import dayjs from "dayjs";
type InputValue = Date | string | number | null;
interface formatTimeProps {
  date: InputValue;
  Format?: string;
}
/**
 * 时间戳转时间
 * @param  date 毫秒
 * @param  newFormat 格式化
 * @return
 */
export function fDateTimeStamp(props: formatTimeProps) {
  const { date, Format } = props;
  const fm = Format || "YYYY-MM-DD HH:mm:ss";
  return date ? dayjs(date).format(fm) : "";
}

interface fDateTimeStampSplit {
  date: InputValue;
}
/**
 * 时间戳转时间，且分割成日期和时间可分开使用
 * @param
 * @return
 */
export function fDateTimeStampSplit(props: fDateTimeStampSplit) {
  const { date } = props;
  let obj = { day: "", time: "" };
  obj.day = fDateTimeStamp({ date, Format: "YYYY-MM-DD" });
  obj.time = fDateTimeStamp({ date, Format: "HH:mm:ss" });
  return obj;
}

/**
 * @func getUnixDateFormatStr
 * @description 根据秒级时间戳获取格式化字符串
 * @param date 秒级时间戳
 * @param format 格式类型
 * @returns str 格式化的地址
 * @example
 * console.log('example');
 */
export function getUnixDateFormatStr(
  date: number,
  format: string = "YYYY-MM-DD HH:mm:ss",
) {
  return dayjs.unix(date).format(format);
}
