import { Button, SxProps, Stack } from "@mui/material";
import { createTypography } from "@/theme/typography";
import { ButtonPropsVariantOverridesKeys } from "@/theme/overrides/Button";
import { TypographyPropsVariantOverridesProps } from "@/theme/overrides/Typography";
import MyIcon from "@/components/public/my-icon";

interface Props {
  variant?: ButtonPropsVariantOverridesKeys;
  children?: React.ReactNode;
  textVariant?: TypographyPropsVariantOverridesProps;
  loading?: boolean;
  disabled?: boolean;
  width?: number | string;
  height?: number | string;
  borderRadius?: number | string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  sx?: SxProps;
  className?: string;
  px?: string | number;
}

export function BaseButton(props: Props) {
  const t = createTypography();
  const {
    children = null,
    variant = "primary",
    textVariant = "body1",
    loading = false,
    disabled = false,
    width = "100%",
    height = "100%",
    borderRadius = "20px",
    onClick = undefined,
    sx = {},
    ...other
  } = props;
  const size = 16;
  return (
    <Button
      {...other}
      disabled={loading || disabled}
      variant={variant}
      sx={{
        ...t[textVariant],
        width,
        height,
        borderRadius,
        ...sx,
      }}
      onClick={onClick}
    >
      {loading ? (
        <Stack width={size} height={size} className="rotate-animation">
          <MyIcon name="icon-loading" size={size} color={"currentColor"} />
        </Stack>
      ) : (
        children
      )}
    </Button>
  );
}

export default BaseButton;
