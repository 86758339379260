import { alpha } from "@mui/material/styles";
import { Palette } from "./types/palette";

declare module "@mui/material/styles/createPalette" {
  interface PaletteOptions extends Palette {}
}

const palette: Palette = {
  text: {
    primary: "#FFFFFF",
    secondary: "#B4B4B6",
    disabled: "#5E5F62",
  },
  active: {
    active: "#0083E6",
    hover: "#198FE8",
    disabled: "#5E5F62",
    disabledBackground: "#4A4E5B",
    // default: "linear-gradient(90deg, #2848E1 0%, #1D98FF 100%)",
  },
  btn2Active: {
    active: alpha("#1378FF", 0.3),
    hover: alpha("#1378FF", 0.1),
    disabled: "#5E5F62",
    disabledBackground: "#4A4E5B",
  },
  btn3Active: {
    active: "#0091FF",
    hover: "#0091FF",
    disabled: alpha("#ECEDEE", 0.1),
    disabledBackground: alpha("#0091FF", 0.1),
  },
  btn4Active: {
    active: alpha("#8028F9", 0.7),
    hover: alpha("#8028F9", 0.4),
    disabled: "#5E5F62",
    disabledBackground: "#4A4E5B",
  },
  btn5Active: {
    active: alpha("#02CBFE", 0.7),
    hover: alpha("#02CBFE", 0.4),
    disabled: "#5E5F62",
    disabledBackground: "#4A4E5B",
  },
  btn6Active: {
    active: alpha("#2A85FF", 0.7),
    hover: alpha("#2A85FF", 0.4),
    disabled: "#5E5F62",
    disabledBackground: "#4A4E5B",
  },
  background: {
    default: "#0E1014",
    paper: "#1F2227",
    textPaper: "#191B1F",
  },
  border: {
    default: "#5E5F62",
    input: "#5E5F62",
  },
  divider: "#2C3036",
  primary: {
    main: "#FFFFFF",
  },
  secondary: {
    main: "#2A85FF",
  },
  error: {
    main: "#F33838",
  },
  warning: {
    main: "#FF8D00",
  },
  info: {
    main: "#B4B4B6",
  },
  success: {
    main: "#11B33C",
  },
  blue: {
    main: "#01CDFF",
  },
  purple: {
    main: "#B028F9",
  },
  //   newProperty: "111",
};
export default palette;

// name: "Access From",
// type: "pie",
// radius: [0, "50%"],
// label: {
//   show: false,
//   fontSize: 14,
// },
// labelLine: {
//   show: false,
// },
