import { Stack, Box, Typography } from "@mui/material";
import { toolsUrl } from "@/config/http";
import DefaultLink from "@/components/public/link/DefaultLink";

export default function footer() {
  const list = [
    { label: "Portfolio", link: "/", active: true },
    { label: "RUNES", link: toolsUrl },
    { label: "Mint", link: `${toolsUrl}/mint-page` },
    { label: "Accelerator", link: `${toolsUrl}/speed` },
  ];
  return (
    <Box
      py={"12px"}
      position={"fixed"}
      bottom={0}
      width={"100%"}
      px={1}
      zIndex={2}
      height={74}
    >
      <Stack
        direction={"row"}
        height={"100%"}
        border={1}
        borderColor={"rgba(42, 133, 255, 0.50)"}
        borderRadius={"16px"}
        alignItems={"center"}
        px={"13px"}
        justifyContent={"space-between"}
        bgcolor={"background.default"}
      >
        {list.map((item, index) => (
          <DefaultLink key={index} href={item.active ? "" : item.link}>
            <Typography
              variant="h5"
              color={item.active ? "text.primary" : "border.default"}
              sx={{
                transition: "all .3s",
                "&:hover": {
                  color: "text.primary",
                },
              }}
            >
              {item.label}
            </Typography>
          </DefaultLink>
        ))}
      </Stack>
    </Box>
  );
}
