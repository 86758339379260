import {
  useAssetTrendData,
  AssetTrendProps,
  getColor,
} from "@/components/pc/v1/home/AssetTrend";
import { Stack, Typography } from "@mui/material";
import FaceTabs from "@/components/public/tabs/FaceTabs";
import LineEcharts from "@/components/public/echarts/LineEcharts";
import { getUnixDateFormatStr } from "@/utils/formatTime";
import { sliceNumber, minus, div, multipliedBy } from "@/utils/number";
import { satoshisToAmount, satoshisToUsd } from "@/utils/bitcoin";
import dayjs from "dayjs";

export default function AssetTrend(props: AssetTrendProps) {
  const {
    typeChange,
    lineData,
    lineXAxis,
    xShowLabelDict,
    xLabelFormatter,
    getListCenterPoint,
    hoverIncreaseProportion,
    tabs,
    tabsActive,
    setTabsActive,
    hoverItem,
    lineList,
    firstNotZeroItem,
    typeList,
    typeActive,
    setHoverItemFn,
  } = useAssetTrendData(props);
  return (
    <Stack width={"100%"} height={"100%"}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        width={"100%"}
        justifyContent={"space-between"}
        mb={2.5}
      >
        <Typography variant="h11">Performance</Typography>

        <FaceTabs
          borderRadius={4}
          width={114}
          textVariant="caption"
          list={tabs}
          value={tabsActive}
          height={17}
          textSx={{
            fontSize: "9px",
          }}
          change={(value: any) => {
            setTabsActive(value);
          }}
        />
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={1}
      >
        {tabsActive == "1" ? (
          <Stack direction={"row"} alignItems={"center"}>
            <Typography variant={"h12"}>
              {satoshisToAmount(hoverItem.btc_assets)} BTC
            </Typography>
            <Typography variant={"h12"} color={"text.secondary"}>
              (${sliceNumber(hoverItem.usd_assets, 2)})
            </Typography>
          </Stack>
        ) : (
          <Stack direction={"row"} alignItems={"center"}>
            <Typography variant={"h12"}>
              ${sliceNumber(hoverItem.usd_assets, 2)}
            </Typography>
            <Typography variant={"h12"} color={"text.secondary"}>
              ({satoshisToAmount(hoverItem.btc_assets)} BTC)
            </Typography>
          </Stack>
        )}
        <Typography variant={"h12"} color={"text.secondary"}>
          {lineList[lineList.length - 1]?.time
            ? getUnixDateFormatStr(
                lineList[lineList.length - 1]?.time || 0,
                "YYYY-MM-DD HH:mm",
              )
            : "-"}
        </Typography>
      </Stack>
      <Stack
        mb={"20px"}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant={"h12"} color={getColor(hoverIncreaseProportion)}>
          {Number(firstNotZeroItem.usd_assets)
            ? hoverIncreaseProportion + "%"
            : "-"}
        </Typography>
        <Stack direction={"row"} spacing={"10px"}>
          {typeList.map((item, index) => (
            <Stack
              key={index}
              py={"2px"}
              px={"10px"}
              borderRadius={"16px"}
              color={
                item.value === typeActive ? "text.primary" : "text.secondary"
              }
              bgcolor={
                item.value === typeActive ? "secondary.main" : "transparent"
              }
              sx={{
                cursor: "pointer",
                "&:hover": {
                  color: "text.primary",
                },
              }}
              onClick={() => typeChange(item)}
            >
              <Typography variant="h12">{item.label}</Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Stack
        width={"100%"}
        height={144}
        // mb={"18px"}
        onMouseLeave={() => {
          setHoverItemFn(lineList[lineList.length - 1] as any);
        }}
      >
        <LineEcharts
          data={lineData}
          xAxisData={lineXAxis}
          customValues={getListCenterPoint({
            totalNumbers: lineData.length,
            segments: 4,
          })}
          axisLabelFormatter={(value: string) => {
            const formatter = xLabelFormatter[typeActive];
            return dayjs.unix(Number(value)).format(formatter);
          }}
          yAxisName={tabsActive === "1" ? "BTC" : ""}
          formatterLabel={(value) => {
            return tabsActive === "1"
              ? satoshisToAmount(value, false)
              : "$" + sliceNumber(value, 0);
            // return tabsActive !== "1" ? "111" : satoshisToAmount(value);
          }}
          renderTip={(params: any) => {
            const data = lineList[params[0].dataIndex || 0];
            setHoverItemFn(data);
            return (
              // <EchartsTip
              //   params={data}
              //   comparison={firstNotZeroItem}
              //   type={tabsActive}
              // />
              <>9999</>
            );
          }}
        />
      </Stack>
      {/* <Stack
    height={42}
    alignItems={"center"}
    direction={"row"}
    borderTop={1}
    borderColor={"divider"}
    width={`calc(100% - ${linePaddingRight - 20}px)`}
  >
    {xLabelList.map((item, key) => (
      <Typography
        key={key}
        variant={"caption"}
        width={`calc(100% / ${xLabelList.length})`}
        align="center"
        color={"text.secondary"}
      >
        {item}
      </Typography>
    ))}
  </Stack> */}
    </Stack>
  );
}
