import { Box, Stack } from "@mui/material";
import H5Header from "./header";
import H5Footer from "./footer";
import { pxToVw } from "@/utils/style";
import CustomerService from "./CustomerService";

interface Props {
  children: React.ReactNode;
}

export default function main(props: Props) {
  const { children } = props;
  const footerHeight = 49 + 27 + 27;
  const minHeight = `calc(100vh - ${pxToVw(footerHeight + 72)})`;
  return (
    <Box>
      <H5Header />
      <Stack pb={`${footerHeight}px`} minHeight={minHeight}>
        {children}
      </Stack>
      <H5Footer />
      <CustomerService />
    </Box>
  );
}
