import {
  baseRes,
  CreateFollowBody,
  CreateFollowByShareBody,
  CreateFollowByShareRes,
  CreateFollowRes,
  CreateShareBody,
  CreateShareRes,
  FollowStatusRes,
  // GetSharBody,
  GetShareFollowBody,
  GetShareFollowRes,
  // GetSharRes,
  GetUserFollowBody,
  GetUserFollowRes,
  RemoveFollowBody,
  RemoveFollowRes,
  RunesHistoryBody,
  RunesHistoryRes,
  UpdateFollowRemarkBody,
  UpdateFollowRemarkRes,
} from "@/types";
import { api } from "@/utils/request";

/**
 * runes 历史
 */
export const runesHistoryApi = (data: RunesHistoryBody) =>
  api<RunesHistoryBody, baseRes<RunesHistoryRes>>({
    url: "/btc/runes/get_mint_history_list",
    method: "post",
    data,
  });

/**
 * 添加新关注地址
 * @param
 * @return
 */
export const createFollowApi = (data: CreateFollowBody) =>
  api<CreateFollowBody, baseRes<CreateFollowRes>>({
    url: "/wallet_manager/follow_address/create_follow",
    method: "post",
    data,
  });
/**
 * 取消关注地址
 * @param
 * @return
 */
export const removeFollowApi = (data: RemoveFollowBody) =>
  api<RemoveFollowBody, baseRes<RemoveFollowRes>>({
    url: "/wallet_manager/follow_address/remove_follow",
    method: "post",
    data,
  });
/**
 * 修改关注地址备注
 * @param
 * @return
 */
export const updateFollowRemarkApi = (data: UpdateFollowRemarkBody) =>
  api<UpdateFollowRemarkBody, baseRes<UpdateFollowRemarkRes>>({
    url: "/wallet_manager/follow_address/update_follow_remark",
    method: "post",
    data,
  });
/**
 * 创建新的地址分享
 * @param
 * @return
 */
export const createShareApi = (data: CreateShareBody) =>
  api<CreateShareBody, baseRes<CreateShareRes>>({
    url: "/wallet_manager/follow_address/share/create_share",
    method: "post",
    data,
  });
/**
 * 获取当前账户组的关注列表
 * @param
 * @return
 */
export const getUserfollowApi = (data: GetUserFollowBody) =>
  api<GetUserFollowBody, baseRes<GetUserFollowRes>>({
    url: `/wallet_manager/follow_address/get_follow`,
    method: "post",
    data,
  });
/**
 * 获取关注分享列表
 * @param
 * @return
 */
export const getShareApi = (data: GetShareFollowBody) =>
  api<GetShareFollowBody, baseRes<GetShareFollowRes>>({
    url: `/wallet_manager/follow_address/share/get_share`,
    method: "post",
    data,
    cleanVerifyToken: true,
  });
/**
 * 根据分享码关注全部
 * @param
 * @return
 */
export const createFollowByShareApi = (data: CreateFollowByShareBody) =>
  api<CreateFollowByShareBody, baseRes<CreateFollowByShareRes>>({
    url: "/wallet_manager/follow_address/create_follow_by_share",
    method: "post",
    data,
  });
/**
 * 获取指定地址的关注状态
 * @param data 别人的地址
 * @return
 */
export const getFollowStatusApi = (data: string) =>
  api<any, baseRes<FollowStatusRes>>({
    url: `/wallet_manager/follow_address/get_follow/address/${data}`,
    method: "get",
    openVerifyTokenMsg: false,
  });
