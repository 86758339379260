import { Stack, SxProps, Typography } from "@mui/material";
import { TypographyPropsVariantOverridesProps } from "@/theme/overrides/Typography";
import { Menus } from "@/components/public/menus";
import MyIcon from "@/components/public/my-icon";
import { ListItem } from "@/types/components";

export interface SelectProps {
  width?: number;
  height?: number;
  value: any;
  list: ListItem[];
  variant?: TypographyPropsVariantOverridesProps;
  placeholder?: string;
  itemHeight?: number;
  change?: (value: string | number, row: ListItem) => void;
  borderRadius?: string;
  iconSize?: number;
  px?: number | string;
  boxSx?: SxProps;
  labelVariant?: TypographyPropsVariantOverridesProps;
  disabled?: boolean;
}
export default function Select(props: SelectProps) {
  const {
    variant,
    width = 307,
    height = 44,
    placeholder = "",
    list = [],
    value = "",
    change = undefined,
    itemHeight,
    borderRadius = "10px",
    iconSize = 16,
    px = "18px",
    boxSx = {},
    labelVariant,
    disabled = false,
  } = props;

  const findValue = list.find((item) => item.value === value);
  const label = findValue ? findValue.label : placeholder;
  return (
    <Menus
      itemHeight={itemHeight}
      list={list}
      value={value}
      change={change}
      boxSx={boxSx}
      labelVariant={labelVariant}
      disabled={disabled}
      childrenRender={(open) => (
        <Stack
          width={width}
          height={height}
          justifyContent={"space-between"}
          alignItems={"center"}
          px={px}
          direction={"row"}
          border={1}
          borderColor={"divider"}
          borderRadius={borderRadius}
        >
          <Typography
            variant={variant as any}
            sx={{
              overflow: "hidden",
            }}
          >
            {label}
          </Typography>
          <MyIcon
            name="icon-down"
            size={iconSize}
            color={"btn4Active.disabled"}
            sx={{
              transform: open ? "rotate(-180deg)" : "rotate(0deg)",
              transition: "all .3s",
            }}
          />
        </Stack>
      )}
    ></Menus>
  );
}
