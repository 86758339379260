"use client";

import { ButtonProps as MuiButtonProps } from "@mui/material";
import { alpha, darken, lighten, Theme } from "@mui/material/styles";
import { Palette } from "../types/palette";
import { border } from "@mui/system";
import themePalette from "../palette";

const COLORS = [
  "primary",
  "secondary",
  "info",
  "success",
  "warning",
  "error",
] as const;

export interface ButtonPropsVariantOverridesProps {
  soft: true;
  linkText: true; // 链接类型
  gradation: true;
  confirmOutlined: true;
  glow: true;
  primary: true;
  purple: true;
  secondary: true;
  cyan: true;
  grey: true;
}
export type ButtonPropsVariantOverridesKeys =
  keyof ButtonPropsVariantOverridesProps;

// NEW VARIANT
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides
    extends ButtonPropsVariantOverridesProps {}
}

interface CreateGlowVariantProps {
  disabled?: boolean;
  variant?: string;
  bg?: string;
  color?: string;
  borderColor?: string;
  boxShadow?: string;
  hoverBg?: string;
  hoverBorderColor?: string;
  hoverColor?: string;
  colorImportant?: boolean;
}
const createGlowVariant = (props: CreateGlowVariantProps) => {
  const {
    disabled,
    variant,
    bg,
    borderColor,
    boxShadow,
    hoverBg,
    color,
    hoverBorderColor,
    hoverColor,
    colorImportant,
  } = props;
  const propsObj: any = { variant };
  if (disabled) propsObj["disabled"] = disabled;
  const noHandleColor = color || themePalette.text.primary;
  const handleColorFn = (value: string) => {
    if (colorImportant && disabled) return `${value} !important`;
    return value;
  };
  const style: any = {
    fontSize: 20,
    color: handleColorFn(color || themePalette.text.primary),
    boxSizing: "border-box",
    "&:hover": {
      background: hoverBg || bg,
      border: `1px solid ${hoverBorderColor || borderColor}`,
      color: handleColorFn(hoverColor || color || themePalette.text.primary),
    },
  };
  if (bg) style["background"] = bg;
  if (borderColor) style["border"] = `1px solid ${borderColor}`;
  if (boxShadow) style["boxShadow"] = boxShadow;
  if (disabled) style["opacity"] = 0.5;
  return {
    props: propsObj,
    style,
  };
};

const createDisabledVariant = ({
  variant,
  bg,
}: {
  variant: string;
  bg: string;
}) =>
  createGlowVariant({
    disabled: true,
    variant,
    color: themePalette.text.secondary,
    bg,
    borderColor: themePalette.divider,
    colorImportant: true,
  });

const createMultipleVariant = (props: CreateGlowVariantProps) => {
  // console.log("props", props, themePalette.text.primary);
  return [
    createGlowVariant(props),
    createGlowVariant({
      ...props,
      disabled: true,
      colorImportant: true,
    }),
  ];
};

export default function Button(theme: Theme) {
  const palette = theme.palette as any as Palette;

  const variants: any[] = [
    ...createMultipleVariant({
      variant: "glow",
      bg: palette.btn6Active.hover,
      hoverBg: palette.btn6Active.active,
      boxShadow: "0px 0px 10px 0px rgba(1,205,255,0.56)",
      borderColor: "#0091FF",
    }),
    // createDisabledVariant({
    //   variant: "glow",
    //   bg: palette.btn6Active.disabledBackground,
    // }),

    ...createMultipleVariant({
      variant: "primary",
      color: palette.secondary.main,
      hoverBg: alpha(palette.btn3Active.active, 0.2),
      borderColor: "#0091FF",
    }),

    ...createMultipleVariant({
      variant: "purple",
      bg: palette.btn4Active.hover,
      hoverBg: palette.btn4Active.active,
      boxShadow: "0px 0px 16px 0px rgba(176,40,249,0.5)",
      borderColor: palette.purple.main,
    }),

    ...createMultipleVariant({
      variant: "cyan",
      bg: palette.btn5Active.hover,
      hoverBg: palette.btn5Active.active,
      boxShadow: "0px 0px 16px 0px rgba(5,202,255,0.5)",
      borderColor: "#02CBFF",
    }),
    ...createMultipleVariant({
      variant: "grey",
      color: palette.active.disabled,
      hoverBg: alpha(palette.active.disabled, 0.2),
      borderColor: palette.active.disabled,
    }),
  ];
  return {
    MuiButton: {
      variants: variants,
    },
  };
}
