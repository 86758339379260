/**
 * @name wallet
 * @description 钱包操作相关方法迁移至当前文件
 */
import { walletApi } from "dotswap-wallet";
import { SHA256, enc } from "crypto-js";
import * as bitcoin from "bitcoinjs-lib";
import { isMainnet } from "@/config/http";
import * as ecc from "tiny-secp256k1";
bitcoin.initEccLib(ecc);

/**
 * @func signPsbt
 * @description 签名psbt
 */
export const signPsbt = async (psbt: string, isFinalize: boolean = true) => {
  const name = getStoreWalletName();
  const { signPsbt, getInfo } = walletApi;
  let handlePsbt = await signPsbt({
    psbt,
    type: "hex",
    isFinalize,
  });

  return handlePsbt;
};

export const signPsbts = async (
  psbts: string[],
  isFinalize: boolean = true,
): Promise<string[]> => {
  const { signPsbts, signPsbt } = walletApi;
  let handlePsbt: string[] = [];
  if (psbts.length > 1) {
    const handleList = await signPsbts({
      psbt: psbts,
      isFinalize,
    });
    handlePsbt.push(...handleList);
  } else {
    const [psbt] = psbts;
    const resPsbt = await signPsbt({
      psbt,
      type: "hex",
      isFinalize,
    });
    handlePsbt.push(resPsbt);
  }
  return handlePsbt;
};

/**
 * @func sendBitcoin
 * @description 发送btc
 */
export const sendBitcoin = async (
  address: string,
  satoshis: number,
  feeRate?: number,
): Promise<string> => {
  const name = getStoreWalletName();
  const { sendBitcoin } = walletApi;
  const option = feeRate ? { feeRate } : undefined;
  return await sendBitcoin({
    address,
    satoshis,
    option,
  });
};

/**
 * @func signMessage
 * @description 签名信息
 */
export const signMessage = async (
  msg: string,
  address?: string,
  type?: string,
) => {
  const { sign, getInfo } = walletApi;
  const info = await getInfo();

  const obj: any = { msg };
  if (type) obj["type"] = type;
  if (address) obj["address"] = address;
  return await sign(obj);
};

/**
 * @func privateToPublicKey
 * @description 通过私钥获取公钥
 * @param privateKey 私钥
 */
export const privateToPublicKey = (privateKey: string) => {
  // 使用私钥创建 ECPair 实例
  const hash = SHA256(privateKey);
  // 获取公钥
  const publicKey = hash.toString(enc.Hex);
  return publicKey;
};

// 用于狗狗链测试使用
const addressAttr = "dogeAddress";
const privateKeyAttr = "privateKey";
// 操作测试完中使用drc20内容
export const getDrc20Store = () => {
  // 狗狗币没有测试环境，用于测试
  const address =
    localStorage.getItem(addressAttr) || "nkjnEZrw1krQL1SpNRPpgvwG4fobUhqVoG";
  const privateKey =
    localStorage.getItem(privateKeyAttr) ||
    "c9ff8e5801b0d975edfb358b083c2564aca7c31bc0c64ce7880b9d3365c4510e";
  return {
    address,
    privateKey,
  };
};

/**
 * @func getStoreWalletName
 * @description 获取本地缓存中钱包名称
 */
export const getStoreWalletName = () => localStorage.getItem("wallet");

/**
 * @func isXvReject
 * @description 是否为xverse钱包拒绝
 */
export const isXvReject = (error: any) =>
  error.code === 1 ||
  error.message === "Request canceled" ||
  error.msg === "Request canceled";

/**
 * @func isOkxReject
 * @description 是否为okx钱包拒绝
 */
export const isOkxReject = (error: any) =>
  error.code === 4001 ||
  error.message === "User rejected the request." ||
  error.msg === "User rejected the request.";

/**
 * @func isUniReject
 * @description 是否为unisat钱包拒绝
 */
export const isUniReject = (error: any) =>
  error.code === 4001 ||
  error.message === "User rejected the request." ||
  error.msg === "User rejected the request.";

/**
 * @func isWizzReject
 * @description 是否为wizz钱包拒绝
 */
export const isWizzReject = (error: any) =>
  error.code === 4001 ||
  error.message === "User rejected the request." ||
  error.msg === "User rejected the request.";

/**
 * @func isBitgetReject
 * @description 是否为bitget钱包拒绝
 */
export const isBitgetReject = (error: any) =>
  error.code === -32603 ||
  error.message === "User rejected the request." ||
  error.msg === "User rejected the request.";

/**
 * @func isPhoneWizzReject
 * @description 手机端wizz钱包
 */
export const isPhoneWizzReject = (error: any) => {
  const data = error.message;
  return data
    ? data.code === 4001 ||
        data.code === 50000 ||
        data.message === "User rejected the request." ||
        data.msg === "User rejected the request."
    : false;
};

/**
 * @func isTpReject
 * @description 是否为tp钱包拒绝
 */
export const isTpReject = (error: any) =>
  error.code === 1 || error.message === "Unknown Error";

/**
 * @func isGateAppFn
 * @description 是否为gate app
 */
export const isGateAppFn = (userAgent: string) => {
  const global: any = window;
  // return userAgent.indexOf('GateWeb3') > -1;
  return global.isWeb3App;
};

/**
 * 钱包用户是否手动拒绝签名
 * @param
 * @return
 */
export const isRejectSign = (error: any) => {
  try {
    if (!error) {
      return false;
    }
    if (error.code === 4001) {
      return true;
    }
    if (isXvReject(error)) return true;
    if (isBitgetReject(error)) return true;
    if (isOkxReject(error)) return true;
    if (isPhoneWizzReject(error)) return true;
    if (isUniReject(error)) return true;
    if (isWizzReject(error)) return true;
    if (isTpReject(error)) return true;
    // wizz钱包，code外面还有一层message
    const errorObj = error && error.message && JSON.parse(error.message);
    if (errorObj.code === 4001 || errorObj.code === 50000) {
      return true;
    }
  } catch (error) {
    return false;
  }
};

/**
 * @func formatId
 * @description 处理tx或则地址
 * @param id 需要处理内容
 * @returns str
 */
export const formatId = (id = "") => {
  if (!id) return;
  if (id.length <= 8) return id;
  const start = id.slice(0, 4);
  const end = id.slice(id.length - 4, id.length);
  return `${start}....${end}`;
};

/**
 * Check if the address is valid.
 */
// export function isValidAddress(address: string) {
//   let error;
//   try {
//     bitcoin.address.toOutputScript(address, bitcoin.networks.testnet);
//     console.log(
//       "bitcoin.address.toOutputScript(address, bitcoin.networks.testnet): ",
//       bitcoin.address.toOutputScript(address, bitcoin.networks.testnet),
//     );
//   } catch (e) {
//     error = e;
//   }
//   if (error) {
//     return false;
//   } else {
//     return true;
//   }
// }

export function isValidAddress(address: string) {
  let error;
  const network = isMainnet
    ? bitcoin.networks.bitcoin
    : bitcoin.networks.testnet;
  try {
    bitcoin.address.toOutputScript(address, network);
  } catch (e) {
    error = e;
  }
  if (error) {
    return false;
  } else {
    return true;
  }
}
