import Image from "@/components/public/image";
import TickImage from "@/components/public/image/TickImage";
import { Button, Stack, Typography, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import PieEcharts from "@/components/public/echarts/PieEcharts";
import { renderToString } from "react-dom/server";
import { createTypography } from "@/theme/typography";
import { TipParams } from "@/types/echarts";
import style from "./index.module.scss";
import palette from "@/theme/palette";
import {
  walletWalletAccountPortfolioPie,
  walletAccountAddressPortfolioPie,
} from "@/services/wallet_manager";
import {
  WalletAccountAddressPortfolioPieItem,
  WalletWalletAccountPortfolioPie,
} from "@/types/wallet_manager";
import { useSearchAddress } from "@/hooks/router";
import { useAppSelector } from "@/redux";
import { formatId } from "@/utils/wallet";
import { sliceNumber, multipliedBy } from "@/utils/number";
import { useUpdateCb } from "@/components/pc/v1/wallet/hook";
import { satoshisToAmount } from "@/utils/bitcoin";
import { useUpdateAddressData } from "./hook";
import Empty from "@/components/public/empty";

export function EchartsTip(props: { params: TipParams }) {
  const t = createTypography();
  const { params } = props;
  const {
    tick,
    value,
    symbol,
    usd_assets,
    percent,
    btc_assets,
    icon_url,
    amount,
    type,
  } = params.data || ({} as any);
  const ratio = multipliedBy(percent, 100);
  const list = [
    {
      label: "Balance",
      value:
        type === "BTC" && tick === "BTC" ? satoshisToAmount(amount) : amount,
      color: "#93B6E6",
    },
    {
      label: "Value",
      value: `$${sliceNumber(usd_assets, 2)}`,
      color: palette.secondary.main,
    },
    {
      label: "Ratio",
      value: `${sliceNumber(ratio, Math.abs(Number(ratio)) < 1 ? 6 : 2)}%`,
      color: "#F5428D",
    },
  ];
  return (
    <div className={style["asset-dist-tip"]}>
      <div className={style["header-box"]}>
        <TickImage
          url={icon_url}
          name={tick}
          symbol={symbol}
          size={18}
          hasRadius={true}
          textVariant={"caption"}
        />
        <div className={style["header-text"]} style={{ ...(t.h11 as any) }}>
          {tick}
        </div>
      </div>
      <div>
        {list.map((item, index) => (
          <div className={style["row-data"]} key={index}>
            <div style={{ color: item.color }}>{item.label}</div>
            <div style={{ color: item.color }}>{item.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
export interface AssetDistProps {
  address: string;
  api?: any;
}

export const useAssetDistData = (props: AssetDistProps) => {
  const { address, api } = props;
  const { address_list } = useAppSelector((state) => state.account);
  const [list, setList] = useState<WalletAccountAddressPortfolioPieItem[]>([]);
  const getAddressPie = async () => {
    const res = await walletAccountAddressPortfolioPie({
      address: searchAddress,
    });
    if (res.code === 10170006) {
      return;
    }
    const { items = [] } = res.data;
    setList(items);
  };
  const getAccountPie = async () => {
    const obj: any = {};
    if (address) obj.address = address;
    let res: null | WalletWalletAccountPortfolioPie["res"];
    if (api) res = await api?.(obj);
    else res = await walletWalletAccountPortfolioPie(obj);
    console.log("api", res);
    if (!res) return;
    const { items = [] } = res.data;
    setList(items);
  };
  const { searchAddress, isSearchPage } = useSearchAddress({
    cb: () => {
      getAccountPie();
    },
    searchCb: () => {
      getAddressPie();
    },
  });

  const getTotalList = () => {
    if (searchAddress) getAddressPie();
    else getAccountPie();
  };
  // useEffect(() => {
  //   if (address && !searchAddress) getAccountPie();
  // }, [address]);

  useUpdateAddressData({
    cb: () => {
      getAccountPie();
    },
    compProps: props,
    isUseCbFn: () => {
      return !searchAddress;
    },
  });
  useUpdateCb({
    updateCb: getTotalList,
  });
  useEffect(() => {
    if (api) getAccountPie();
  }, []);
  const pieList = list.map((item) => ({
    ...item,
    name: item.tick,
    value: Number(item.usd_assets),
  }));
  const t = createTypography();
  return {
    pieList,
    list,
  };
};

export default function AssetDist(props: AssetDistProps) {
  const { pieList, list } = useAssetDistData(props);
  return (
    <Stack height={"100%"}>
      <Stack direction={"row"} py={2} px={3.5} flex={"none"}>
        <Stack width={382}>
          <Typography variant="h4">Token Allocation</Typography>
          {pieList.length > 0 ? (
            <Stack width={"calc(100% - 20px)"} height={330} mt={3}>
              <PieEcharts
                renderTip={(params: any) => <EchartsTip params={params} />}
                data={pieList}
                showLabel={false}
              />
            </Stack>
          ) : null}
        </Stack>
        <Stack flex={1}>
          <Typography variant="h4">Top 8</Typography>
          {pieList.length > 0 ? (
            <Stack spacing={"14px"} mt={3}>
              {list.map((item, key) => (
                <Stack direction={"row"} key={key} alignItems={"center"}>
                  <TickImage
                    url={item.icon_url}
                    size={25}
                    hasRadius
                    name={item.tick}
                    symbol={item.symbol}
                  />
                  <Tooltip title={item.tick || ""}>
                    <Typography
                      variant="h6"
                      width={160}
                      ml={1}
                      className="ellipsis"
                    >
                      {item.tick}
                    </Typography>
                  </Tooltip>
                  <Typography variant="h6" flex={1} ml={2} width={60}>
                    {sliceNumber(multipliedBy(item.percent, 100), 2)}%
                  </Typography>
                </Stack>
              ))}
            </Stack>
          ) : null}
        </Stack>
      </Stack>
      {pieList.length === 0 ? (
        <Stack height={"100%"}>
          <Empty width={90} height={73} msg={"No data"} />
        </Stack>
      ) : null}
    </Stack>
  );
}
