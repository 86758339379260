import BigNumber from "bignumber.js";
// 乘
export const multipliedBy = (num: string | number, num2: string | number) => {
  const handleNum = new BigNumber(num);
  return handleNum.multipliedBy(num2).toString();
};
// 除
export const div = (num: string | number, num2: string | number) => {
  const handleNum = new BigNumber(num);
  return handleNum.div(num2).toString();
};

// 减
export const minus = (num: string | number, num2: string | number) => {
  const handleNum = new BigNumber(num);
  return handleNum.minus(num2).toString();
};

// 加
export const plus = (num: string | number, num2: string | number) => {
  const handleNum = new BigNumber(num);
  return handleNum.plus(num2).toString();
};

// 四舍五入保留小数（去0）
export function fixedNumber(number: number | string, decimal: number) {
  var fixedNum = Number(number).toFixed(decimal); // 保留指定位数的小数
  var trimmedNum = fixedNum.replace(/(\.0*|0+)$/, ""); // 去除末尾的零
  return trimmedNum;
}

// 去位保留小数（去0）
export function sliceNumber(number: number | string, decimal: number) {
  let numStr = new BigNumber(number).toFixed();
  const numArr = numStr.split(".");
  const decimalNum = numArr[1] ? numArr[1].slice(0, decimal) : "0";
  var fixedNum = `${numArr[0]}.${decimalNum}`; // 保留指定位数的小数
  var trimmedNum = fixedNum.replace(/(\.0*|0+)$/, ""); // 去除末尾的零
  return trimmedNum;
}
