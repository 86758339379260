import { Stack, Typography } from "@mui/material";
import { getTextLinkGradation } from "@/utils/style";
import BaseButton from "@/components/public/button/BaseButton";
import { useDemoData } from "@/components/pc/v1/wallet/NoLoginDemo";
import { WalletList } from "@/components/pc/v1/wallet/NoLogin";
import Image from "@/components/public/image";
import MyIcon from "@/components/public/my-icon";
import { createTypography } from "@/theme/typography";
import Tabs from "@/components/public/tabs";
import AssetTrend from "./AssetTrend";
import { CustomTable, ColumnItem } from "@/components/public/table/CustomTable";
import {
  homePortfolioDetail,
  homePortfolioTrend,
  homePortfolioPie,
} from "@/services/wallet_manager";
import AssetDist from "./AssetDist";
import {
  WalletAccountGetAccountItem,
  WalletAccountAddressPortfolioDetailItem,
  WalletAccountAddressPortfolioDetail,
  WalletWalletAccountDetail,
} from "@/types/wallet_manager";
import TickImage from "@/components/public/image/TickImage";
import { satoshisToAmount, formatUsd } from "@/utils/bitcoin";
import { sliceNumber, multipliedBy } from "@/utils/number";
import { UserSortType } from "@/types/service";
import { pxToVw } from "@/utils/style";
import { useAlertToast } from "@/components/public/dialog/AlertToast";
import { useEffect } from "react";
import { copyTextToClipboard } from "@/utils/tools";
import { SnackbarUtils } from "@/components/public/snackbar/SnackbarProvider";

function EchartsBox(props: { children?: React.ReactNode }) {
  const { children } = props;
  return (
    <Stack
      width={323}
      borderRadius={"9px"}
      border={1}
      borderColor={"#2C3036"}
      p={"12px"}
    >
      {children}
    </Stack>
  );
}

export default function HomePage() {
  const { userList, tabs, typeList, sort, list, loading } = useDemoData();
  const typography = createTypography();
  const { openAlertToast } = useAlertToast();
  const openToast = () => {
    openAlertToast({
      width: 264,
      msg: "",
      minHeight: 172,
      showClose: false,
      render: (close) => {
        return (
          <>
            <Stack
              px={"28px"}
              py={"38px"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography variant={"caption"} mb={2} textAlign={"center"}>
                For the full experience, please
                <br />
                use the desktop version
              </Typography>
              <BaseButton
                textVariant="body2"
                height={36}
                width={154}
                borderRadius={"10px"}
                variant={"glow"}
                onClick={() => {
                  copyTextToClipboard(window.location.href);
                  close?.(false);
                  SnackbarUtils.toast("Copied successfully");
                }}
              >
                Copy Link
              </BaseButton>
            </Stack>
            <MyIcon
              name="icon-del-duoqianbao"
              size={16}
              onClick={() => close?.(false)}
              sx={{
                position: "absolute",
                top: "16px",
                right: "20px",
              }}
            />
          </>
        );
      },
    });
  };

  const column: ColumnItem[] = [
    {
      label: "Token",
      render: (row: WalletAccountAddressPortfolioDetailItem) => (
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <TickImage
            url={row.icon_url}
            name={row.tick}
            symbol={row.symbol}
            size={16}
            hasRadius={true}
          />
          <Typography
            variant="h11"
            sx={{
              wordBreak: "break-all",
            }}
          >
            {row.tick}
          </Typography>
        </Stack>
      ),
      width: 105,
      pl: 1,
      headerVariant: "h12",
    },
    {
      label: "Balance",
      key: "amount",
      render: (row: WalletAccountAddressPortfolioDetailItem) => (
        <Typography
          variant={"h11"}
          sx={{
            wordBreak: "break-word",
          }}
        >
          {row.type === "BTC"
            ? satoshisToAmount(row.amount)
            : sliceNumber(row.amount, 2)}
        </Typography>
      ),
      width: 64,
      pl: 1,
      headerVariant: "h12",
    },
    {
      label: "Value",
      sort: true,
      key: "usd_assets",
      render: (row: WalletAccountAddressPortfolioDetailItem) => (
        <Stack
          width={"100%"}
          sx={{
            wordBreak: "break-word",
          }}
        >
          <Typography variant="h11">
            {sliceNumber(satoshisToAmount(row.btc_assets), 5)} ₿
          </Typography>
          <Typography variant="h11" color="text.secondary">
            (${sliceNumber(row.usd_assets, 0)})
          </Typography>
        </Stack>
      ),
      width: 86,
      pl: 1,
      headerVariant: "h12",
    },
    {
      label: "Change",
      key: "price_change_24h",
      sort: true,
      render: (row: WalletAccountAddressPortfolioDetailItem) => (
        <Typography
          variant="caption"
          sx={{
            color: `${
              +row.price_change_24h > 0 ? "success.main" : "error.main"
            }`,
          }}
        >
          {row.price_change_24h
            ? sliceNumber(multipliedBy(row.price_change_24h, 100), 3) + "%"
            : "-"}
        </Typography>
      ),
      width: 66,
      pl: 1,
      headerVariant: "h12",
    },
  ];
  return (
    <Stack>
      <Stack
        width={"100%"}
        pt={"60px"}
        justifyContent={"center"}
        alignItems={"center"}
        position={"relative"}
      >
        <Image
          src="/assets/h5_home_bg.png"
          width={pxToVw(375)}
          height={pxToVw(234)}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        />
        <Stack direction={"row"} justifyContent={"center"} mb={1}>
          <Typography variant="h3">Power Up Your</Typography>
          &nbsp;
          <Typography
            variant="h3"
            sx={{
              ...getTextLinkGradation(
                "linear-gradient( 90deg, #00B2FF 0%, #2981F6 100%)",
              ),
            }}
          >
            Trading
          </Typography>
        </Stack>
        <Typography
          variant="h11"
          color={"text.primary"}
          mb={"32px"}
          textAlign={"center"}
          sx={{
            opacity: 0.8,
          }}
        >
          Easily track and analyse your BTC, Runes & BRC20
          <br />
          assets with Mempool Portfolio
        </Typography>
        <BaseButton
          textVariant="h11"
          height={36}
          width={154}
          borderRadius={"10px"}
          variant={"glow"}
          sx={{
            mb: "38px",
          }}
          onClick={openToast}
        >
          Try It Now
        </BaseButton>
      </Stack>
      <Stack alignItems={"center"} mb={"38px"}>
        <Typography variant="subtitle3" color={"text.primary"} mb={"16px"}>
          - Supported Wallets -
        </Typography>
        <Stack direction={"row"} width={295} justifyContent={"space-between"}>
          {WalletList.map((item, index) => (
            <Stack
              justifyContent={"center"}
              key={index}
              spacing={1}
              width={28}
              alignItems={"center"}
            >
              <Image
                src={item.image}
                width={28}
                height={28}
                style={{
                  borderRadius: "4px",
                  boxShadow: "0px 0px 4px 0px rgba(255,255,255,0.5)",
                }}
              />
              <Typography
                variant="subtitle3"
                color={"text.primary"}
                mb={"16px"}
              >
                {item.label}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Stack alignItems={"center"} position={"relative"} mb={"21px"}>
        <Typography
          variant="body2"
          color={"text.primary"}
          mb={2}
          textAlign={"center"}
        >
          - Demonstrations -
        </Typography>

        <Stack
          width={355}
          height={"fit-content"}
          borderRadius={"24px"}
          border={4}
          borderColor={"#383838"}
          p={"16px 16px 25px 16px"}
          sx={{
            background: "linear-gradient( 180deg, #1C1C1C 0%, #0E1014 100%)",
          }}
        >
          {/* 遮罩层 */}
          <Stack
            width={"100%"}
            height={"100%"}
            position={"absolute"}
            top={0}
            left={0}
            zIndex={1}
            onClick={openToast}
          ></Stack>

          {/* 内容层 */}
          <Stack
            height={12}
            width={154}
            borderRadius={"7px"}
            bgcolor={"divider"}
            sx={{
              m: "auto",
            }}
          ></Stack>
          <Stack
            mt={2}
            direction={"row"}
            alignItems={"center"}
            mb={"12px"}
            sx={{
              cursor: "pointer",
            }}
          >
            <Typography variant="h5">My Wallet ({userList.length})</Typography>
            &nbsp;&nbsp;
            <MyIcon name="icon-down" size={16} />
          </Stack>
          <Stack mb={"16px"}>
            <BaseButton
              variant={"primary"}
              borderRadius={"5px"}
              sx={{
                width: 92,
                height: 24,
                px: "6px",
                ...typography.caption,
              }}
            >
              Link Address
            </BaseButton>
          </Stack>
          <Stack mb={"13px"}>
            <Tabs
              activeIconHeight={2}
              list={tabs}
              active={tabs[0].value}
              variant={"h8"}
              activeVariant={"h11"}
              activeSpacing={"2px"}
              boxSpacing={2}
            />
          </Stack>
          <Stack spacing={"20px"} mb={"20px"}>
            <EchartsBox>
              <AssetTrend address={""} api={homePortfolioTrend} />
            </EchartsBox>
            <EchartsBox>
              <AssetDist address={""} api={homePortfolioPie} />
            </EchartsBox>
          </Stack>
          <Stack width={323} mx={"auto"}>
            <Typography variant="h6" mb={"12px"}>
              Asset Details
            </Typography>
            <Stack border={1} borderRadius={"20px"} borderColor={"divider"}>
              <CustomTable
                sortIconSize={6}
                headerPy={1.75}
                loadingPlaceholderLen={10}
                defaultKey={sort.sort}
                defaultSort={sort.desc ? UserSortType.desc : UserSortType.asc}
                column={column}
                list={list}
                loading={loading}
                showEmptyPlaceholder={true}
                tableHeight={"fit-content" as any}
                bodyPy={2}
                bodyOtherStyle={({ isLast }) => ({
                  borderBottom: isLast ? "none" : "1px solid #2C3036",
                })}
                headerOtherStyle={{
                  borderBottom: "1px solid #2C3036",
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
