import { Stack } from "@mui/material";
import Image from "@/components/public/image";
import { HeaderHeight, HeaderZIndex } from "@/config/layouts";
import { useHasScroll } from "@/layouts/pc/header";
import MyIcon from "@/components/public/my-icon";
import { useRouter } from "next/router";

export default function H5Header() {
  const { hasScroll } = useHasScroll();
  const router = useRouter();
  return (
    <Stack height={72}>
      <Stack
        height={72}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        pl={"20px"}
        pr={"24px"}
        position={"fixed"}
        top={0}
        width={"100%"}
        zIndex={HeaderZIndex}
        // borderBottom={hasScroll ? 1 : "none"}
        borderColor={"divider"}
        sx={{
          background: (theme) =>
            hasScroll ? theme.palette.background.default : "transparent",
          transition: "all 0.3s",
          boxShadow: hasScroll ? "0px 2px 4px 0px rgba(28,33,48,0.5)" : "none",
        }}
      >
        <Stack
          height={29}
          width={120}
          onClick={() => router.push("/")}
          sx={{
            cursor: "pointer",
          }}
        >
          <Image src="/assets/header_logo.png" height={29} width={120} />
        </Stack>
        {/* <Stack
          width={40}
          height={40}
          alignItems={"center"}
          justifyContent={"center"}
          bgcolor={"btn6Active.hover"}
          border={1}
          borderColor={"secondary.main"}
          borderRadius={"50%"}
        >
          <MyIcon name="icon-qianbao2" size={16} />
        </Stack> */}
      </Stack>
    </Stack>
  );
}
