import Link from "next/link";

interface Props {
  children: React.ReactNode;
  href?: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
}

export default function DefaultLink(props: Props) {
  const { href = "", children, target = "_self" } = props;
  return (
    <>
      {href ? (
        <Link
          href={href}
          target={target}
          style={{
            textDecoration: "none",
            outline: "none",
          }}
        >
          {children}
        </Link>
      ) : (
        children
      )}
    </>
  );
}
