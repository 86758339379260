/**
 * 钱包地址显示格式化
 * @param
 * @return
 */
export const formatAddress = (props: { str: string; length?: number }) => {
  const { str, length = 4 } = props;
  try {
    if (str.length <= 8) {
      return str;
    } else {
      return (
        str.substring(0, length) + "..." + str.substring(str.length - length)
      );
    }
  } catch (error) {
    return str;
  }
};
