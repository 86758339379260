/**
 * @file AppInit
 * @description 用于app中初始化的组件
 */
import { useEffect } from "react";
import { useWalletContent, walletNameToKey } from "@/hooks/wallet";
import { setRate } from "@/redux/slices/config";
import { getUSDAndBTCRate } from "@/services/nft";
import { useAppDispatch } from "@/redux";
import { walletApi } from "dotswap-wallet";
import { clearAddress, clearToken } from "@/utils/auth";
import { useResultAlert } from "@/components/public/dialog/ResultAlertRevision";
import { bus } from "@/utils/bus";
import { tokenInvalidBusName } from "@/config/http";

/**
 * @func useInitWallet
 * @description 初始化时调用钱包连接
 */
export const useInitWallet = () => {
  const { connect, disconnect } = useWalletContent();
  const { openResultAlert } = useResultAlert();
  useEffect(() => {
    setTimeout(() => {
      const walletKey = window.localStorage.getItem("wallet");
      if (walletKey) connect({ key: walletKey });
      else disconnect();
    }, 200);
  }, []);

  // 监听钱包
  const { on } = walletApi;
  const onChange = async () => {
    on(async (data: any) => {
      if (data.address && data.name) {
        clearAddress();
        const isSuccess = await connect({ key: walletNameToKey[data.name] });
        if (isSuccess)
          openResultAlert({
            // containerId: 'wallet-connect-result',
            msg: "Wallet connected. Start trading now!",
            type: "succeed",
          });
        else disconnect();
      }
    });
  };
  useEffect(() => {
    onChange();
  }, []);
  useEffect(() => {
    bus.on(tokenInvalidBusName, () => {
      clearAddress();
      clearToken();
      disconnect();
    });
  }, []);
};
/**
 * @func useInitProject
 * @description 初始化项目
 */
export const useInitProject = () => {
  useInitWallet();
};

export default function AppInit() {
  useInitProject();

  const dispatch = useAppDispatch();
  const fetchRate = async () => {
    try {
      const rateRes = await getUSDAndBTCRate();
      dispatch(setRate(rateRes));
    } catch (error) {}
  };
  useEffect(() => {
    fetchRate();
  }, []);

  return <></>;
}
