import React, { ReactNode } from "react";
// import { render } from 'react-dom';
import { createRoot } from "react-dom/client";
import { createUuid } from "@/utils/tools";
import { DialogAlert } from "@/config/layouts";

export function mountComponentToBody(
  Component: React.ReactNode,
  options?: { containerId?: string; zIndex?: number },
): () => void {
  const containerId = options?.containerId || createUuid();
  const zIndex = options?.containerId || DialogAlert;

  const rootContainer = document.createElement("div");
  rootContainer.id = containerId;
  document.body.appendChild(rootContainer);
  rootContainer.style.zIndex = zIndex + "";
  rootContainer.style.position = "fixed";
  rootContainer.style.width = "100vw";
  rootContainer.style.height = "100vh";
  rootContainer.style.left = "0";
  rootContainer.style.right = "0";
  rootContainer.style.top = "0";
  rootContainer.style.bottom = "0";
  // rootContainer.setAttribute("tabindex", "0");

  const root = createRoot(rootContainer);

  root.render(Component);

  return () => {
    root.unmount();
    document.body.removeChild(rootContainer);
  };
}

export default mountComponentToBody;
