export async function getUSDAndBTCRate(): Promise<number> {
  try {
    // 方案一
    // const { data } = await Request.get(`https://alchemist.mempool.com/alchemist/get_rate`);

    // 方案二
    // const { data } = await Request.get(`https://api-btc.tiertop.io/api/v1/rate`);
    // const btc = data?.data?.BTC || '0';
    // const usd = data?.data?.USDT || '1';
    // const result = Big(btc).div(usd).toNumber();
    // return result;

    // 方案三
    return await fetch("https://blockchain.info/ticker")
      .then((response) => response.json())
      .then((data) => {
        return data.USD.last;
      });
  } catch (error) {
    console.log(error);
    return 0;
  }
}
