import { echarts } from "./config";
import ReactECharts from "echarts-for-react/lib/core";
import { EChartOption } from "echarts";
import palette from "@/theme/palette";
import { baseRenderTip } from "./tools";
type TooltipFormatterParams =
  | EChartOption.Tooltip.Format
  | EChartOption.Tooltip.Format[];
import { multipliedBy } from "@/utils/number";

interface Props {
  showXAxis?: boolean;
  xAxisData?: Array<string | number>;
  data: number[];
  formatterLabel?: (value: number) => string;
  renderTip?: (params: TooltipFormatterParams) => React.ReactNode;
  xLabelHeight?: number;
  customValues?: number[];
  axisLabelFormatter?: (value: string) => string;
  yAxisName?: string;
}
export default function LineEcharts(props: Props) {
  const {
    showXAxis = true,
    xAxisData = [],
    data = [],
    formatterLabel = null,
    renderTip = null,
    xLabelHeight = 25,
    customValues = [],
    axisLabelFormatter = null,
    yAxisName = "",
  } = props;
  const tooltipOther: any = {
    className: "echarts-tip",
  };
  const otherAxisLabel: any = {
    customValues,
  };
  const min = data.length > 0 ? Math.min(...data) : "";
  const max = data.length > 0 ? Math.max(...data) : 0;
  const maxRateOfIncrease = min ? ((max - min) / min) * 100 : 0;
  const thresholdValueList: any = {
    50: [0.95, 1.05],
    30: [0.8, 1.2],
    20: [0.7, 1.3],
    10: [0.65, 1.35],
    5: [0.6, 1.4],
    0: [0.5, 1.5],
  };
  const keys: any[] = Object.keys(thresholdValueList)
    .map((item) => Number(item))
    .sort((old, newItem) => newItem - old);
  const rangeKey = keys.find((item) => maxRateOfIncrease >= item);
  const rangeValue = thresholdValueList[rangeKey];
  const options: EChartOption = {
    tooltip: {
      show: true,
      trigger: "axis",
      axisPointer: { lineStyle: { color: palette.divider, type: "solid" } },
      formatter: renderTip
        ? function (params: any) {
            return renderTip ? baseRenderTip(renderTip(params)) : "";
          }
        : undefined,
      ...tooltipOther,
    },
    xAxis: {
      type: "category",
      data: xAxisData,
      show: showXAxis,
      boundaryGap: false,
      axisLine: {
        show: true,
      },
      axisLabel: {
        ...otherAxisLabel,
        color: "#B4B4B6",
        fontSize: 12,
        lineHeight: xLabelHeight,
        formatter: (value: string) => {
          return axisLabelFormatter ? axisLabelFormatter(value) : value;
        },
      },
      axisTick: {
        show: false,
      },
    },
    grid: {
      // 配置grid的上下左右边距，设置为0表示没有边距
      left: 5,
      bottom: 0, // 同上
      right: 0,
      top: 10,
      containLabel: true, // 表示grid区域包含坐标轴的刻度标签
    },
    yAxis: {
      type: "value",
      position: "left",
      // offset: 0 - 10,
      splitLine: {
        show: false,
      },
      axisLabel: {
        align: "right",
        formatter: function (value: number) {
          return formatterLabel ? formatterLabel(value) : value;
        },
        color: "#B4B4B6",
      },
      nameLocation: "start",
      name: yAxisName,
      nameTextStyle: {
        align: "right",
        color: "#B4B4B6",
        padding: [0, 8, 0, 0],
      },
      // align: "right",
      axisTick: {
        // show: false,
      },
      min: multipliedBy(min, rangeValue ? rangeValue[0] : 0.95),
      max: multipliedBy(max, rangeValue ? rangeValue[1] : 1.05),
    },
    series: [{ data, type: "line", showSymbol: false }],
  };
  return (
    <ReactECharts
      echarts={echarts}
      option={options}
      style={{ height: "100%", width: "100%" }}
    />
  );
}
