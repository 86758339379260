import { TypographyPropsVariantOverridesProps } from "@/theme/overrides/Typography";
import { SxProps, styled } from "@mui/material/styles";
import { createTypography } from "@/theme/typography";
import style from "./index.module.scss";

const getPxStr = (value: number | string) =>
  typeof value === "number" ? `${value}px` : value;
interface TextareaProps {
  width?: number | string;
  height?: number | string;
  bgColor?: React.CSSProperties["background"];
  borderRadius?: number | string;
  borderColor?: React.CSSProperties["borderColor"];
}
export const Textarea = styled("textarea")<TextareaProps>((options) => {
  const { ...other } = options;
  return {
    "&:focus-visible": {
      outline: "none",
    },
    border: "1px solid",
    resize: "none",
    ...other,
  };
});

interface Props {
  height?: number;
  width?: number;
  variant?: TypographyPropsVariantOverridesProps;
  backgroundColor?: React.CSSProperties["backgroundColor"];
  borderRadius?: number | string;
  borderColor?: React.CSSProperties["borderColor"];
  padding?: number | string;
  color?: string;
  value?: string;
  onChange?: (value: string) => void;
}

export default function BaseTextarea(props: Props) {
  const {
    width = "100%",
    height = 159,
    backgroundColor = "transparent",
    borderRadius = 20,
    borderColor = "#5E5F62",
    variant = "subtitle2",
    padding = "16px",
    color = "#fff",
    onChange = null,
    value = "",
  } = props;
  const t = createTypography();
  // const handleWidth = getPxStr(width);
  // const handleHeight = getPxStr(height);
  const onChangeFn = (value: string) => {
    console.log("value", value);
    onChange?.(value);
  };
  return (
    <textarea
      className={style["base-textarea"]}
      style={{
        width: getPxStr(width),
        height: getPxStr(height),
        border: "1px solid",
        backgroundColor,
        borderRadius,
        borderColor,
        padding,
        color,
        resize: "none",
        ...t[variant],
      }}
      // sx={{
      //   color,
      //   ...sx,
      // }}
      value={value}
      onChange={(e: any) => onChangeFn(e.target.value)}
    />
  );
}
