import { WALLET_NAME } from "@/config/wallet";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";
import MyIcon from "@/components/public/my-icon";
import Image from "@/components/public/image";
import { useWalletContent, walletNameDict } from "@/hooks/wallet";
import { useState } from "react";
import { getIsHasWallet } from "dotswap-wallet";
import { useResultAlert } from "@/components/public/dialog/ResultAlertRevision";
import { DialogIndex } from "@/config/layouts";
import BaseDialogBox from "./BaseDialogBox";

interface ConnectWalletProps {
  succeedCb?: () => void;
  open?: boolean;
  close?: () => void;
}

export default function ConnectWallet(props: ConnectWalletProps) {
  const { open = false, succeedCb = null, close = null } = props;

  const totalWalletList = [
    {
      label: "Xverse Wallet",
      tip: "Recommended",
      image: "/assets/logo-xverse.png",
      key: WALLET_NAME.XV,
      link: "https://chrome.google.com/webstore/detail/xverse-wallet/idnnbdplmphpflfnlkomgpfbpcgelopg",
    },
    {
      label: "OKX Wallet",
      tip: "",
      key: WALLET_NAME.OK,
      image: "/assets/logo-okx.png",
      link: "https://chrome.google.com/webstore/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge",
    },
    {
      label: "Unisat Wallet",
      tip: "",
      image: "/assets/unisat.png",
      size: 40,
      key: WALLET_NAME.Uni,
      link: "https://chrome.google.com/webstore/detail/unisat-wallet/ppbibelpcjmhbdihakflkdcoccbgbkpo",
    },
    {
      label: "Magic Eden",
      image: "/assets/magic_eden_icon.png",
      key: WALLET_NAME.ME,
      link: "https://chromewebstore.google.com/detail/magic-eden-wallet/mkpegjkblkkefacfnmkajcjmabijhclg",
    },
    {
      label: "Wizz Wallet",
      tip: "Formerly Atom wallet",
      image: "/assets/atom.png",
      key: WALLET_NAME.Atom,
      link: "https://chromewebstore.google.com/detail/atom-wallet/ghlmndacnhlaekppcllcpcjjjomjkjpg?utm_source=ext_app_menu",
    },
    // {
    //   label: "Bitget Wallet",
    //   image: "/assets/bitget.png",
    //   key: WALLET_NAME.BG,
    //   link: "https://chromewebstore.google.com/detail/bitget-wallet-formerly-bi/jiidiaalihmmhddjgbnbgdfflelocpak",
    // },
  ];
  const { openResultAlert } = useResultAlert();
  const handleConnectSuccess = () => {
    console.log("成功");
    handleLinkClose();
    succeedCb?.();
    openResultAlert({
      // containerId: 'wallet-connect-result',
      msg: "Wallet connected. Start trading now!",
      type: "succeed",
    });
  };

  const [loadingStatus, setLoadingStatus] = useState({
    key: "",
    loading: false,
  });
  const { connect, disconnect } = useWalletContent();
  const totalConnect = async (data: any) => {
    const { key, link } = data;
    setLoadingStatus({
      key,
      loading: true,
    });
    const isSuccess = await connect({
      key,
      errorCb: () => {
        handleLinkClose();
      },
    });
    setLoadingStatus({
      key,
      loading: false,
    });
    if (isSuccess) handleConnectSuccess();
    else handleLinkClose();

    if (!isSuccess && !getIsHasWallet(walletNameDict[key])) {
      window.open(link);
      setLoadingStatus({
        key,
        loading: false,
      });
      return;
    }
  };
  const handleLinkClose = () => {
    close?.();
  };
  return (
    <BaseDialogBox open={open} width={450}>
      <Stack bgcolor={"rgb(31, 34, 39)"} borderRadius={1.25}>
        <Box pt={2} position="relative">
          <Typography
            variant="h3"
            width={"100%"}
            textAlign={"left"}
            color="primary.main"
            pl={3}
          >
            Connect Wallet
          </Typography>
          <MyIcon
            name="icon-close"
            size={16}
            onClick={() => handleLinkClose()}
            sx={{
              position: "absolute",
              right: 16,
              top: 16,
            }}
          />
        </Box>

        <Stack pt={3} height={"fit-content"} p={"0 0 30px 0"} mt={"24px"}>
          <Stack
            spacing={1}
            boxSizing={"border-box"}
            overflow={"auto"}
            px={3}
            className="scrollbar"
          >
            {totalWalletList.map((item, index) => (
              <Stack
                position={"relative"}
                key={index}
                height={80}
                width={"100%"}
                direction={"row"}
                bgcolor={"background.textPaper"}
                px={4}
                onClick={() => {
                  if (loadingStatus.loading) return;
                  totalConnect(item);
                }}
                justifyContent={"space-between"}
                alignItems={"center"}
                borderRadius={"8px"}
                sx={{
                  cursor: loadingStatus.loading ? "not-allowed" : "pointer",
                }}
              >
                <Stack alignItems={"center"} direction={"row"} spacing={2}>
                  <Image src={item.image} size={40} />
                  <Stack>
                    <Typography variant="subtitle1">{item.label}</Typography>
                    <Typography variant="caption" color={"text.secondary"}>
                      {item.tip}
                    </Typography>
                  </Stack>
                </Stack>

                {loadingStatus.loading && loadingStatus.key === item.key ? (
                  <MyIcon
                    name="icon-a-xingzhuangjiehe1"
                    size={16}
                    className="rotate-animation"
                    sx={{
                      opacity: 0.5,
                    }}
                    fill="url('#linear')"
                    onClick={() => handleLinkClose()}
                  >
                    <defs>
                      <linearGradient
                        id="linear"
                        x1="0%"
                        y1="0%"
                        x2="100%"
                        y2="0%"
                      >
                        <stop
                          offset="0%"
                          style={{
                            stopColor: "#D8D8D8",
                          }}
                        />
                        <stop
                          offset="100%"
                          style={{
                            stopColor: "#DFDFDF",
                          }}
                        />
                      </linearGradient>
                    </defs>
                  </MyIcon>
                ) : null}
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </BaseDialogBox>
    // <Dialog
    //   maxWidth="xs"
    //   open={open}
    //   onClose={handleLinkClose}
    //   fullWidth
    //   sx={{
    //     zIndex: DialogIndex,
    //   }}
    // >
    // </Dialog>
  );
}
