import {
  Stack,
  Typography,
  Button,
  Backdrop,
  TextareaAutosize,
} from "@mui/material";
import { createTypography } from "@/theme/typography";
import MyIcon from "@/components/public/my-icon";
import { useEffect, useState } from "react";
import { formatId } from "@/utils/wallet";
import { DialogIndex } from "@/config/layouts";
import { MenusBox } from "@/components/public/menus";
import Image from "@/components/public/image";
import { useAlertToast } from "@/components/public/dialog/AlertToast";
import { useResultAlert } from "@/components/public/dialog/ResultAlertRevision";
import BaseTextarea from "@/components/public/textarea/BaseTextarea";
import Input from "@/components/public/input";
import BaseButton from "@/components/public/button/BaseButton";
import {
  walletAccountGetAccount,
  walletAccountUpdateAddressRemark,
  walletAccountManualAddAddress,
  walletAccountRemoveAddress,
} from "@/services/wallet_manager";
import { WalletAccountGetAccountItem } from "@/types/wallet_manager";
import { get } from "lodash";
import { useWalletContent } from "@/hooks/wallet";
import ConnectWallet from "@/components/public/dialog/ConnectWallet";
import { height, width } from "@mui/system";
import { useAppSelector } from "@/redux";
import { useUpdateAccount } from "@/hooks/wallet";
import { clearAddress, getAddress, clearToken } from "@/utils/auth";
import { isValidAddress } from "@/utils/wallet";
import { SnackbarUtils } from "@/components/public/snackbar/SnackbarProvider";

export const useAddressGroup = () => {
  const { openAlertToast } = useAlertToast();
  const { openResultAlert } = useResultAlert();
  const { address_list } = useAppSelector((state) => state.account);
  const list = address_list;
  const [openAdd, setOpenAdd] = useState(false);
  const [addrGroup, setAddrGroup] = useState("");
  const [openAddrToGroup, setOpenAddrToGroup] = useState(false);
  const addGroupAddress = (value: string) => {
    const addGroup = value.split(/\r\n|\n|\r/);
    setAddrGroup(value);
  };
  const typography = createTypography();
  const { updateAccount } = useUpdateAccount();
  const [addGroupAddressSubmitLoading, setAddGroupAddressSubmitLoading] =
    useState(false);
  const addGroupAddressSubmit = async (group = addrGroup) => {
    setAddGroupAddressSubmitLoading(true);
    const address = group
      .split("\n")
      .filter((item) => item.trim())
      .map((item) => item.trim());
    const addAddr = await walletAccountManualAddAddress({
      address,
    }).catch((e) => {
      setAddrGroup("");
      setOpenAdd(false);
      setAddGroupAddressSubmitLoading(false);
      throw e;
    });
    setAddrGroup("");
    setOpenAdd(false);
    setAddGroupAddressSubmitLoading(false);
    const { duplicate_address = [] } = addAddr.data || {};
    if (addAddr.code === 10170005) {
      // 失败
      const goOn = "goOn";
      const closeText = "close";
      const res = await openResultAlert({
        isShowTitle: false,
        type: "fail",
        msg: (
          <Typography variant="subtitle3">
            These addresses (
            <Typography variant="subtitle3" color={"secondary.main"}>
              {duplicate_address.map((item) => formatId(item)).join(",")}
            </Typography>
            ) are already linked to other address groups and cannot be linked.
            To re-link, please connect your wallet to these addresses and
            perform the re-link operation.
          </Typography>
        ),
        closeText: "Got it",
        footerRender: (close) => {
          return (
            <Stack direction={"row"} spacing={2} mt={3}>
              <BaseButton
                variant={"glow"}
                height={56}
                textVariant="subtitle3"
                onClick={() => close(goOn)}
              >
                Continue
              </BaseButton>
              <BaseButton
                variant={"primary"}
                height={56}
                textVariant="subtitle3"
                onClick={() => close(closeText)}
              >
                Cancel
              </BaseButton>
            </Stack>
          );
        },
      });
      if (res === goOn) {
        const list = address.filter(
          (item) =>
            !duplicate_address.includes(item) ||
            !duplicate_address.includes(item.trim()),
        );
        if (list.length === 0) {
          SnackbarUtils.error("No linkable addresses");
          return;
        } else {
          addGroupAddressSubmit(list.join("\n"));
        }
      }
      return;
    }
    if (addAddr.code === 10170007) {
      SnackbarUtils.error("No linkable addresses");
      return;
    }
    // 成功
    updateAccount();
    await openResultAlert({
      type: "succeed",
      title: "Added successfully",
    });
  };
  const addAddrToGroup = async () => {
    setOpenAddrToGroup(false);
    await openResultAlert({
      type: "succeed",
      title: "Added successfully",
    });
  };
  const closeAdd = () => {
    setOpenAdd(false);
    setAddrGroup("");
  };

  const edit = () => {
    console.log("编辑");
  };
  const { disconnect } = useWalletContent();
  const unbind = async (data: WalletAccountGetAccountItem) => {
    const accountRes = await walletAccountGetAccount();
    const { items = [] } = accountRes.data;
    const signItems = items.filter((item) => item.is_signed);
    const isLast =
      signItems.length === 1 && signItems[0].address === data.address;
    if (!isLast) {
      const res = await openAlertToast({
        msg: "Are you sure you want to unlink this address?",
      });
      if (res) {
        await walletAccountRemoveAddress({
          address: data.address,
        });
        if (data.address === getAddress().address) {
          disconnect();
          clearAddress();
        } else {
          updateAccount();
        }
        await openResultAlert({
          type: "succeed",
          title: "Unlinked successfully",
        });
      }
    } else {
      const res = await openAlertToast({
        msg: "Unlinking this signature verified address will clear the address group data. Do you want to proceed?",
      });
      if (res) {
        await walletAccountRemoveAddress({
          address: data.address,
        });
        clearAddress();
        clearToken();
        disconnect();
        await openResultAlert({
          type: "succeed",
          title: "Unlinked successfully",
          msg: "The address group data has been cleared.",
        });
      }
    }
  };

  const [isEdit, setIsEdit] = useState(false);
  const defaultEditItem = {
    account_id: "",
    address: "",
    is_signed: false,
    remark: "",
  };
  const [editItem, setEditItem] =
    useState<WalletAccountGetAccountItem>(defaultEditItem);
  const [editText, setEditText] = useState("");
  const openEdit = (data: WalletAccountGetAccountItem) => {
    setEditItem(data);
    setEditText(data.remark);
    setIsEdit(true);
  };
  const closeEdit = () => {
    setEditText("");
    setEditItem({ ...defaultEditItem });
    setIsEdit(false);
  };
  const saveEdit = async (data: WalletAccountGetAccountItem) => {
    console.log("提交信息", editText);
    setIsEdit(false);
    await walletAccountUpdateAddressRemark({
      items: [{ address: data.address, remark: editText }],
    });
    await openResultAlert({
      type: "succeed",
      title: "Saved successfully",
    });
    updateAccount();
  };

  // 连接钱包相关
  const openAddressAlert = async () => {
    const res = await openResultAlert({
      type: "warn",
      minHeight: 280,
      width: 419,
      render: (close) => {
        const getAttr = (color = ""): any => {
          return {
            height: 84,
            width: 385,
            borderRadius: "22px",
            alignItems: "center",
            direction: "row",
            px: "34px",
            border: 1,
            borderColor: color,
            color,
            spacing: "18px",
            sx: {
              cursor: "pointer",
            },
          };
        };
        return (
          <Stack pt={"62px"} alignItems={"center"}>
            <Stack spacing={"20px"}>
              <Stack {...getAttr("#2A85FF")} onClick={() => close("1")}>
                <MyIcon
                  name="icon-piliangtianjia"
                  size={34}
                  color="currentColor"
                />
                <Typography variant="h6" color="currentColor">
                  Add in Bulk
                </Typography>
              </Stack>
              <Stack {...getAttr("#B028F9")} onClick={() => close("2")}>
                <MyIcon
                  name="icon-a-qianbao22"
                  size={34}
                  color="currentColor"
                  sx={{
                    flex: "none",
                  }}
                />
                <Stack>
                  <Typography variant="h6" color="currentColor">
                    Connect Wallet and Add Individually
                  </Typography>
                  <Typography variant="caption" color="currentColor">
                    (Please switch to the address you want to link in your
                    browser wallet first)
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        );
      },
    });
    if (res === "1") setOpenAdd(true);
    if (res === "2") setOpenConnectWallet(true);
  };
  const [openConnectWallet, setOpenConnectWallet] = useState(false);

  return {
    list,
    openAdd,
    setOpenAdd,
    edit,
    unbind,
    addrGroup,
    addGroupAddress,
    addGroupAddressSubmit,
    openAddrToGroup,
    addAddrToGroup,
    openEdit,
    isEdit,
    editText,
    setEditText,
    closeEdit,
    saveEdit,
    openConnectWallet,
    openAddressAlert,
    setOpenConnectWallet,
    editItem,
    closeAdd,
    addGroupAddressSubmitLoading,
  };
};

export default function AddressGroup() {
  const typography = createTypography();
  const [openMenus, setOpenMenus] = useState(false);
  const {
    list,
    openAdd,
    setOpenAdd,
    edit,
    unbind,
    addrGroup,
    addGroupAddress,
    addGroupAddressSubmit,
    openAddrToGroup,
    addAddrToGroup,
    openEdit,
    isEdit,
    editText,
    editItem,
    setEditText,
    closeEdit,
    saveEdit,
    openAddressAlert,
    openConnectWallet,
    setOpenConnectWallet,
    closeAdd,
    addGroupAddressSubmitLoading,
  } = useAddressGroup();
  const listItemHeight = 50;
  const remarkList = [
    {
      ok: true,
      text: "Enter your address, one per line",
    },
    {
      ok: true,
      text: "Add both BTC and Ordinals addresses if using ME Wallet or Xverse Wallet",
    },
    {
      ok: false,
      text: "Do not add other people’s addresses to avoid issues like data discrepancies",
    },
  ];
  const addrGroupSplitArray = addrGroup.split("\n");
  const validList = addrGroupSplitArray.filter(
    (item) => isValidAddress(item.trim()) && item.trim(),
  );
  const isError = addrGroupSplitArray.some(
    (item) => item.trim() && !isValidAddress(item.trim()),
  );
  const addLen = validList.length;
  return (
    <Stack>
      <Stack mb={"22px"}>
        <MenusBox
          p={0}
          onClose={() => closeEdit()}
          render={(_, close) => (
            <Stack
              px={2}
              py={3}
              spacing={"15px"}
              minWidth={384}
              boxSizing={"border-box"}
            >
              {list.map((item, key) => (
                <Stack
                  key={key}
                  spacing={"2px"}
                  direction={"row"}
                  // justifyContent={"space-between"}
                  alignItems={"flex-start"}
                >
                  <Stack>
                    <Stack alignItems={"center"} direction={"row"}>
                      <Typography variant="subtitle2" width={100}>
                        {formatId(item.address)}
                      </Typography>
                      <Stack direction={"row"} spacing={0.75}>
                        {item.is_signed ? (
                          <Image size={16} src="/assets/signed_icon.png" />
                        ) : null}

                        {(isEdit && editItem.address !== item.address) ||
                        !isEdit ? (
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={0.75}
                          >
                            <MyIcon
                              name="icon-bianji2"
                              size={15}
                              color="text.secondary"
                              onClick={() => openEdit(item)}
                            />
                            <Stack
                              width={18}
                              height={18}
                              alignItems={"center"}
                              justifyContent={"center"}
                              border={1}
                              borderColor={"text.secondary"}
                              borderRadius={"50%"}
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                close?.();
                                unbind(item);
                              }}
                            >
                              <MyIcon
                                name="icon-close"
                                size={8}
                                color="text.secondary"
                              />
                            </Stack>
                          </Stack>
                        ) : null}
                      </Stack>
                    </Stack>
                    {isEdit && editItem.address === item.address ? (
                      <Stack direction={"row"} spacing={1} mt={"10px"}>
                        <Input
                          color={"text.secondary"}
                          fontSize={14}
                          p="9px 16px"
                          autoFocus
                          boxHeight={38}
                          maxLength={50}
                          width={180}
                          placeholder="Up to 50 characters"
                          value={editText}
                          change={(value) => setEditText(value)}
                        />
                        <BaseButton
                          variant={"glow"}
                          width={72}
                          height={36}
                          textVariant="subtitle3"
                          onClick={() => {
                            close?.();
                            saveEdit(item);
                          }}
                        >
                          Save
                        </BaseButton>
                        <BaseButton
                          variant={"primary"}
                          width={72}
                          height={36}
                          textVariant="subtitle3"
                          onClick={() => closeEdit()}
                        >
                          Cancel
                        </BaseButton>
                      </Stack>
                    ) : (
                      <>
                        {item.remark ? (
                          <Typography
                            variant="caption"
                            color={"text.secondary"}
                            mt={"2px"}
                          >
                            ({item.remark})
                          </Typography>
                        ) : null}
                      </>
                    )}
                  </Stack>
                </Stack>
              ))}
            </Stack>
          )}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            // display={"inline-flex"}
            sx={{
              cursor: "pointer",
            }}
          >
            <Typography variant="h2">My Wallet ({list.length})</Typography>
            &nbsp; &nbsp;
            <MyIcon
              name="icon-down"
              size={20}
              onClick={() => setOpenMenus(!openMenus)}
            />
          </Stack>
        </MenusBox>
      </Stack>
      <Stack mb={"28px"}>
        <Button
          variant={"primary"}
          sx={{
            width: 130,
            height: 44,
            ...typography.subtitle3,
          }}
          onClick={() => openAddressAlert()}
        >
          Link Address
        </Button>
      </Stack>
      <Backdrop
        aria-hidden={false}
        open={openAdd}
        onClick={() => {
          // 不要点击任意地方都关闭，否则用户会误点，看不清提示内容
          // if (onClick) {
          //   onClick();
          //   closeCb();
          // }
        }}
        sx={{
          zIndex: DialogIndex,
        }}
      >
        <Stack
          width={662}
          height={447}
          borderRadius={2.5}
          bgcolor={"#272931"}
          position={"relative"}
          p={3}
        >
          <Stack
            width={16}
            height={16}
            position={"absolute"}
            right={24}
            top={24}
          >
            <MyIcon name="icon-close" size={16} onClick={() => closeAdd()} />
          </Stack>
          <Stack mb={"22px"} spacing={"11px"}>
            {remarkList.map((item, index) => (
              <Stack
                key={index}
                direction={"row"}
                alignItems={"center"}
                spacing={"12px"}
              >
                <MyIcon
                  name={item.ok ? "icon-dagou" : "icon-guanbi"}
                  size={20}
                  color={item.ok ? "success.main" : "error.main"}
                />
                <Typography variant="subtitle2">{item.text}</Typography>
              </Stack>
            ))}
          </Stack>
          <BaseTextarea
            value={addrGroup}
            onChange={(value) => addGroupAddress(value)}
          />
          {isError ? (
            <Typography variant="subtitle2" color="error.main" mt={2}>
              Wrong format
            </Typography>
          ) : null}
          <Typography
            mt={2}
            variant="subtitle2"
            color={"text.secondary"}
            mb={"32px"}
          >
            Total addresses {addLen}
          </Typography>
          <BaseButton
            variant={"glow"}
            disabled={!addLen || isError}
            loading={addGroupAddressSubmitLoading}
            sx={{
              height: 56,
              ...typography.subtitle2,
              borderRadius: "20px",
            }}
            onClick={() => addGroupAddressSubmit()}
          >
            Submit
          </BaseButton>
        </Stack>
      </Backdrop>
      <Backdrop
        aria-hidden={false}
        open={openAddrToGroup}
        onClick={() => null}
        sx={{
          zIndex: DialogIndex,
        }}
      >
        <Stack
          width={482}
          height={303}
          borderRadius={2.5}
          bgcolor={"#272931"}
          position={"relative"}
          p={3}
        >
          <Stack
            width={16}
            height={16}
            position={"absolute"}
            right={24}
            top={24}
          >
            <MyIcon
              name="icon-close"
              size={16}
              onClick={() => setOpenAdd(false)}
            />
          </Stack>
          <Typography variant="subtitle2" mb={"18px"} textAlign={"center"}>
            填写你想绑定的地址组内已签名认证的任一地址
          </Typography>
          <BaseTextarea
            height={137}
            value={addrGroup}
            onChange={(value) => addGroupAddress(value)}
          />
          <Button
            variant={"glow"}
            sx={{
              height: 56,
              borderRadius: "22px",
              ...typography.subtitle3,
              mt: "20px",
            }}
            onClick={addAddrToGroup}
          >
            Submit
          </Button>
        </Stack>
      </Backdrop>
      <ConnectWallet
        open={openConnectWallet}
        close={() => setOpenConnectWallet(false)}
      />
    </Stack>
  );
}
