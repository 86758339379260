import { Stack, Typography } from "@mui/material";
import FaceTabs from "@/components/public/tabs/FaceTabs";
import { ListItem, TabsItem } from "@/types/components";
import { useEffect, useState } from "react";
import LineEcharts from "@/components/public/echarts/LineEcharts";
import style from "./index.module.scss";
import { createTypography } from "@/theme/typography";
import { TipParams } from "@/types/echarts";
import palette from "@/theme/palette";
import { getUnixDateFormatStr } from "@/utils/formatTime";
import {
  walletAccountAddressPortfolioTrend,
  walletWalletAccountPortfolioTrend,
} from "@/services/wallet_manager";
import {
  WalletAccountAddressPortfolioTrendItem,
  WalletWalletAccountPortfolioTrend,
} from "@/types/wallet_manager";
import { useSearchAddress } from "@/hooks/router";
import { sliceNumber, minus, div, multipliedBy } from "@/utils/number";
import { satoshisToAmount, satoshisToUsd } from "@/utils/bitcoin";
import { useAppSelector } from "@/redux";
import { chunk } from "lodash";
import dayjs from "dayjs";
import { useUpdateCb } from "@/components/pc/v1/wallet/hook";
import { useUpdateAddressData } from "./hook";

export const getIncreaseProportion = (
  number: number | string,
  number2: number | string,
  numberTime: number,
  number2Time: number,
  options?: {
    openLessOneDecimal?: boolean;
    lessOneDecimal?: number;
    decimal?: number;
  },
) => {
  const {
    openLessOneDecimal = false,
    lessOneDecimal = 6,
    decimal = 2,
  } = options || {};
  const value = multipliedBy(div(minus(number, number2), number2), 100);
  const handleValue =
    openLessOneDecimal && Math.abs(Number(value)) < 1
      ? sliceNumber(value, lessOneDecimal)
      : sliceNumber(value, decimal);
  return number2 == 0 || number2Time >= numberTime ? "0" : handleValue;
};
export const getColor = (number: number | string) => {
  if (Number(number) >= 0) return "#11B33C";
  return "#F33838";
};
const getComputeAttr = (value: string) =>
  value === "1" ? "btc_assets" : "usd_assets";

export function EchartsTip(props: {
  params: WalletAccountAddressPortfolioTrendItem;
  comparison: WalletAccountAddressPortfolioTrendItem;
  type: string;
}) {
  const t = createTypography();
  const { params, comparison, type } = props;
  const { time, btc_assets, usd_assets } = params as any;
  const value = getIncreaseProportion(
    params[getComputeAttr(type)],
    comparison[getComputeAttr(type)],
    params.time,
    comparison.time,
    { openLessOneDecimal: true },
  );
  const changeValue1 = `${satoshisToAmount(minus(btc_assets, comparison.btc_assets))} BTC`;
  const changeValue2 = `$${sliceNumber(minus(usd_assets, comparison.usd_assets), 2)}`;
  const handleChangeValue = type === "1" ? changeValue1 : changeValue2;
  const usdValue =
    comparison.time >= params.time
      ? type === "1"
        ? "0 BTC"
        : "$0"
      : handleChangeValue;
  const list = [
    { value: getUnixDateFormatStr(time), color: palette.text.primary, mb: 5 },
    {
      value:
        type == "1"
          ? `${satoshisToAmount(btc_assets)} BTC ($${sliceNumber(usd_assets, 2)})`
          : `$${sliceNumber(usd_assets, 2)} (${satoshisToAmount(btc_assets)} BTC)`,
      color: "#93B6E6",
      mb: 2,
    },
    {
      value: `${value}% (${usdValue})`,
      color: getColor(value),
    },
  ];
  return (
    <div className={style["asset-dist-tip"]}>
      {list.map((item, key) => (
        <div
          key={key}
          style={{
            ...t.caption,
            color: item.color,
            marginBottom: item.mb || 0,
          }}
        >
          {item.value}
        </div>
      ))}
    </div>
  );
}

interface GetTrendProps {}

export const useAssetTrendData = (props: AssetTrendProps) => {
  const { address, api = undefined } = props;
  const tabs = [
    { label: "BTC", value: "1" },
    { label: "USD", value: "2" },
  ];
  const [tabsActive, setTabsActive] = useState(tabs[0].value);
  const defaultHoverItem = {
    usd_assets: "0",
    btc_assets: "0",
    time: 0,
  };
  const [hoverItem, setHoverItem] =
    useState<WalletAccountAddressPortfolioTrendItem>(defaultHoverItem);
  const setHoverItemFn = (data: WalletAccountAddressPortfolioTrendItem) => {
    setHoverItem(data || { ...defaultHoverItem });
  };

  const typeList = [
    { label: "1D", value: "1d" },
    { label: "1W", value: "1w" },
    { label: "1M", value: "1m" },
    { label: "1Y", value: "1y" },
  ];
  const [typeActive, setTypeActive] = useState(typeList[0].value);
  const typeChange = async (data: TabsItem) => {
    await getTotalList(data.value);
    setTypeActive(data.value);
  };

  const [lineList, setLineList] = useState<
    WalletAccountAddressPortfolioTrendItem[]
  >([]);
  const lineData = lineList.map((item) =>
    tabsActive === "1" ? Number(item.btc_assets) : Number(item.usd_assets),
  );
  const lineXAxis = lineList.map((item) => item.time);
  const xShowLabelDict: any = {
    "1d": 8,
    "1w": 7,
    "1m": 5,
    "1y": 3,
  };
  const xLabelFormatter: any = {
    "1d": "A HH:mm",
    "1w": "ddd",
    "1m": "MM-DD",
    "1y": "MMM",
  };
  const getListCenterPoint = (props?: {
    totalNumbers?: number;
    segments?: number;
  }) => {
    const { totalNumbers = 144, segments = 7 } = props || {};
    const segmentLength = Math.floor(totalNumbers / segments);
    const centers: number[] = [];
    for (let i = 0; i < segments; i++) {
      const startIndex = i * segmentLength;
      const endIndex = startIndex + segmentLength;
      const centerIndex = Math.floor((startIndex + endIndex) / 2);
      centers.push(centerIndex);
    }
    return centers;
  };

  const getAddressTrend = async (metric = typeActive) => {
    const res = await walletAccountAddressPortfolioTrend({
      address: searchAddress,
      metric,
    });
    const { items = [] } = res.data;
    setLineList(items);
    setHoverItemFn(items[items.length - 1]);
  };
  const getAccountTrend = async (metric = typeActive) => {
    const obj: any = {
      metric,
    };
    if (address) obj.address = address;
    let res: null | WalletWalletAccountPortfolioTrend["res"] = null;
    if (api) res = await api?.(obj);
    else res = await walletWalletAccountPortfolioTrend(obj);
    if (!res) return;
    const { items = [] } = res.data;
    setLineList(items);
    setHoverItemFn(items[items.length - 1]);
  };
  const getTotalList = async (metric = typeActive) => {
    console.log("getTotalList", metric);
    if (searchAddress) await getAddressTrend(metric);
    else await getAccountTrend(metric);
  };
  const { searchAddress } = useSearchAddress({
    cb: () => {
      getAccountTrend();
    },
    searchCb: () => {
      getAddressTrend();
    },
  });
  useUpdateCb({
    updateCb: getTotalList,
  });
  const { usdRate } = useAppSelector((state) => state.config);
  const computeAttr = getComputeAttr(tabsActive);
  const firstNotZeroItem = lineList.find(
    (item) => Number(item[computeAttr]) > 0,
  ) ||
    lineList[0] || { btc_assets: 0, usd_assets: 0 };
  const hoverIncreaseProportion = getIncreaseProportion(
    hoverItem[computeAttr],
    firstNotZeroItem[computeAttr],
    hoverItem.time,
    firstNotZeroItem.time,
    { openLessOneDecimal: true },
  );

  useUpdateAddressData({
    cb: () => {
      getAccountTrend();
    },
    compProps: props,
    isUseCbFn: () => {
      return !searchAddress;
    },
  });
  useEffect(() => {
    if (api) getAccountTrend();
  }, []);
  return {
    typeChange,
    lineData,
    lineXAxis,
    xShowLabelDict,
    xLabelFormatter,
    getListCenterPoint,
    hoverIncreaseProportion,
    tabs,
    tabsActive,
    setTabsActive,
    hoverItem,
    lineList,
    firstNotZeroItem,
    typeList,
    typeActive,
    setHoverItemFn,
  };
};

export interface AssetTrendProps {
  address: string;
  api?: any;
}
export default function AssetTrend(props: AssetTrendProps) {
  const {
    typeChange,
    lineData,
    lineXAxis,
    xShowLabelDict,
    xLabelFormatter,
    getListCenterPoint,
    hoverIncreaseProportion,
    tabs,
    tabsActive,
    setTabsActive,
    hoverItem,
    lineList,
    firstNotZeroItem,
    typeList,
    typeActive,
    setHoverItemFn,
  } = useAssetTrendData(props);
  // useEffect(() => {
  //   getTotalList();
  // }, [searchAddress]);

  // const data = [100, 100, 100, 101, 100, 100, 100, 100, 100, 100];
  // const xData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <Stack width={"100%"} p={"18px"} height={"100%"}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        width={"100%"}
        justifyContent={"space-between"}
        mb={2.5}
      >
        <Typography variant="h4">Performance</Typography>
        <FaceTabs
          textVariant="caption"
          list={tabs}
          value={tabsActive}
          height={26}
          change={(value: any) => {
            setTabsActive(value);
          }}
        />
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        {tabsActive == "1" ? (
          <Stack direction={"row"} alignItems={"center"} mb={1}>
            <Typography variant={"body1"}>
              {satoshisToAmount(hoverItem.btc_assets)} BTC
            </Typography>
            <Typography variant={"body2"} color={"text.secondary"}>
              (${sliceNumber(hoverItem.usd_assets, 2)})
            </Typography>
          </Stack>
        ) : (
          <Stack direction={"row"} alignItems={"center"} mb={1}>
            <Typography variant={"body2"}>
              ${sliceNumber(hoverItem.usd_assets, 2)}
            </Typography>
            <Typography variant={"body1"} color={"text.secondary"}>
              ({satoshisToAmount(hoverItem.btc_assets)} BTC)
            </Typography>
          </Stack>
        )}
        <Typography variant={"body2"} color={"text.secondary"}>
          {lineList[lineList.length - 1]?.time
            ? getUnixDateFormatStr(
                lineList[lineList.length - 1]?.time || 0,
                "YYYY-MM-DD HH:mm",
              )
            : "-"}
        </Typography>
      </Stack>
      <Stack
        mb={"34px"}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant={"body1"} color={getColor(hoverIncreaseProportion)}>
          {Number(firstNotZeroItem.usd_assets)
            ? hoverIncreaseProportion + "%"
            : "-"}
        </Typography>
        <Stack direction={"row"} spacing={"10px"}>
          {typeList.map((item, index) => (
            <Stack
              key={index}
              py={"2px"}
              px={"10px"}
              borderRadius={"16px"}
              color={
                item.value === typeActive ? "text.primary" : "text.secondary"
              }
              bgcolor={
                item.value === typeActive ? "secondary.main" : "transparent"
              }
              sx={{
                cursor: "pointer",
                "&:hover": {
                  color: "text.primary",
                },
              }}
              onClick={() => typeChange(item)}
            >
              <Typography variant="caption">{item.label}</Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Stack
        width={"100%"}
        height={182 + 42 + 18}
        // mb={"18px"}
        onMouseLeave={() => {
          setHoverItemFn(lineList[lineList.length - 1] as any);
        }}
      >
        <LineEcharts
          data={lineData}
          xAxisData={lineXAxis}
          customValues={getListCenterPoint({
            totalNumbers: lineData.length,
            segments: xShowLabelDict[typeActive],
          })}
          axisLabelFormatter={(value: string) => {
            const formatter = xLabelFormatter[typeActive];
            return dayjs.unix(Number(value)).format(formatter);
          }}
          yAxisName={tabsActive === "1" ? "BTC" : ""}
          formatterLabel={(value) => {
            return tabsActive === "1"
              ? satoshisToAmount(value, false)
              : "$" + sliceNumber(value, 0);
            // return tabsActive !== "1" ? "111" : satoshisToAmount(value);
          }}
          renderTip={(params: any) => {
            const data = lineList[params[0].dataIndex || 0];
            setHoverItemFn(data);
            return (
              <EchartsTip
                params={data}
                comparison={firstNotZeroItem}
                type={tabsActive}
              />
            );
          }}
        />
      </Stack>
      {/* <Stack
        height={42}
        alignItems={"center"}
        direction={"row"}
        borderTop={1}
        borderColor={"divider"}
        width={`calc(100% - ${linePaddingRight - 20}px)`}
      >
        {xLabelList.map((item, key) => (
          <Typography
            key={key}
            variant={"caption"}
            width={`calc(100% / ${xLabelList.length})`}
            align="center"
            color={"text.secondary"}
          >
            {item}
          </Typography>
        ))}
      </Stack> */}
    </Stack>
  );
}
