import { getAssetsUrl } from "@/utils/config";

// 平台代币图标
// export const PLATFORM_ICON = 'https://hwimg.ddpurse.com/upload/20230910/885452366986407936.png';
export const PLATFORM_ICON = getAssetsUrl("/assets/local_logo/ds-logo.png");
// ATOM官方图标（目前atom爬不到图标，所以要先用本地的）
export const ATOM_ICON = getAssetsUrl("/assets/local_logo/atom-logo.png");
export const PAI_ICON = getAssetsUrl("/assets/local_logo/pai.png");
export const BTC_ICON = "https://static.ddpurse.com/bsv-swap/BTC.png";
export const DOGE_ICON = getAssetsUrl("/assets/local_logo/doge_chain.png");
export const DOHI_ICON = getAssetsUrl("/assets/local_logo/dogi.png");
export const QUARk_ICON = getAssetsUrl("/assets/local_logo/quark.png");
export const DOTS_ICON = getAssetsUrl("/assets/local_logo/dots.png");
export const INF_ICON = getAssetsUrl("/assets/local_logo/infinity.png");
export const Electron_ICON = getAssetsUrl("/assets/local_logo/electron.jpeg");
export const RUNES_ICON = getAssetsUrl("/assets/local_logo/runes-defi.jpeg");
export const DeAI_ICON = getAssetsUrl("/assets/local_logo/DeAI.png");

export const RUNES_ICO1N2 = getAssetsUrl("/assets/local_logo/runes_logo_2.png");
export const BRC20_ICON = getAssetsUrl("/assets/local_logo/brc20_logo.png");
export const ARC20_ICON = getAssetsUrl("/assets/local_logo/arc20_logo.png");
