import { baseRes } from "@/types";

export const createUuid = () =>
  Math.random().toString(36).substring(2) + Date.now().toString(36);

/**
 * 阻止冒泡
 * @param  使用示例：onClick={(event) => handleStopPropagation(onEdit, event)}
 * @return
 */
export const handleStopPropagation = (
  callback: () => void,
  event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
) => {
  event.stopPropagation();
  callback();
};
/**
 * 接口请求成功
 * 使用示例1：基本使用，成功之后执行onSuccess回调
 * handleApiResponse({res,onSuccess: (data) => {console.log("Success:", data);}});
 * 使用示例2: 带错误处理，成功之后执行onSuccess回调，失败之后执行onError回调
 * const data = handleApiResponse({res,onSuccess: (data) => {console.log("Success:", data);},onError: (error) => {console.error("Error:", error.message);}}));
 * @param
 * @return
 */
export const handleApiResponse = <T>(props: {
  res: baseRes<T>;
  onSuccess?: (data: T) => void;
  onError?: (error: Error) => void;
}): T => {
  const { res: response, onSuccess, onError } = props;
  if (response.code === 0) {
    const data = response.data;
    onSuccess?.(data);
    return data;
  } else {
    const error = new Error(response.msg || "Unexpected error occurred");
    onError?.(error);
    throw error;
  }
};

export function copyTextToClipboard(text: string) {
  // 检查浏览器是否支持 Clipboard API
  if (navigator.clipboard && window.isSecureContext) {
    // 使用 Clipboard API 的写方法将文本复制到剪贴板
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard successfully!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  } else {
    // 如果 Clipboard API 不支持，使用较旧的方法
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // 设置文本区域不可见
    textArea.style.position = "fixed"; // 避免页面滚动
    textArea.style.left = "-9999px";

    // 将文本区域添加到文档中
    document.body.appendChild(textArea);

    // 选中文本
    textArea.focus();
    textArea.select();

    try {
      // 执行复制命令
      const successful = document.execCommand("copy");
      const msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    // 移除文本区域
    document.body.removeChild(textArea);
  }
}
