import { useWindowSize } from "usehooks-ts";
import { mobileMaxPx } from "@/config/terminal";
import { useEffect, useState } from "react";

export const useTerminal = () => {
  const { width = 0 } = useWindowSize();
  const [init, setInit] = useState(false);
  useEffect(() => {
    setInit(true);
  }, []);
  return {
    isMobile: width <= mobileMaxPx,
    init,
  };
};
