import TickImage from "@/components/public/image/TickImage";
import { Button, Stack, Typography, Tooltip } from "@mui/material";
import PieEcharts from "@/components/public/echarts/PieEcharts";
import { sliceNumber, multipliedBy } from "@/utils/number";
import Empty from "@/components/public/empty";

import {
  useAssetDistData,
  AssetDistProps,
} from "@/components/pc/v1/home/AssetDist";

export default function AssetDist(props: AssetDistProps) {
  const { pieList, list } = useAssetDistData(props);
  return (
    <Stack height={"100%"}>
      <Stack direction={"row"} flex={"none"}>
        <Stack width={382}>
          <Typography variant="h11">Token Allocation</Typography>
          {pieList.length > 0 ? (
            <Stack flex={1} height={190} mt={1}>
              <PieEcharts
                center={["40%", "50%"]}
                radius={["0", "80%"]}
                renderTip={(params: any) => <></>}
                data={pieList}
                showLabel={false}
              />
            </Stack>
          ) : null}
        </Stack>
        <Stack flex={1}>
          <Typography variant="h11">Top 8</Typography>
          {pieList.length > 0 ? (
            <Stack spacing={"10px"} mt={1}>
              {list.map((item, key) => (
                <Stack direction={"row"} key={key} alignItems={"center"}>
                  <TickImage
                    url={item.icon_url}
                    size={10}
                    hasRadius
                    name={item.tick}
                    symbol={item.symbol}
                  />
                  <Tooltip title={item.tick || ""}>
                    <Typography
                      variant="h12"
                      width={92}
                      ml={1}
                      className="ellipsis"
                    >
                      {item.tick}
                    </Typography>
                  </Tooltip>
                  <Typography variant="h12" flex={1} ml={0.5} width={40}>
                    {sliceNumber(multipliedBy(item.percent, 100), 2)}%
                  </Typography>
                </Stack>
              ))}
            </Stack>
          ) : null}
        </Stack>
      </Stack>
      {pieList.length === 0 ? (
        <Stack height={"100%"}>
          <Empty width={90} height={73} msg={"No data"} />
        </Stack>
      ) : null}
    </Stack>
  );
}
