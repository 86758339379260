import { useBaseAlert } from "./BaseDialog";
import {
  Backdrop,
  Box,
  Stack,
  SxProps,
  Typography,
  Button,
} from "@mui/material";
import MyIcon from "@/components/public/my-icon";
import BaseButton from "@/components/public/button/BaseButton";

type Close = (value: boolean) => void;
interface AlertToastProps {
  width?: number;
  minHeight?: number;
  render?: (close: Close) => React.ReactNode;
  showClose?: boolean;
  msg: string;
  footerRender?: (close: Close) => React.ReactNode;
  boxSx?: SxProps;
  closeText?: string;
  confirmText?: string;
}
export default function AlertToast(props: AlertToastProps & { close: Close }) {
  const {
    width = 482,
    minHeight = 303,
    render = null,
    showClose = true,
    close,
    boxSx = {},
    msg = "",
    footerRender = null,
    closeText = "Cancel",
    confirmText = "Confirm",
  } = props;
  const handleClose = (value = false) => {
    close(value);
  };
  return (
    <Backdrop
      aria-hidden={false}
      open={true}
      onClick={() => {
        // 不要点击任意地方都关闭，否则用户会误点，看不清提示内容
        // if (onClick) {
        //   onClick();
        //   closeCb();
        // }
      }}
    >
      <Box
        sx={{
          width,
          borderRadius: "20px",
          position: "relative",
          minHeight,
        }}
        bgcolor="background.paper"
      >
        {showClose ? (
          <Stack
            onClick={() => handleClose(false)}
            position={"absolute"}
            right={"24px"}
            top={"24px"}
            zIndex={2}
            sx={{
              cursor: "pointer",
            }}
          >
            <MyIcon name="icon-close" color="white" size={16} />
          </Stack>
        ) : null}
        {render ? (
          render(handleClose)
        ) : (
          <>
            <Stack
              position={"absolute"}
              sx={{
                height: "100%",
                width: "100%",
                pt: "97px",
                px: "16px",
                pb: "22px",
                ...boxSx,
              }}
            >
              <Stack
                alignItems="center"
                justifyContent={"space-between"}
                flex={1}
              >
                <Stack alignItems={"center"}>
                  <Typography variant="subtitle2" mt={2} textAlign={"center"}>
                    {msg}
                  </Typography>
                </Stack>
                <Stack width={"100%"}>
                  {footerRender ? (
                    footerRender(handleClose)
                  ) : (
                    <Stack direction={"row"} spacing={"14px"}>
                      <BaseButton
                        variant={"glow"}
                        textVariant="h5"
                        sx={{
                          height: "56px",
                          borderRadius: "22px",
                          flex: 1,
                        }}
                        onClick={() => handleClose(true)}
                      >
                        {confirmText}
                      </BaseButton>
                      <BaseButton
                        textVariant="h5"
                        variant={"primary"}
                        sx={{
                          height: "56px",
                          borderRadius: "22px",
                          flex: 1,
                        }}
                        onClick={() => handleClose(false)}
                      >
                        {closeText}
                      </BaseButton>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </>
        )}
      </Box>
    </Backdrop>
  );
}

export const useAlertToast = () => {
  /**
   * @func openAlertToast
   * @description 打开弹层
   * @param {options} 弹层插件配置信息
   * @returns remove: 移除的弹窗的方法
   * @example
   * const res = await openAlertToast()
   */
  const { openBaseAlert } = useBaseAlert();
  const openAlertToast = (options: AlertToastProps) => {
    return openBaseAlert({
      render: (close: Close) => <AlertToast {...options} close={close} />,
    });
  };

  return {
    openAlertToast,
  };
};
