import { Button, Stack, Box, SxProps } from "@mui/material";
import { createTypography } from "@/theme/typography";
import { useAppDispatch, useAppSelector } from "@/redux";
import { useState } from "react";
import ConnectWallet from "@/components/public/dialog/ConnectWallet";
import { TypographyPropsVariantOverridesProps } from "@/theme/overrides/Typography";
interface Props {
  children: React.ReactNode;
  beforeConnectDom?: React.ReactNode;
  sx?: SxProps;
  width?: number | string;
  height?: number | string;
  textVariant?: TypographyPropsVariantOverridesProps;
}

export default function ConnectWalletButton(props: Props) {
  const {
    children,
    beforeConnectDom,
    width = 160,
    height = 44,
    textVariant = "subtitle2",
    sx = {},
  } = props;
  const t: any = createTypography();
  const { isConnect } = useAppSelector((state) => state.wallet);
  const [open, setOpen] = useState(false);
  return (
    <>
      {isConnect && children ? children : null}
      {!isConnect && beforeConnectDom ? (
        <Box component={"span"} onClick={() => setOpen(true)}>
          {beforeConnectDom}
        </Box>
      ) : null}
      {!isConnect && !beforeConnectDom ? (
        <Button
          variant={"glow"}
          sx={{
            width,
            height,
            flex: "none",
            borderRadius: "50px",
            ...t[textVariant as any],
            ...sx,
          }}
          onClick={() => setOpen(true)}
        >
          Connect Wallet
        </Button>
      ) : null}
      <ConnectWallet open={open} close={() => setOpen(false)} />
    </>
  );
}
