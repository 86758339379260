import { Stack, Typography, Button, Popover } from "@mui/material";
import { HeaderHeight, HeaderZIndex } from "@/config/layouts";
import { useEffect, useState } from "react";
import MyIcon from "@/components/public/my-icon";
import Input from "@/components/public/input";
import { createTypography } from "@/theme/typography";
import ConnectWalletButton from "@/components/public/button/ConnectWalletButton";
import { useAppSelector } from "@/redux/index";
import { formatId } from "@/utils/wallet";
import { useWalletContent } from "@/hooks/wallet";
import { useRouter } from "next/router";
import { dotswapUrl, toolsUrl } from "@/config/http";
import DefaultLink from "@/components/public/link/DefaultLink";
import HeaderPoints from "./HeaderPoints";
import Image from "@/components/public/image";

export const useHasScroll = () => {
  // 定义滚动事件的处理函数
  const [hasScroll, setHasScroll] = useState(false);
  const handleScroll = () => {
    // 获取滚动距离
    const scrollY = window.scrollY;
    setHasScroll(scrollY > 0);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return {
    hasScroll,
  };
};

export default function Header() {
  const router = useRouter();
  const { hasScroll } = useHasScroll();
  const [address, setAddress] = useState("");
  const t = createTypography();
  const { address: odAddress } = useAppSelector((state) => state.wallet);

  const [domRef, setDomRef] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const { disconnect } = useWalletContent();
  const goSearchPage = () => {
    router.push(`/home/${address}`);
    setAddress("");
  };
  const list = [
    { label: "Portfolio", active: true, url: "/", target: "_self" },
    { label: "Mint", url: toolsUrl },
    { label: "Accelerator", url: `${toolsUrl}/speed` },
    { label: "Market", url: `${dotswapUrl}/market` },
    { label: "DotSwap", url: dotswapUrl, vital: true },
  ];
  return (
    <Stack
      height={HeaderHeight}
      position={"sticky"}
      top={0}
      borderBottom={hasScroll ? 1 : "none"}
      borderColor={"background.default"}
      sx={{
        background: (theme) =>
          hasScroll ? theme.palette.background.default : "transparent",
        transition: "all 0.3s",
        bgcolor: "background.default",
        zIndex: HeaderZIndex,
      }}
      alignItems={"center"}
      direction={"row"}
      justifyContent={"space-between"}
      p={"0 24px 0 0"}
      spacing={"50px"}
    >
      <Stack spacing={"40px"} direction={"row"} flex={"none"}>
        <Stack
          height={92}
          pl={"27px"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {/* <MyIcon
            width={160}
            height={35}
            onClick={() => router.push("/")}
            name="icon-dotswap-tool"
            sx={{
              cursor: "pointer",
            }}
          /> */}
          <Stack
            height={35}
            width={160}
            onClick={() => router.push("/")}
            sx={{
              cursor: "pointer",
            }}
          >
            <Image src="/assets/header_logo.png" width={160} height={35} />
          </Stack>
        </Stack>
        <Stack direction={"row"} spacing={"40px"} alignItems={"center"}>
          {list.map((item, key) => (
            <DefaultLink
              href={item.url}
              key={key}
              target={(item.target as any) || "_blank"}
            >
              <Stack position={"relative"} alignItems={"center"}>
                <Typography
                  key={key}
                  variant="h5"
                  color="text.primary"
                  sx={{
                    lineHeight: "35px",
                    ...(item.vital
                      ? {
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          backgroundImage:
                            "linear-gradient( 90deg, #C668F8 0%, #01CDFF 100%)",
                        }
                      : {}),
                  }}
                >
                  {item.label}
                </Typography>
                {item.active ? (
                  <Stack
                    width={24}
                    height={2}
                    borderRadius={"2px"}
                    bgcolor={"secondary.main"}
                    position={"absolute"}
                    bottom={0}
                  ></Stack>
                ) : null}
              </Stack>
            </DefaultLink>
          ))}
        </Stack>
        {/* <Stack spacing={"8px"} alignItems={"center"}>
          <Typography variant="h5" color={"secondary.main"}>
            My Wallet
          </Typography>
          <Stack
            height={6}
            width={24}
            bgcolor={"secondary.main"}
            sx={{
              borderRadius: "4px",
            }}
          ></Stack>
        </Stack> */}
      </Stack>
      <Stack
        direction={"row"}
        spacing={"31px"}
        height={"100%"}
        right={0}
        alignItems={"center"}
        flex="auto"
        justifyContent={"flex-end"}
      >
        <HeaderPoints />
        <Stack flex={"auto"} maxWidth={377}>
          <Input
            placeholder="Search addresses"
            p="3px 3px 3px 24px"
            value={address}
            borderRadius={"22px"}
            boxHeight={44}
            fontSize={14}
            width={"100%"}
            change={(value) => setAddress(value)}
            onPressEnter={goSearchPage}
            endDom={
              <Button
                variant={"primary"}
                sx={{
                  height: "100%",
                  ...t.h6,
                  borderRadius: "22px",
                }}
                onClick={goSearchPage}
              >
                Search
              </Button>
            }
          />
        </Stack>
        <ConnectWalletButton>
          <Stack
            ref={(ref) => setDomRef(ref)}
            width={160}
            height={44}
            border={1}
            borderRadius={"20px"}
            alignItems={"center"}
            justifyContent={"center"}
            borderColor={"btn3Active.active"}
            sx={{
              flex: "none",
              cursor: "pointer",
            }}
            direction={"row"}
            spacing={1}
            onClick={() => {
              console.log("6666");
              setOpen(true);
            }}
          >
            <Typography variant={"subtitle3"} color="btn3Active.active">
              {formatId(odAddress)}
            </Typography>
            <MyIcon name="icon-down" size={14} color="btn3Active.active" />
          </Stack>
        </ConnectWalletButton>
      </Stack>

      <Popover
        open={open}
        anchorEl={domRef}
        onClose={handleClose}
        sx={{
          "& > .MuiPopover-paper": {
            bgcolor: "transparent",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: "right",
        }}
        anchorPosition={{
          top: 500,
          left: 0,
        }}
      >
        <Stack
          bgcolor={"background.textPaper"}
          boxSizing={"border-box"}
          p={2}
          borderRadius={"16px"}
          spacing={1}
        >
          <Stack
            height={44}
            width={156}
            px={2}
            py={1}
            borderRadius={"10px"}
            justifyContent={"center"}
            sx={{
              cursor: "pointer",
              "&:hover": {
                bgcolor: "rgba(255,255,255,0.1)",
                "& .sign-out": {
                  color: "primary.main",
                },
              },
            }}
            onClick={() => {
              console.log("sign out");
              setOpen(false);
              disconnect();
            }}
          >
            <Typography color="info.main" variant="h6" className="sign-out">
              Sign Out
            </Typography>
          </Stack>
        </Stack>
      </Popover>
    </Stack>
  );
}
