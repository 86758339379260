import { Modal, ModalProps, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import MyIcon from "../my-icon";
import { useTerminal } from "@/hooks/env";
import BaseModal, { BaseModalProps } from "./base-modal";

interface CommonModalProps extends BaseModalProps {
  headerTitle?: string | React.ReactNode;
  showBack?: boolean; // TODO: 预留返回按钮
  showClose?: boolean;
  modalHeader?: React.ReactNode; // 头部

  modalContent?: React.ReactNode; // 内容
  modalFooter?: React.ReactNode; // 底部

  onClose?: () => void;

  closeTop?: number;
}

const CommonModal: React.FC<CommonModalProps> = (props: CommonModalProps) => {
  const {
    headerTitle,
    showBack,
    showClose = true,
    modalHeader,
    onClose,
  } = props;
  return (
    <BaseModal open={props.open} onClose={props.onClose}>
      <>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            position: "relative",
            width: "100%",
            ...(headerTitle
              ? {
                  height: 72 - 16,
                }
              : {}),
          }}
        >
          {headerTitle ? (
            <Typography variant="h5">{headerTitle}</Typography>
          ) : null}
          {showClose ? (
            <Stack
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 0,
                cursor: "pointer",
                ...(headerTitle
                  ? {
                      top: "50%",
                      transform: "translateY(-50%)",
                    }
                  : {
                      top: "8px",
                    }),
              }}
            >
              <MyIcon name="icon-close" color="white" size={16} />
            </Stack>
          ) : null}
        </Stack>
        {props.children}
      </>
    </BaseModal>
  );
};

export default CommonModal;
