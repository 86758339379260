import { TabsItem } from "@/types/components";
import { Stack, SxProps, Typography } from "@mui/material";
import { TypographyPropsVariantOverridesProps } from "@/theme/overrides/Typography";

interface Props {
  list: TabsItem[];
  value?: TabsItem["value"];
  change?: (value: string, row: TabsItem) => void;
  width?: number | string;
  height?: number | string;
  borderRadius?: number;
  textVariant?: TypographyPropsVariantOverridesProps;
  mode?: "width" | "padding";
  px?: number | string;
  textSx?: SxProps;
}
export default function FaceTabs(props: Props) {
  const {
    list,
    value,
    change,
    borderRadius = 10,
    textVariant = "subtitle3",
    width = 241,
    height = 44,
    mode = "width",
    px = 2,
    textSx = {},
    ...other
  } = props;
  const tabsChange = (value: string, row: TabsItem) => {
    change?.(value, row);
  };
  const attr: any = {
    ...other,
    // width,
    height,
  };
  if (mode === "width") attr["width"] = width;
  const valueIndex = list.findIndex((item) => item.value === value);
  return (
    <Stack {...attr} direction={"row"}>
      {list.map((item, key) => (
        <Stack
          key={key}
          width={
            mode === "width" ? `calc(100% / ${list.length})` : "fit-content"
          }
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            position: "relative",
            borderTop: 1,
            borderBottom: 1,
            borderColor: valueIndex === key ? "secondary.main" : "divider",
            cursor: "pointer",
            transition: "all .3s",
            flex: "none",
            "&:not(:last-child)::after": {
              content: "''",
              display: "block",
              position: "absolute",
              right: 0,
              top: 0,
              width: "1px",
              bgcolor:
                valueIndex === key || valueIndex - 1 === key
                  ? "secondary.main"
                  : "divider",
              height: "100%",
            },
            "&:last-child": {
              borderRight: 1,
              borderColor:
                valueIndex === list.length - 1 ? "secondary.main" : "divider",
              borderRadius: `0 ${borderRadius}px ${borderRadius}px 0`,
            },
            "&:first-of-type": {
              borderLeft: 1,
              borderColor: valueIndex === 0 ? "secondary.main" : "divider",
              borderRadius: `${borderRadius}px 0 0 ${borderRadius}px`,
            },
          }}
          onClick={() => tabsChange(item.value, item)}
        >
          <Typography
            px={mode === "padding" ? px : 0}
            variant={textVariant as any}
            color={valueIndex === key ? "secondary.main" : "text.secondary"}
            sx={textSx}
          >
            {item.label}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
}
