import { useCallback, useMemo } from "react";
// @mui
import { CssBaseline } from "@mui/material";
import {
  ThemeProvider as MUIThemeProvider,
  ThemeOptions,
  createTheme,
} from "@mui/material/styles";
import { handleBreakpoints } from "@mui/system";
import GlobalStyles from "./GlobalStyles";
import componentsOverride from "./overrides"; //引用主题组件
import palette from "./palette";
import typography, { createTypography } from "./typography";
import { useTerminal } from "@/hooks/env";
import { pxToVw } from "@/utils/style";

type Props = {
  children: React.ReactNode;
  hasH5?: boolean;
};

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    lg2: true;
    xl: true;
  }
}

export default function ThemeProvider(props: Props) {
  const { children, hasH5 = false } = props;
  const { isMobile, init } = useTerminal();
  const isChange = isMobile && init && hasH5; //isMobile;

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: palette as any,
      typography: createTypography(isChange) as any,
      shape: { borderRadius: 8 },
    }),
    [isChange],
  );

  const spacingChange = useCallback(
    (value: number | string) => {
      if (typeof value === "number") {
        const number = value * 8;
        return isChange ? pxToVw(number) : number;
      }
      return value;
    },
    [isChange],
  );

  const getPxToVw = useCallback(
    (size: string | number) => {
      const reg = /([0-9]*)px/;
      const isTransform =
        typeof size === "string" && reg.test(size) && isChange;
      const isNumber = typeof size === "number" && isChange;
      const number: any = isTransform ? reg.exec(size) : 0;
      let handleSize = isTransform ? pxToVw(Number(number[1])) : size;
      handleSize = isNumber ? pxToVw(size) : handleSize;
      return handleSize;
    },
    [isChange],
  );
  const changeKeys = [
    "width",
    "height",
    "fontSize",
    "minWidth",
    "minHeight",
    "min-width",
    "min-height",
    "right",
    "top",
    "left",
    "bottom",
  ]; // 获取如需要转换属性请增加
  const createSxConfig = (list: string[]) => {
    const obj: any = {};
    list.forEach((item) => {
      obj[item] = {
        style: (props: any) => {
          const change = (size: string | number) => {
            const handleSize = getPxToVw(size);
            return { [item]: handleSize };
          };
          return handleBreakpoints(props, props[item], change);
        },
      };
    });
    return obj;
  };

  const themeObj = {
    ...themeOptions,
  };
  if (isChange) {
    themeObj["spacing"] = spacingChange;
    themeObj["unstable_sxConfig"] = {
      ...createSxConfig(isChange ? changeKeys : []),
    };
  }
  const theme = createTheme(themeObj);

  theme.components = componentsOverride(theme) as any;

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      {children}
    </MUIThemeProvider>
  );
}
