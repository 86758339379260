import { SigInfo } from "@/types/wallet_manager";
import {
  useConfirmDialog,
  useGetAuthentication,
  useGetMultipleAddressAuthentication,
} from "@/components/public/login/LoginDialog";
import {
  walletAccountBindAddress,
  walletAccountGetAddress,
  walletAccountUnionAddress,
  walletAccountGetAccount,
  walletAccountAuth,
  walletAccountMoveAccount,
  walletAccountCreateAccount,
  walletAccountBindCheckAccount,
  walletAccountCreateAccountBatch,
  walletAccountBindAddressBatch,
  walletAccountBindCheckAccountBatch,
} from "@/services/wallet_manager";
import {
  setToken,
  setAddress as setLocalStorageAddress,
  getAddress as getLocalStorageAddress,
  getToken as getTokenCase,
} from "@/utils/auth";
import { formatId } from "@/utils/wallet";
import dayjs from "dayjs";
import { isRejectSign, signMessage } from "@/utils/wallet";

interface BindWalletRes {
  token: string;
  sig_info: SigInfo;
}
type PromiseBindWalletRes = Promise<BindWalletRes>;
interface GetSignInfo {
  address: string;
  publicKey: string;
}
type GetTokenProps = GetSignInfo & { sig_info?: SigInfo };

export const getLoginSignMsg = (address: string) => {
  return `Welcome to Mempool Portfolio!

  Click to sign in and accept the Mempool Portfolio Terms of Service and Privacy Policy.

  This request is only for login authorization and will not trigger any transactions. Your funds are safe.

  Wallet address: ${address}

  Time: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`;
};
// 获取签名信息
export const getSignInfo = async ({
  address,
  publicKey,
}: GetSignInfo): Promise<SigInfo> => {
  const sign_info = getLoginSignMsg(address);
  const signRes = await signMessage(sign_info, address);
  const sig_info = {
    sign_info,
    address,
    public_key: publicKey,
    signature: signRes,
  };
  return sig_info;
};

export const useSingleAddressBindWallet = () => {
  const { openGetAuthentication } = useGetAuthentication();
  const { openConfirmDialog } = useConfirmDialog();
  // 绑定到锁定的账户
  const bindCheckAccount = async (
    options: GetTokenProps,
  ): Promise<void | BindWalletRes> => {
    const { address } = options;
    const token: any = await openGetAuthentication({
      address: address,
    });
    if (token) {
      const sig_info = await getSignInfo(options);
      const bindRes = await walletAccountBindCheckAccount({
        sig_info,
        bind_token: token,
      });
      return {
        token: bindRes.data.token,
        sig_info,
      };
    }
  };
  // 情况1 => 直接绑定
  const directBinding = async (
    options: GetTokenProps,
  ): Promise<void | BindWalletRes> => {
    const sig_info = await getSignInfo(options);
    const res = await walletAccountBindAddress({
      sig_info,
    });
    if (res.code === 10170002) {
      const handleRes = await checkOrRebinding(options);
      if (handleRes) return handleRes;
    }
    if (res.code === 10170003) {
      const handleRes = await checkOrCreateAccount(options);
      if (handleRes) return handleRes;
    }
    return {
      token: res.data.token,
      sig_info,
    };
  };
  // 情况2 => 绑定或创建新组
  const bindOrCreateAccount = async (
    options: GetTokenProps & { oldAddress: string },
  ): Promise<void | BindWalletRes> => {
    const { address, publicKey, oldAddress } = options;
    const closeBtnValue = "close";
    const confirmBtnValue = "confirm";
    const openRes = await openConfirmDialog({
      msg: `Detected your last connection was to the ${formatId(oldAddress)} address group. Do you want to link the current address to this group?`,
      closeBtnValue,
      confirmBtnValue,
    });
    if (openRes === closeBtnValue) {
      const handleRes = await directBinding({ address, publicKey });
      return handleRes;
    } else if (openRes === confirmBtnValue) {
      const sig_info = await getSignInfo({
        address,
        publicKey,
      });
      const createRes = await walletAccountCreateAccount({ sig_info });
      return {
        sig_info,
        token: createRes.data.token,
      };
    }
  };
  // 情况3
  const directLogin = async (
    options: GetTokenProps,
  ): Promise<void | BindWalletRes> => {
    const sig_info = await getSignInfo(options);
    const res = await walletAccountAuth({
      sig_info,
    });
    return {
      sig_info,
      token: res.data.token,
    };
  };
  // 情况4 ==> 直接使用1
  // 情况5 => 绑定到指定地址与改绑地址组
  const checkOrRebinding = async (
    options: GetTokenProps,
  ): Promise<void | BindWalletRes> => {
    const { address } = options;
    const rebinding = "rebinding";
    const bindCheck = "bindCheck";
    const res = await openConfirmDialog({
      msg: `This address is already linked. Do you want to re-link it to your new address group ${formatId(address)}?`,
      confirmText: "Link to a specified address group",
      confirmBtnValue: bindCheck,
      closeText: "Confirm re-link",
      closeBtnValue: rebinding,
    });
    if (res === bindCheck) {
      // 绑定到地址地址组 => 绑定指定地址组
      return await bindCheckAccount(options);
    } else if (res === rebinding) {
      // 改版到对应token的地址组 => 确定改绑
      const sig_info = options.sig_info
        ? options.sig_info
        : await getSignInfo(options);
      await walletAccountMoveAccount({ sig_info });
      const tokenCase = getTokenCase();
      return {
        token: tokenCase,
        sig_info,
      };
    }
  };
  // 情况6 => 绑定到指定地址与新建地址组
  const checkOrCreateAccount = async (
    options: GetTokenProps,
  ): Promise<void | BindWalletRes> => {
    const createAccount = "createAccount";
    const bindCheck = "bindCheck";
    const res = await openConfirmDialog({
      msg: "Detected that this address is already linked. Do you want to unlink it and create a new address group?",
      closeText: "Create new group",
      closeBtnValue: createAccount,
      confirmText: "Link to a specified address group",
      confirmBtnValue: bindCheck,
    });
    if (res === bindCheck) {
      // 绑定指定地址组
      return await bindCheckAccount(options);
    } else if (res === createAccount) {
      // 创建新地址组
      const sig_info = options.sig_info
        ? options.sig_info
        : await getSignInfo(options);
      const res = await walletAccountCreateAccount({ sig_info });
      return {
        token: res.data.token,
        sig_info,
      };
    }
  };

  return {
    getSignInfo,
    checkOrCreateAccount,
    checkOrRebinding,
    directBinding,
    bindOrCreateAccount,
    directLogin,
  };
};

interface MultipleAddressBindWalletProp {
  btcAddress: string;
  btcPublicKey: string;
  address: string;
  publicKey: string;
}

export function useMultipleAddressBindWallet() {
  const { openGetAuthentication } = useGetMultipleAddressAuthentication();
  const { openConfirmDialog } = useConfirmDialog();
  const getMultipleSignInfo = async (
    options: MultipleAddressBindWalletProp,
  ) => {
    const { btcAddress, btcPublicKey, address, publicKey } = options;
    const btcSigInfo = await getSignInfo({
      address: btcAddress,
      publicKey: btcPublicKey,
    });
    const sigInfo = await getSignInfo({
      address,
      publicKey,
    });
    return {
      btc_sig_info: btcSigInfo,
      ord_sig_info: sigInfo,
    };
  };
  // 情况1 => ord + btc均无绑定与签名 => 直接绑定
  // 情况4 => ord + btc有一签名 => 绑定至已签名的地址组
  // 情况5 => ord + btc均签名但不同组 => 绑定至ord地址组
  const multipleDirectBinding = async (
    options: MultipleAddressBindWalletProp,
  ) => {
    const sigInfo = await getMultipleSignInfo(options);
    const res = await walletAccountBindAddressBatch({
      ...sigInfo,
    });
    return {
      token: res.data.token,
      sigInfo,
    };
  };

  // 情况2 有token信息 + 双地址未绑定 => 提示绑定或创建
  const multipleBindOrCreateAccount = async (
    options: MultipleAddressBindWalletProp & { oldAddress: string },
  ) => {
    const { oldAddress, ...other } = options;
    const closeBtnValue = "close";
    const confirmBtnValue = "confirm";
    const openRes = await openConfirmDialog({
      msg: `Detected your last connection was to the ${formatId(oldAddress)} address group. Do you want to link the current address to this group?`,
      closeBtnValue,
      confirmBtnValue,
    });
    if (openRes === closeBtnValue) {
      return await multipleDirectBinding(other);
    } else if (openRes === confirmBtnValue) {
      const sigInfo = await getMultipleSignInfo(other);
      const res = await walletAccountCreateAccountBatch({
        ...sigInfo,
      });
      return {
        token: res.data.token,
        sigInfo,
      };
    }
  };

  // 情况3 btc + ord地址均签名且在同一个组
  const multipleDirectLogin = async (
    options: MultipleAddressBindWalletProp,
  ) => {
    const sig_info = await getSignInfo(options);
    const res = await walletAccountAuth({
      sig_info,
    });
    return {
      sig_info: {
        ord_sig_info: sig_info,
      },
      token: res.data.token,
    };
  };
  // 情况6 => ord + btc地址均绑定但未签名 => 创建新地址或绑定原地址组
  const multipleCreateOrMvOldAddress = async (
    options: MultipleAddressBindWalletProp,
  ) => {
    const { btcAddress, address } = options;
    const createAccount = "createAccount";
    const bindCheck = "bindCheck";
    const res = await openConfirmDialog({
      msg: "Detected that this address is already linked. Do you want to unlink it and create a new address group?",
      closeText: "Create new group",
      closeBtnValue: createAccount,
      confirmText: "Link to a specified address group",
      confirmBtnValue: bindCheck,
    });

    if (res === bindCheck) {
      // 绑定指定地址组
      const token: any = await openGetAuthentication({
        btc_bind_address: btcAddress,
        ord_bind_address: address,
      });
      if (token) {
        const sig_info = await getMultipleSignInfo(options);
        const bindRes = await walletAccountBindCheckAccountBatch({
          ...sig_info,
          bind_token: token,
        });
        return {
          token: bindRes.data.token,
          sig_info,
        };
      }
    } else if (res === createAccount) {
      // 创建新地址组
      const sig_info = await getMultipleSignInfo(options);
      const res = await walletAccountCreateAccountBatch({
        ...sig_info,
      });
      return {
        token: res.data.token,
        sig_info,
      };
    }
  };

  return {
    getMultipleSignInfo,
    multipleDirectBinding,
    multipleBindOrCreateAccount,
    multipleDirectLogin,
    multipleCreateOrMvOldAddress,
  };
}
