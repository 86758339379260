import { createPortal } from "react-dom";
import { Stack, SxProps } from "@mui/material";

interface Props {
  children: React.ReactNode;
  open?: boolean;
  width?: string | number;
  sx?: SxProps;
}

export default function BaseDialogBox(props: Props) {
  const { children, open, width, sx = {} } = props;
  if (!open) return;

  return createPortal(
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      position={"fixed"}
      top={0}
      left={0}
      width={"100vw"}
      height={"100vh"}
      sx={{
        ...sx,
      }}
    >
      <Stack
        width={"100%"}
        height={"100%"}
        sx={{
          position: "absolute",
          zIndex: 1,
          background: "rgba(0, 0, 0, 0.5)",
          transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1)",
        }}
      ></Stack>
      <Stack
        justifyContent={"center"}
        width={width}
        sx={{
          zIndex: 2,
        }}
      >
        {children}
      </Stack>
    </Stack>,
    document.body, // 渲染到 body 中
  );
}
