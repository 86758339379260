import { useAppSelector } from "@/redux";
import { ListItem, TabsItem } from "@/types/components";
import { useEffect, useState } from "react";
import { formatId } from "@/utils/wallet";

export const useAddressGroupInfo = () => {
  const { address_list = [] } = useAppSelector((state) => state.account);
  const addressList: ListItem[] = [
    { label: "All addresses", value: "" },
    ...address_list.map((item) => ({
      label: formatId(item.address) || "",
      value: item.address,
      remark: item.remark,
    })),
  ];
  return {
    addressList,
  };
};

export const useUpdateCb = ({ updateCb }: { updateCb?: () => any | void }) => {
  const { address_list = [] } = useAppSelector((state) => state.account);
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (address_list.length == 0) return;
    if (init) updateCb?.();
    setInit(true);
  }, [address_list]);
};
