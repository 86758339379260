import { Box, Collapse } from "@mui/material";
import {
  SnackbarProvider as NotistackProvider,
  ProviderContext,
  SnackbarKey,
  SnackbarMessage,
  VariantType,
  useSnackbar,
} from "notistack";
import { useRef } from "react";
import StyledNotistack from "./styles";
import MyIcon from "../my-icon";
import Image from "../image";
import { Stack } from "@mui/system";

type Props = {
  children: React.ReactNode;
  showClose?: boolean;
};
type SnackbarIconProps = {
  icon: string;
};
interface SnackbarProps extends React.ComponentProps<typeof NotistackProvider> {
  msg?: string;
  variant?: VariantType;
  onClose?: () => void;
  showClose?: boolean; // 是否显示关闭按钮
}

let useSnackbarRef: ProviderContext;
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

/**
 * toast组件，支持直接传入SnackbarProvider所有配置
 * 使用示例：SnackbarUtils.xxx("message",SnackbarProviderOption)
    SnackbarUtils.success("成功");
    SnackbarUtils.warning("警告");
    SnackbarUtils.info("信息");
    SnackbarUtils.error("失败");
    SnackbarUtils.toast("提示");
 * @param
 * @return
 */
export const SnackbarUtils = {
  success(message: SnackbarMessage, props?: SnackbarProps) {
    const { msg, onClose, ...other } = props || {};
    this.toast(message, { msg, variant: "success", onClose, ...other });
  },
  warning(message: SnackbarMessage, props?: SnackbarProps) {
    const { msg, onClose, ...other } = props || {};
    this.toast(message, { msg, variant: "warning", onClose, ...other });
  },
  info(message: SnackbarMessage, props?: SnackbarProps) {
    const { msg, onClose, ...other } = props || {};
    this.toast(message, { msg, variant: "info", onClose, ...other });
  },
  error(message: SnackbarMessage, props?: SnackbarProps) {
    const { msg, onClose, ...other } = props || {};
    this.toast(message, { msg, variant: "error", onClose, ...other });
  },
  toast(message: SnackbarMessage, obj?: SnackbarProps) {
    const {
      msg,
      variant = "default",
      onClose, // 关闭前的回调
      showClose = false, // 是否显示关闭按钮
      ...props
    } = obj || {};
    // 这个的优先级高于NotistackProvider，如果没设置会默认使用NotistackProvider设置的值
    useSnackbarRef.enqueueSnackbar(message || msg, {
      ...props,
      variant,
      onClose,
      action: (key) => {
        return (
          <>
            {showClose ? (
              <Stack
                sx={{ cursor: "pointer" }}
                mr={2}
                onClick={() => useSnackbarRef.closeSnackbar(key)}
              >
                <MyIcon name="icon-close" color="#949EA9" size={14} />
              </Stack>
            ) : null}
          </>
        );
      },
    });
  },
};

/**
 * 重写SnackbarProvider，统一处理样式及默认配置
 * @param
 * @return
 */
export default function SnackbarProvider({
  children,
  showClose = false,
}: Props) {
  const isRTL = false;
  const notistackRef = useRef<any>(null);
  const onClose = (key: SnackbarKey) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <>
      <StyledNotistack />
      <NotistackProvider
        ref={notistackRef}
        dense
        maxSnack={2}
        preventDuplicate
        autoHideDuration={2000}
        TransitionComponent={isRTL ? Collapse : undefined}
        variant="success" // Set default variant
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        iconVariant={{
          info: <SnackbarIcon icon="/assets/toast-info.png" />,
          success: <SnackbarIcon icon="/assets/toast-success.png" />,
          warning: <SnackbarIcon icon="/assets/toast-warning.png" />,
          error: <SnackbarIcon icon="/assets/toast-error.png" />,
        }}
        action={(key) =>
          showClose ? (
            <Stack sx={{ cursor: "pointer" }} mr={2} onClick={onClose(key)}>
              <MyIcon name="icon-close" color="#949EA9" size={14} />
            </Stack>
          ) : (
            <></>
          )
        }
      >
        {children}
      </NotistackProvider>
    </>
  );
}

function SnackbarIcon({ icon }: SnackbarIconProps) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 28,
        height: 28,
        display: "flex",
        borderRadius: 1.5,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Image alt={icon} src={icon} width={24} />
    </Box>
  );
}
