import { Box, Stack } from "@mui/material";
import MyIcon from "@/components/public/my-icon";
import DefaultLink from "@/components/public/link/DefaultLink";
import { customerService } from "@/config/http";

export default function CustomerService() {
  return (
    <Box position={"fixed"} bottom={40} right={40}>
      <DefaultLink href={customerService} target={"_blank"}>
        <Stack
          width={48}
          height={48}
          borderRadius={"50%"}
          border={1}
          bgcolor={"#191B1F"}
          alignItems={"center"}
          justifyContent={"center"}
          borderColor={"rgba(42, 133, 255, 0.20)"}
        >
          <MyIcon name="icon-kefu" size={24} color="#2A85FF" />
        </Stack>
      </DefaultLink>
    </Box>
  );
}
