import { api } from "@/utils/request";
import {
  WalletAccountAuth,
  WalletAccountCreateAccount,
  WalletAccountBindAddress,
  WalletAccountManualAddAddress,
  WalletAccountUpdateAddressRemark,
  WalletAccountGetAddress,
  WalletAccountGetAccount,
  WalletAccountRemoveAddress,
  WalletAccountMoveAccount,
  WalletAccountBindCheckAccount,
  WalletAccountUnionAddress,
  WalletAccountAddressPortfolioDetail,
  WalletAccountAddressPortfolioPie,
  WalletAccountAddressPortfolioTrend,
  WalletAccountAddressPortfolioTxRecords,
  WalletWalletAccountDetail,
  WalletAccountCheckAccount,
  WalletWalletAccountPortfolioTrend,
  WalletWalletAccountPortfolioTxRecords,
  WalletWalletAccountPortfolioPie,
  WalletAccountCreateAccountBatch,
  WalletAccountBindAddressBatch,
  WalletAccountGetAddressBatch,
  WalletAccountCheckAccountBatch,
  WalletAccountBindCheckAccountBatch,
  HomePortfolioDetail,
  HomePortfolioPie,
  HomePortfolioTrend
} from "@/types/wallet_manager";

/**
 * 授权验证接口
 */
export const walletAccountAuth = (data: WalletAccountAuth["req"]) =>
  api<WalletAccountAuth["req"], WalletAccountAuth["res"]>({
    url: "/wallet_manager/wallet_account/auth",
    method: "post",
    data,
    cleanVerifyToken: true,
  });

/**
 * 创建账户组
 */
export const walletAccountCreateAccount = (
  data: WalletAccountCreateAccount["req"],
) =>
  api<WalletAccountCreateAccount["req"], WalletAccountCreateAccount["res"]>({
    url: "/wallet_manager/wallet_account/create_account",
    method: "post",
    data,
    cleanVerifyToken: true,
  });

/**
 * 签名绑定地址
 */
export const walletAccountBindAddress = (
  data: WalletAccountBindAddress["req"],
) =>
  api<WalletAccountBindAddress["req"], WalletAccountBindAddress["res"]>({
    url: "/wallet_manager/wallet_account/bind_address",
    method: "post",
    data,
    filterCodes: [10170002, 10170003],
    cleanVerifyToken: true,
  });

/**
 * 手动添加地址
 */
export const walletAccountManualAddAddress = (
  data: WalletAccountManualAddAddress["req"],
) =>
  api<
    WalletAccountManualAddAddress["req"],
    WalletAccountManualAddAddress["res"]
  >({
    url: "/wallet_manager/wallet_account/manual_add_address",
    method: "post",
    data,
    filterCodes: [10170005, 10170007],
  });

/**
 * 修改地址备注
 */
export const walletAccountUpdateAddressRemark = (
  data: WalletAccountUpdateAddressRemark["req"],
) =>
  api<
    WalletAccountUpdateAddressRemark["req"],
    WalletAccountUpdateAddressRemark["res"]
  >({
    url: "/wallet_manager/wallet_account/update_address_remark",
    method: "post",
    data,
  });

/**
 * 获取地址的绑定信息
 */
export const walletAccountGetAddress = (data: WalletAccountGetAddress["req"]) =>
  api<WalletAccountGetAddress["req"], WalletAccountGetAddress["res"]>({
    url: "/wallet_manager/wallet_account/get_address",
    method: "post",
    data,
    cleanVerifyToken: true,
  });

/**
 * 获取当前token的账户组信息
 */
export const walletAccountGetAccount = () =>
  api<WalletAccountGetAccount["res"], WalletAccountGetAccount["res"]>({
    url: "/wallet_manager/wallet_account/get_account",
    method: "get",
    // cleanVerifyToken: true,
  });

/**
 * 解绑地址
 */
export const walletAccountRemoveAddress = (
  data: WalletAccountRemoveAddress["req"],
) =>
  api<WalletAccountRemoveAddress["req"], WalletAccountRemoveAddress["res"]>({
    url: "/wallet_manager/wallet_account/remove_address",
    method: "post",
    data,
  });

/**
 * 记录地址关联关系(xverse,me
 */
export const walletAccountMoveAccount = (
  data: WalletAccountMoveAccount["req"],
) =>
  api<WalletAccountMoveAccount["req"], WalletAccountMoveAccount["res"]>({
    url: "/wallet_manager/wallet_account/move_account",
    method: "post",
    data,
  });

/**
 * 记录地址关联关系(xverse,me
 */
export const walletAccountUnionAddress = (
  data: WalletAccountUnionAddress["req"],
) =>
  api<WalletAccountUnionAddress["req"], WalletAccountUnionAddress["res"]>({
    url: "/wallet_manager/wallet_account/union_address",
    method: "post",
    data,
  });

/**
 * 检测地址是否与签名地址同组
 */
export const walletAccountCheckAccount = (
  data: WalletAccountCheckAccount["req"],
) =>
  api<WalletAccountCheckAccount["req"], WalletAccountCheckAccount["res"]>({
    url: "/wallet_manager/wallet_account/check_account",
    method: "post",
    data,
    cleanVerifyToken: true,
  });

/**
 * 绑定地址到已签名地址的账户组
 */
export const walletAccountBindCheckAccount = (
  data: WalletAccountBindCheckAccount["req"],
) =>
  api<
    WalletAccountBindCheckAccount["req"],
    WalletAccountBindCheckAccount["res"]
  >({
    url: "/wallet_manager/wallet_account/bind_check_account",
    method: "post",
    data,
    cleanVerifyToken: true,
  });

/**
 * 获取他人地址资产明细
 */
export const walletAccountAddressPortfolioDetail = (
  data: WalletAccountAddressPortfolioDetail["req"],
) =>
  api<
    WalletAccountAddressPortfolioDetail["req"],
    WalletAccountAddressPortfolioDetail["res"]
  >({
    url: "/wallet_manager/address/portfolio/detail",
    method: "post",
    data,
    filterCodes: [10170006],
    cleanVerifyToken: true,
  });

/**
 * 获取他人地址资产占比
 */
export const walletAccountAddressPortfolioPie = (
  data: WalletAccountAddressPortfolioPie["req"],
) =>
  api<
    WalletAccountAddressPortfolioPie["req"],
    WalletAccountAddressPortfolioPie["res"]
  >({
    url: "/wallet_manager/address/portfolio/pie",
    method: "post",
    data,
    cleanVerifyToken: true,
    filterCodes: [10170006],
  });

/**
 * 获取他人地址资产走势
 */
export const walletAccountAddressPortfolioTrend = (
  data: WalletAccountAddressPortfolioTrend["req"],
) =>
  api<
    WalletAccountAddressPortfolioTrend["req"],
    WalletAccountAddressPortfolioTrend["res"]
  >({
    url: "/wallet_manager/address/portfolio/trend",
    method: "post",
    data,
    cleanVerifyToken: true,
  });

/**
 * 获取他人地址交易记录
 */
export const walletAccountAddressPortfolioTxRecords = (
  data: WalletAccountAddressPortfolioTxRecords["req"],
) =>
  api<
    WalletAccountAddressPortfolioTxRecords["req"],
    WalletAccountAddressPortfolioTxRecords["res"]
  >({
    url: "/wallet_manager/address/portfolio/tx_records",
    method: "post",
    data,
    cleanVerifyToken: true,
  });

/**
 * 查看账户组的资产明细
 */
export const walletWalletAccountDetail = (
  data: WalletWalletAccountDetail["req"],
) =>
  api<WalletWalletAccountDetail["req"], WalletWalletAccountDetail["res"]>({
    url: "/wallet_manager/wallet_account/portfolio/detail",
    method: "post",
    data,
    cleanVerifyToken: true,
  });

/**
 * 查看账户组的资产占比
 */
export const walletWalletAccountPortfolioPie = (
  data: WalletWalletAccountPortfolioPie["req"],
) =>
  api<
    WalletWalletAccountPortfolioPie["req"],
    WalletWalletAccountPortfolioPie["res"]
  >({
    url: "/wallet_manager/wallet_account/portfolio/pie",
    method: "post",
    data,
  });

/**
 * 查看账户组的资产走势
 */
export const walletWalletAccountPortfolioTrend = (
  data: WalletWalletAccountPortfolioTrend["req"],
) =>
  api<
    WalletWalletAccountPortfolioTrend["req"],
    WalletWalletAccountPortfolioTrend["res"]
  >({
    url: "/wallet_manager/wallet_account/portfolio/trend",
    method: "post",
    data,
  });

/**
 * 查看账户组的交易记录
 */
export const walletWalletAccountPortfolioTxRecords = (
  data: WalletWalletAccountPortfolioTxRecords["req"],
) =>
  api<
    WalletWalletAccountPortfolioTxRecords["req"],
    WalletWalletAccountPortfolioTxRecords["res"]
  >({
    url: "/wallet_manager/wallet_account/portfolio/tx_records",
    method: "post",
    data,
  });

/**
 * 创建新账户组（批量
 */
export const walletAccountCreateAccountBatch = (
  data: WalletAccountCreateAccountBatch["req"],
) =>
  api<
    WalletAccountCreateAccountBatch["req"],
    WalletAccountCreateAccountBatch["res"]
  >({
    url: "/wallet_manager/wallet_account/create_account/batch",
    method: "post",
    data,
    cleanVerifyToken: true,
  });

/**
 * 签名绑定地址（批量
 */
export const walletAccountBindAddressBatch = (
  data: WalletAccountBindAddressBatch["req"],
) =>
  api<
    WalletAccountBindAddressBatch["req"],
    WalletAccountBindAddressBatch["res"]
  >({
    url: "/wallet_manager/wallet_account/bind_address/batch",
    method: "post",
    data,
    cleanVerifyToken: true,
  });

/**
 * 获取地址的绑定信息（批量
 */
export const walletAccountGetAddressBatch = (
  data: WalletAccountGetAddressBatch["req"],
) =>
  api<WalletAccountGetAddressBatch["req"], WalletAccountGetAddressBatch["res"]>(
    {
      url: "/wallet_manager/wallet_account/get_address/batch",
      method: "post",
      data,
      cleanVerifyToken: true,
    },
  );

/**
 * 检测地址是否与签名地址同组（批量
 */
export const walletAccountCheckAccountBatch = (
  data: WalletAccountCheckAccountBatch["req"],
) =>
  api<
    WalletAccountCheckAccountBatch["req"],
    WalletAccountCheckAccountBatch["res"]
  >({
    url: "/wallet_manager/wallet_account/check_account/batch",
    method: "post",
    data,
    cleanVerifyToken: true,
  });

/**
 * 绑定地址到已签名地址的账户组（批量
 */
export const walletAccountBindCheckAccountBatch = (
  data: WalletAccountBindCheckAccountBatch["req"],
) =>
  api<
    WalletAccountBindCheckAccountBatch["req"],
    WalletAccountBindCheckAccountBatch["res"]
  >({
    url: "/wallet_manager/wallet_account/bind_check_account/batch",
    method: "post",
    data,
    cleanVerifyToken: true,
  });

/**
 * 查看首页展示的资产明细
 */
export const homePortfolioDetail = (data: HomePortfolioDetail["req"]) =>
  api<HomePortfolioDetail["req"], HomePortfolioDetail["res"]>({
    url: "/wallet_manager/home/portfolio/detail",
    method: "post",
    data,
    cleanVerifyToken: true,
  });

/**
 * 查看首页展示的资产占比
 */
export const homePortfolioPie = (data: HomePortfolioPie["req"]) =>
  api<HomePortfolioPie["req"], HomePortfolioPie["res"]>({
    url: "/wallet_manager/home/portfolio/pie",
    method: "post",
    data,
    cleanVerifyToken: true,
  });

/**
 * 查看首页展示的资产占比
 */
export const homePortfolioTrend = (data: HomePortfolioTrend["req"]) =>
  api<HomePortfolioTrend["req"], HomePortfolioTrend["res"]>({
    url: "/wallet_manager/home/portfolio/trend",
    method: "post",
    data,
    cleanVerifyToken: true,
  });
