import { runesOrderMarketinfoStatus } from "@/services/runes";
import { getUnixDateFormatStr } from "@/utils/formatTime";
import { useEffect, useState } from "react";
import useSWR from "swr";

export const useUpdateTime = () => {
  const getUpdateTime = async () => {
    const res = await runesOrderMarketinfoStatus();
    const { updated_time } = res.data;
    return getUnixDateFormatStr(updated_time);
  };

  // 实时获取预计到账数量
  const { data: updateTime, error } = useSWR(
    "/polymerizer/runes/marketinfo/status",
    getUpdateTime,
    {
      refreshInterval: 150000,
    },
  );
  // useEffect(() => {
  //   getUpdateTime();
  // }, []);
  return {
    updateTime,
  };
};

export const useUpdateAddressData = (props: {
  cb?: () => void;
  compProps: { address: string };
  isUseCbFn?: () => boolean;
}) => {
  const { cb, compProps, isUseCbFn = () => true } = props;
  const { address } = compProps;
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (init && isUseCbFn?.()) cb?.();
    setInit(true);
  }, [address]);
};
