import { Modal, ModalProps, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import MyIcon from "../my-icon";
import { useTerminal } from "@/hooks/env";

export interface BaseModalProps extends ModalProps {
  onClose?: () => void; // 关闭弹窗
}
/**
 * @description: 基础空弹窗
 * @param
 * @return
 */
const BaseModal: React.FC<BaseModalProps> = (props: BaseModalProps) => {
  const { isMobile } = useTerminal();
  const boxStyle = isMobile
    ? {
        left: 0,
        right: 0,
        bottom: 0,
      }
    : {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      };
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      disableAutoFocus
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        zIndex: 50, // 为了让 wallet-connect 的弹窗拥有更高的优先级
        // '& .MuiModal-backdrop': props.isBlurBack
        //   ? {
        //       backdropFilter: 'blur(10px)',
        //       backgroundColor: 'rgba(0, 0, 0, 0.5)',
        //     }
        //   : {},
      }}
    >
      <Box
        style={{
          position: "absolute",
          borderRadius: 20,
          ...boxStyle,
        }}
        sx={{
          px: { xs: 2, md: 3 },
          pb: 3,
          pt: 0,
          minWidth: { xs: "calc(100% - 32px)", md: 482 },
          minHeight: 100,
        }}
        bgcolor="background.paper"
      >
        {props.children}
      </Box>
    </Modal>
  );
};

export default BaseModal;
