import MyIcon from "@/components/public/my-icon";
import { useAppSelector } from "@/redux";
import { addressPoints } from "@/services/cpfp";
import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function UserPoints() {
  const [points, setPoints] = useState<number | string>("?");
  const { address, btcAddress } = useAppSelector((state) => state.wallet);
  useEffect(() => {
    if (address) {
      fetchPoints();
    } else {
      setPoints("?");
    }
  }, [address]);

  const fetchPoints = async () => {
    try {
      const res = await addressPoints({
        ord_address: address,
        btc_address: btcAddress,
      });
      if (res && res.data) {
        setPoints(res.data.points);
      }
    } catch (error) {
      console.log("fetchPoints-error: ", error);
    }
  };
  return (
    <Stack direction={"row"} alignItems={"center"} sx={{ flex: "none" }}>
      <MyIcon
        name={"icon-duoqianbaoguanli-shouyi"}
        size={16}
        color={"text.main"}
      />
      <Typography variant="h5" ml={0.5} color={"text.main"}>
        Points {points}
      </Typography>
      {/* <HtmlTooltipAttachIcon iconColor="#EBF2FF" title={<>Coming soon</>} /> */}
    </Stack>
  );
}
