/**
 * @file config
 * @description 和项目配置有关的方法
 */
import { imageBaseUrl } from "@/config/http";
export const getAssetsUrl = (src: string) => {
  const imageUrlRegex =
    /^(https?:\/\/)?([\w\d-]+\.)+[\w\d]{2,}(\/[\w\d-._~:/?#[\]@!$&'()*+,;%=]*)?\.(jpg|jpeg|png|gif|bmp|webp)$/i;

  return imageUrlRegex.test(src) ? src : `${imageBaseUrl}${src}`;
};
