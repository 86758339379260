import Image from "@/components/public/image";
import { Stack, Typography } from "@mui/material";

interface Props {
  msg?: string;
  src?: string;
  size?: number;
  width?: number;
  height?: number;
  notConnectSrc?: string;
  notConnectSize?: string;
  notConnectWidth?: string;
  notConnectHeight?: string;
}

export default function Empty(props: Props) {
  const {
    msg = `You don't have any Runes`,
    src = "/assets/runes_order_list_empty.png",
    size = 0,
    width,
    height,
    notConnectSrc = "/assets/runes_order_list_empty.png",
    notConnectWidth = 90,
    notConnectHeight = 59,
    notConnectSize = 0,
  } = props;

  const handleWidth = width || size;
  const handleHeight = height || size;
  return (
    <Stack
      spacing={3}
      alignItems={"center"}
      justifyContent={"center"}
      width={"100%"}
      height={"100%"}
    >
      <Image src={src} width={handleWidth} height={handleHeight} />
      <Typography variant={"body2"} color="text.secondary">
        {msg}
      </Typography>
    </Stack>
  );
}
