import { Stack, Typography, Button } from "@mui/material";
import Tabs from "@/components/public/tabs";
import { useEffect, useState, createContext } from "react";
import { createTypography } from "@/theme/typography";
import { useRouter } from "next/router";
import { TabsItem } from "@/types/components";
import AddressGroup from "./AddressGroup";
import BaseButton from "@/components/public/button/BaseButton";
import MyIcon from "@/components/public/my-icon";
import { useSearchAddress } from "@/hooks/router";
import FollowButton from "@/components/public/button/FollowButton";
import { useAppSelector } from "@/redux";
import NoLogin from "./NoLogin";
import { forwardRef, useImperativeHandle } from "react";

interface Props {
  children?: React.ReactNode;
  tabsHeight?: number;
  isFillHeader?: boolean;
}

const WalletBox = forwardRef(function (props: Props, ref) {
  const { children, tabsHeight = 44, isFillHeader = false } = props;
  const { searchAddress, isSearchPage } = useSearchAddress({});
  const { address } = useAppSelector((state) => state.wallet);
  const list: Array<TabsItem & { blurKey?: string; searchValue?: string }> = [
    { label: "Assets", value: "/", searchValue: "/home", blurKey: "/home" },
    {
      label: "Activity",
      value: "/record",
      searchValue: "/record",
      blurKey: "/record",
    },
  ];
  if (!isSearchPage)
    list.push(
      { label: "Watchlist", value: "/follow", blurKey: "/follow" },
      { label: "Analytics", value: "/proposal", disabled: true },
    );
  const [active, setActive] = useState(list[0].value);
  // const [searchAddress, setSearchAddress] = useState("");
  const typography = createTypography();
  const router = useRouter();
  const [init, setInit] = useState(false);
  const getListActive = (path: string) => {
    const item = list.find((item) => {
      return (
        path === item.value || (item.blurKey && path.indexOf(item.blurKey) > -1)
      );
    });
    return item ? item.value : "";
  };
  useEffect(() => {
    setActive(getListActive(router.pathname));
    setInit(true);
  }, [router.query]);

  // 是否为无效地址
  const [isInvalidAddress, setIsInvalidAddress] = useState(false);
  const handleSetIsInvalidAddress = (value: boolean) => {
    console.log("handleSetIsInvalidAddress", value);
    setIsInvalidAddress(value);
  };
  useImperativeHandle(ref, () => {
    return {
      setIsInvalid: handleSetIsInvalidAddress,
      isInvalid: isInvalidAddress,
    };
  }, []);
  return (
    <Stack width={"100%"} mx={"auto"}>
      {searchAddress ? (
        <Stack mb={"47px"}>
          <Stack mb={"38px"}>
            <BaseButton
              variant={"primary"}
              height={44}
              width={100}
              borderRadius={"22px"}
              textVariant="h5"
              onClick={() => router.back()}
            >
              <MyIcon
                name="icon-youjiantou"
                size={16}
                sx={{
                  transform: "rotate(-180deg);",
                  mr: 1,
                }}
              />
              Back
            </BaseButton>
          </Stack>
          <Stack spacing={"24px"} direction={"row"} alignItems={"center"}>
            <Typography variant="h2">{searchAddress}</Typography>
            {!isInvalidAddress ? (
              <FollowButton otherAddress={searchAddress} />
            ) : null}
          </Stack>
        </Stack>
      ) : address ? (
        <AddressGroup />
      ) : (
        <NoLogin />
      )}

      {isInvalidAddress ? (
        <Stack
          direction={"row"}
          alignItems={"center"}
          borderTop={1}
          borderColor={"divider"}
          pt={"23px"}
        >
          <MyIcon name="icon-bukaixin" size={50} />
          <Typography variant="h4" ml={2}>
            Address not found
          </Typography>
        </Stack>
      ) : searchAddress || address ? (
        <Stack position={"relative"}>
          <Stack
            height={tabsHeight}
            position={isFillHeader ? "relative" : "absolute"}
            width={"fit-content"}
            sx={{
              visibility: init ? "visible" : "hidden",
            }}
          >
            <Tabs
              activeIconHeight={6}
              list={list}
              showDisabledTooltip={true}
              active={active}
              onChange={(value) => {
                const item = list.find((item) => item.value === value);
                if (isSearchPage)
                  router.replace(`${item?.searchValue}/${searchAddress}`);
                else router.push(value);
              }}
            />
          </Stack>
          {children}
        </Stack>
      ) : null}
      {}
    </Stack>
  );
});
WalletBox.displayName = "WalletBox";
export default WalletBox;
