import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import { useAppSelector } from "@/redux";

export const useSearchAddress = ({
  cb = undefined,
  searchCb = undefined,
}: {
  cb?: () => void;
  searchCb?: () => void;
}) => {
  const router = useRouter();
  const [searchAddress, setSearchAddress] = useState("");
  const [isSearchPage, setIsSearchPage] = useState<boolean | undefined>(
    undefined,
  );
  const [routerInit, setRouterInit] = useState(false);
  const { address } = useAppSelector((state) => state.wallet);

  useEffect(() => {
    if (router.query.id) {
      setIsSearchPage(true);
      setSearchAddress(router.query.id as string);
    } else {
      setSearchAddress("");
      setIsSearchPage(false);
    }
    setRouterInit(true);
  }, [router.pathname, router.query]);
  useEffect(() => {
    if (routerInit && address && !isSearchPage) cb?.();
  }, [address, routerInit]);
  useEffect(() => {
    if (searchAddress) searchCb?.();
  }, [searchAddress]);
  return {
    searchAddress,
    isSearchPage,
    routerInit,
  };
};
