import { multipliedBy, sliceNumber, div } from "@/utils/number";

export const checkIsBTCAddress = (address: string) => {
  if (!address) {
    return false;
  }
  const addr = address.toLowerCase();
  if (process.env.NEXT_PUBLIC_NETWORK_WALLET !== "livenet") {
    return addr.startsWith("tb1q") || addr.startsWith("tb1p");
  } else {
    return addr.startsWith("bc1p") || addr.startsWith("bc1q");
  }
};
/**
 * @func satoshisToAmount
 * @description 聪转成BTC
 * @param string 输出BTC值
 */
export const satoshisToAmount = (
  value: string | number,
  zeroSuppression = true,
) => {
  const handleValue = Number(div(Number(value), 100000000)).toFixed(8);
  return zeroSuppression ? sliceNumber(handleValue, 8) : handleValue;
};

/**
 * @func amountToSatoshis
 * @description BTC转成聪
 * @param string 输出聪
 */
export const amountToSatoshis = (value: string | number) => {
  return sliceNumber(multipliedBy(Number(value), 100000000), 8);
};

/**
 * @func satoshisToUsd
 * @description 聪转成美元
 * @param string 输出美元
 */
export const satoshisToUsd = (
  value: number,
  rate: number,
  decimal: number = 3,
) => {
  return amountToUsd(Number(satoshisToAmount(value)), rate, decimal);
};

/**
 * @func amountToUsd
 * @description BTC转成美元
 * @param string 输出美元
 */
export const amountToUsd = (
  value: number,
  rate: number,
  decimal: number = 3,
) => {
  return sliceNumber(multipliedBy(value, rate), decimal);
};

/**
 * @func
 * @description 格式化美元
 * @param string 输出格式化后的字符串
 */
interface GetFormatUsdOption {
  value: number | string;
  decimal?: number;
  threshold?: number;
}
export const getFormatUsd = (options?: GetFormatUsdOption) => {
  const { value = "", decimal = 0, threshold = 1000 } = options || {};
  const dict = {
    B: 1000000000,
    M: 1000000,
    k: 1000,
  };
  if (value && Number(value) > threshold) {
    const list = Object.entries(dict);
    for (let i = 0; i < list.length; i++) {
      const [unit, num] = list[i];
      if (Number(value) > num) {
        return {
          value: sliceNumber(div(value, num), decimal),
          unit,
        };
      }
    }
  }
  return { value: sliceNumber(value, decimal), unit: "" };
};

/**
 * @func formatUsd
 * @description 格式化美元
 * @param string 输出格式化后的字符串
 */
export const formatUsd = (option?: GetFormatUsdOption) => {
  const { value = "" } = option || {};
  if (!value) return value;
  const res = getFormatUsd(option);
  return res.value + res.unit;
};
