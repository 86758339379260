import { alpha, Theme } from "@mui/material/styles";
import { TextFieldProps } from "@mui/material";
import { Palette } from "../types/palette";
import { borderRadius, fontSize } from "@mui/system";

declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides {
    disable1: true;
  }
  interface TextFieldPropsSizeOverrides {
    large: true;
  }
}
export default function Input(theme: Theme) {
  const palette = theme.palette as any as Palette;
  const rootStyle = (ownerState: TextFieldProps) => {
    const disabledState = {
      "&.Mui-disabled": {
        // disable字体颜色是白色
        ...(ownerState.color === "disable1" && {
          WebkitTextFillColor: theme.palette.text.primary,
        }),
      },
    };
    const size = InputSize(ownerState.size as any);
    return [disabledState, size];
  };
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        input: (props: { ownerState: TextFieldProps }) => {
          const { ownerState } = props;
          return rootStyle(ownerState);
        },
        root: {
          "&.MuiOutlinedInput-root": {
            borderRadius: "10px",
          },
          "& .MuiOutlinedInput-input": {
            color: palette.text.secondary,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.border.input,
          },
          "&:hover .MuiOutlinedInput-notchedOutline ": {
            borderColor: palette.active.active,
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: 1,
              borderColor: palette.active.active,
            },
          },
          "&.Mui-disabled": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: palette.border.input,
            },
          },
        },
      },
    },
    // MuiInputBase: {
    //   styleOverrides: {
    //     root: {
    //       "&.Mui-disabled": {
    //         "& svg": {
    //           color: theme.palette.text.disabled,
    //         },
    //       },
    //     },
    //     input: {
    //       "&::placeholder": {
    //         opacity: 1,
    //         color: theme.palette.text.disabled,
    //       },
    //     },
    //   },
    // },
    // MuiInput: {
    //   styleOverrides: {
    //     input: (props: { ownerState: TextFieldProps }) => {
    //       const { ownerState } = props;
    //       return rootStyle(ownerState);
    //     },
    //   },
    // },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       "& .MuiInputLabel-root.Mui-focused": {
    //         color: theme.palette.text.primary,
    //       },
    //     },
    //   },
    // },
    // MuiFilledInput: {
    //   styleOverrides: {
    //     root: {
    //       borderRadius: theme.shape.borderRadius,
    //       backgroundColor: alpha(theme.palette.grey[500], 0.08),
    //       "&:hover": {
    //         backgroundColor: alpha(theme.palette.grey[500], 0.16),
    //       },
    //       "&.Mui-focused": {
    //         backgroundColor: alpha(theme.palette.grey[500], 0.16),
    //       },
    //       "&.Mui-disabled": {
    //         backgroundColor: theme.palette.action.disabledBackground,
    //       },
    //     },
    //     underline: {
    //       "&:before, :after": {
    //         display: "none",
    //       },
    //     },
    //   },
    // },
  };
}

function InputSize(size: "small" | "medium" | "large") {
  const py = 16;
  const base = { padding: "8px 14px" };
  const sizes = {
    small: { fontSize: 14, height: 38 - py },
    medium: { height: 44 - py },
    large: { height: 56 - py },
  };

  return { ...base, ...sizes[size] };
}
