import { Box, Stack } from "@mui/material";
import Header from "./header";
import Footer from "./footer";
import CustomerService from "./CustomerService";
interface Props {
  children: React.ReactNode;
}

export default function main(props: Props) {
  const { children } = props;
  return (
    <Stack minWidth={1400}>
      <Header />
      <Stack component={"div"} width={"1400px"} mx={"auto"}>
        {children}
      </Stack>
      <Footer />
      <CustomerService />
    </Stack>
  );
}
