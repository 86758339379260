import { api } from "@/utils/request";
import {
  MarketInfoList,
  RunesOwned,
  RunesDetail,
  RunesOwnedUtxo,
  RunesOrderCreate,
  RunesOrderSubmit,
  RunesOrderCancel,
  MarketInfoSingle,
  RunesOrderCancelSubmit,
  RunesOrderListing,
  RunesOrderModify,
  RunesOrderModifySubmit,
  MarketList,
  RunesOwnedList,
  RunesSoldList,
  RunesOrderMarketinfoStatus,
  RunesMarketinfoOrders,
  RunesOrderCheckBid,
  RunesOrderBid,
  RunesOrderConfirmBid,
  RunesOrderBought,
} from "@/types/runes";
import { marketBaseUrl } from "@/config/http";
// import { handleGetData } from "@/utils/tools";

const baseUrl = marketBaseUrl;
// const baseUrl = '/api';

export const marketInfoSingle = (data: MarketInfoSingle["req"]) =>
  api<MarketInfoSingle["req"], MarketInfoSingle["res"]>({
    url: "/polymerizer/runes/marketinfo/single",
    method: "post",
    baseUrl,
    data,
  });

/**
 * 查询指定 runes 信息
 */
export const runesDetail = (data: RunesDetail["req"]) =>
  api<RunesDetail["req"], RunesDetail["res"]>({
    url: "/polymerizer/runes/detail",
    method: "post",
    data,
    baseUrl,
  });

/**
 * 用户持有的 utxo 列表
 */
export const runesOwnedUtxo = (data: RunesOwnedUtxo["req"]) =>
  api<RunesOwnedUtxo["req"], RunesOwnedUtxo["res"]>({
    url: "/polymerizer/runes/owned/utxo",
    method: "post",
    baseUrl,
    data,
  });

/**
 * 发起批量挂单
 */
export const runesOrderCreate = (data: RunesOrderCreate["req"]) =>
  api<RunesOrderCreate["req"], RunesOrderCreate["res"]>({
    url: "/polymerizer/runes/order/create",
    method: "post",
    baseUrl,
    data,
  });

/**
 * 确认提交签名-发起批量挂单
 */
export const runesOrderSubmit = (data: RunesOrderSubmit["req"]) =>
  api<RunesOrderSubmit["req"], RunesOrderSubmit["res"]>({
    url: "/polymerizer/runes/order/submit",
    method: "post",
    baseUrl,
    data,
  });

/**
 * 发起批量下架
 */
export const runesOrderCancel = (data: RunesOrderCancel["req"]) =>
  api<RunesOrderCancel["req"], RunesOrderCancel["res"]>({
    url: "/polymerizer/runes/order/cancel",
    method: "post",
    baseUrl,
    data,
  });

/**
 * 确认提交签名-发起批量下架
 */
export const runesOrderCancelSubmit = (data: RunesOrderCancelSubmit["req"]) =>
  api<RunesOrderCancelSubmit["req"], RunesOrderCancelSubmit["res"]>({
    url: "/polymerizer/runes/order/cancel-submit",
    baseUrl,
    method: "post",
    data,
    filterCodes: [10190009],
  });

/**
 * 首页runes列表
 */
export const marketList = (data: MarketList["req"]) =>
  api<MarketList["req"], MarketList["res"]>({
    url: "/polymerizer/runes/marketinfo/list",
    method: "post",
    baseUrl,
    data,
  });
/**
 * 持有runes列表
 */
export const runesOwnedList = (data: RunesOwnedList["req"]) =>
  api<RunesOwnedList["req"], RunesOwnedList["res"]>({
    url: "/polymerizer/runes/owned",
    method: "post",
    baseUrl,
    data,
  });
/**
 * 已成交记录
 */
export const runesSoldList = (data: RunesSoldList["req"]) =>
  api<RunesSoldList["req"], RunesSoldList["res"]>({
    url: "/polymerizer/runes/order/sold",
    method: "post",
    baseUrl,
    data,
  });

/**
 * 获取用户挂单信息
 */
export const runesOrderListing = (data: RunesOrderListing["req"]) =>
  api<RunesOrderListing["req"], RunesOrderListing["res"]>({
    url: "/polymerizer/runes/order/listing",
    method: "post",
    baseUrl,
    data,
  });

/**
 * 发起批量改价
 */
export const runesOrderModify = (data: RunesOrderModify["req"]) =>
  api<RunesOrderModify["req"], RunesOrderModify["res"]>({
    url: "/polymerizer/runes/order/modify",
    method: "post",
    baseUrl,
    data,
  });

/**
 * 发起批量改价
 */
export const runesOrderModifySubmit = (data: RunesOrderModifySubmit["req"]) =>
  api<RunesOrderModifySubmit["req"], RunesOrderModifySubmit["res"]>({
    url: "/polymerizer/runes/order/modify-submit",
    method: "post",
    baseUrl,
    data,
  });

/**
 * 发起批量改价
 */
export const runesOrderMarketinfoStatus = () =>
  api<any, RunesOrderMarketinfoStatus["res"]>({
    url: "/polymerizer/runes/marketinfo/status",
    method: "post",
    baseUrl,
    cleanVerifyToken: true,
  });

/**
 * runes 订单列表
 */
// export const runesMarketinfoOrders = (data: RunesMarketinfoOrders['req']) =>
//   api<RunesMarketinfoOrders['req'], RunesMarketinfoOrders['res']>({
//     url: `/polymerizer/runes/marketinfo/orders${handleGetData(data)}`,
//     method: 'get',
//     baseUrl,
//   });

/**
 * 买单接口-检测-1
 */
export const runesOrderCheckBid = (data: RunesOrderCheckBid["req"]) =>
  api<RunesOrderCheckBid["req"], RunesOrderCheckBid["res"]>({
    url: `/polymerizer/runes/order/check_bid`,
    method: "post",
    baseUrl,
    data,
    filterCodes: [10190009, 10190008],
  });

/**
 * 买单接口-下单-2
 */
export const runesOrderBid = (data: RunesOrderBid["req"]) =>
  api<RunesOrderBid["req"], RunesOrderBid["res"]>({
    url: `/polymerizer/runes/order/bid`,
    method: "post",
    baseUrl,
    data,
    filterCodes: [10190009, 10190008],
  });

/**
 * 买单接口-签名-3
 */
export const runesOrderConfirmBid = (data: RunesOrderConfirmBid["req"]) =>
  api<RunesOrderConfirmBid["req"], RunesOrderConfirmBid["res"]>({
    url: `/polymerizer/runes/order/confirm_bid`,
    method: "post",
    baseUrl,
    data,
    filterCodes: [10190009, 10190008],
  });

/**
 * 买单接口-签名-3用于测试使用
 */
export const runesOrderValidConfirmBid = (data: RunesOrderConfirmBid["req"]) =>
  api<RunesOrderConfirmBid["req"], RunesOrderConfirmBid["res"]>({
    url: `/polymerizer/runes/order/valid_confirm_bid`,
    method: "post",
    baseUrl,
    data,
  });

/**
 * 获取用户已购买信息
 */
export const runesOrderBought = (data: RunesOrderBought["req"]) =>
  api<RunesOrderBought["req"], RunesOrderBought["res"]>({
    url: `/polymerizer/runes/order/bought`,
    method: "post",
    baseUrl,
    data,
  });
