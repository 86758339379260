import * as echarts from "echarts/core";
import { LineChart, PieChart } from "echarts/charts";
import {
  TooltipComponent,
  TitleComponent,
  GridComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

// 注册所需组件
echarts.use([
  TooltipComponent,
  TitleComponent,
  GridComponent,
  LineChart,
  PieChart,
  CanvasRenderer,
]);

export { echarts };
