// 钱包名字
export const WALLET_NAME = {
  XV: "xverse",
  OK: "Oke", //'okx',
  Uni: "unisat",
  Atom: "ATOM",
  DL: "dogeLabs",
  BG: "Bitget",
  GT: "gate",
  TP: "tokenPocket",
  ME: "magiceden",
};

// 代币类型coinType(判断的时候，用变量，以防改名)
export const TokenType = {
  BTC: "btc",
  DOGE: "DOGE",
  Brc20: "brc20",
  Arc20: "arc20",
  Drc20: "drc20",
  Runes: "runes",
};

// 钱包网络
export const walletSdkNetwork = process.env.NEXT_PUBLIC_NETWORK_WALLET;
