import { useResultAlert } from "@/components/public/dialog/ResultAlertRevision";
import { Button, Stack, Typography } from "@mui/material";
import { createTypography } from "@/theme/typography";
import { formatId } from "@/utils/wallet";
import BaseTextarea from "@/components/public/textarea/BaseTextarea";
import { useEffect, useState } from "react";
import {
  walletAccountCheckAccount,
  walletAccountCheckAccountBatch,
} from "@/services/wallet_manager";
import BaseButton from "@/components/public/button/BaseButton";

// export function useNotSignNotBindDialog() {
//   const { openResultAlert } = useResultAlert();
//   const typography = createTypography();

//   const openNotSignNotBindAlert = async ({ address }: { address: string }) => {
//     return await openResultAlert({
//       type: "fail",
//       isShowTitle: false,
//       msg: `Detected your last connection was to the ${formatId(address)} address group. Do you want to link the current address to this group?`,
//       closeText: "Got it",
//       footerRender: (close) => {
//         const sx = {
//           height: 56,
//           ...typography.h5,
//           flex: 1,
//         };
//         return (
//           <Stack direction={"row"} alignItems={"center"} spacing={"14px"}>
//             <Button variant={"glow"} onClick={() => close(true)} sx={sx}>
//               Link
//             </Button>
//             <Button variant={"primary"} onClick={() => close(false)} sx={sx}>
//               Create new group
//             </Button>
//           </Stack>
//         );
//       },
//     });
//   };

//   return {
//     openNotSignNotBindAlert,
//   };
// }

export function useConfirmDialog() {
  const { openResultAlert } = useResultAlert();
  const typography = createTypography();
  const openConfirmDialog = async ({
    msg,
    closeText = "Link",
    confirmText = "Create new group",
    closeBtnValue = false,
    confirmBtnValue = true,
  }: {
    msg: string;
    closeText?: string;
    confirmText?: string;
    closeBtnValue?: any;
    confirmBtnValue?: any;
  }) => {
    return await openResultAlert({
      type: "fail",
      isShowTitle: false,
      msg,
      closeText: "Got it",
      footerRender: (close) => {
        const sx = {
          height: 56,
          ...typography.h5,
          flex: 1,
        };
        return (
          <Stack direction={"row"} alignItems={"center"} spacing={"14px"}>
            <Button
              variant={"glow"}
              onClick={() => close(closeBtnValue)}
              sx={sx}
            >
              {closeText}
            </Button>
            <Button
              variant={"primary"}
              onClick={() => close(confirmBtnValue)}
              sx={sx}
            >
              {confirmText}
            </Button>
          </Stack>
        );
      },
    });
  };

  return {
    openConfirmDialog,
  };
}

function AuthRenderComponents(options: {
  error?: boolean;
  value: string;
  setValue: (value: string) => void;
  token: string;
  close: (value: string) => string;
}) {
  const { token, error, value, setValue, close } = options;
  return (
    <Stack pt={"34px"} px={2} alignItems={"center"} pb={2}>
      <Typography variant="h5" mb={"18px"} textAlign={"center"}>
        Enter a signature verified address from the original group
      </Typography>
      <BaseTextarea
        variant="body1"
        padding={"18px"}
        value={value}
        onChange={(value) => setValue(value)}
      ></BaseTextarea>
      {error ? (
        <Typography
          variant="body2"
          color="error.main"
          mt={"10px"}
          textAlign={"center"}
        >
          The signature verified address was not found in the original address
          group!
        </Typography>
      ) : null}
      <BaseButton
        variant={"glow"}
        height={56}
        width={"100%"}
        textVariant="h5"
        disabled={error || !token}
        onClick={() => close(token)}
        sx={{
          mt: "24px",
        }}
      >
        Submit
      </BaseButton>
    </Stack>
  );
}

function GetAuthenticationRenderComponents(props: {
  close: any;
  address: string;
}) {
  const { close, address } = props;
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [token, setToken] = useState("");
  const verifyAddress = async () => {
    const res = await walletAccountCheckAccount({
      bind_address: address,
      signed_address: value,
    });
    const { valid, bind_token } = res.data;
    if (!valid) {
      setError(true);
      setToken("");
      return;
    }
    setError(false);
    setToken(bind_token);
  };

  useEffect(() => {
    if (value) verifyAddress();
    else setError(false);
  }, [value]);
  return (
    <AuthRenderComponents
      {...{
        value,
        setValue,
        close,
        error,
        token,
      }}
    />
  );
}
export function useGetAuthentication() {
  const { openResultAlert } = useResultAlert();
  const typography = createTypography();
  const openGetAuthentication = async ({
    address = "",
  }: {
    address: string;
  }) => {
    return await openResultAlert({
      type: "warn",
      minHeight: 337,
      width: 560,
      render: (close: any) => (
        <GetAuthenticationRenderComponents address={address} close={close} />
      ),
    });
  };

  return {
    openGetAuthentication,
  };
}

const GetMultipleAuthRenderComponents = (props: {
  close: any;
  btc_bind_address: string;
  ord_bind_address: string;
}) => {
  const { close, btc_bind_address, ord_bind_address } = props;
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [token, setToken] = useState("");
  const verifyAddress = async () => {
    const res = await walletAccountCheckAccountBatch({
      btc_bind_address,
      signed_address: value,
      ord_bind_address,
    });
    const { valid, bind_token } = res.data;
    if (!valid) {
      setError(true);
      setToken("");
      return;
    }
    setError(false);
    setToken(bind_token);
  };

  useEffect(() => {
    if (value) verifyAddress();
    else setError(false);
  }, [value]);
  return (
    <AuthRenderComponents
      {...{
        value,
        setValue,
        close,
        error,
        token,
      }}
    />
  );
};

export function useGetMultipleAddressAuthentication() {
  const { openResultAlert } = useResultAlert();
  const typography = createTypography();
  const openGetAuthentication = async ({
    btc_bind_address,
    ord_bind_address,
  }: {
    btc_bind_address: string;
    ord_bind_address: string;
  }) => {
    return await openResultAlert({
      type: "warn",
      minHeight: 337,
      width: 560,
      render: (close: any) => (
        <GetMultipleAuthRenderComponents
          btc_bind_address={btc_bind_address}
          ord_bind_address={ord_bind_address}
          close={close}
        />
      ),
    });
  };

  return {
    openGetAuthentication,
  };
}
