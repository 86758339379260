import { Stack, Typography, Button } from "@mui/material";
import MyIcon from "@/components/public/my-icon";
import { useEffect, useState } from "react";
import {
  homePortfolioDetail,
  homePortfolioTrend,
  homePortfolioPie,
} from "@/services/wallet_manager";
import {
  HomePortfolioDetail,
  WalletAccountAddressPortfolioDetailItem,
} from "@/types/wallet_manager";
import { createTypography } from "@/theme/typography";
import Tabs from "@/components/public/tabs";
import Select from "@/components/public/select";
import AssetTrend from "@/components/pc/v1/home/AssetTrend";
import AssetDist from "@/components/pc/v1/home/AssetDist";
import FaceTabs from "@/components/public/tabs/FaceTabs";
import { useTableColumn } from "@/components/pc/v1/home/index";
import { getUnixDateFormatStr } from "@/utils/formatTime";
import { CustomTable, ColumnItem } from "@/components/public/table/CustomTable";
import { UserSortType } from "@/types/service";
import { SnackbarUtils } from "@/components/public/snackbar/SnackbarProvider";

export function useDemoData() {
  const userList = new Array(6).fill("");

  const tabs = [
    { label: "Assets", value: "/" },
    {
      label: "Activity",
      value: "/record",
    },
    { label: "Watchlist", value: "/follow" },
    { label: "Analytics", value: "/proposal" },
  ];

  // tabs
  const typeList = [
    { label: "View by token", value: "1" },
    { label: "View by address", value: "2" },
    // { label: "View by address", value: "3" },
  ];

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<WalletAccountAddressPortfolioDetailItem[]>(
    [],
  );

  const sort = {
    sort: "usd_assets",
    desc: true,
  };
  const getTableList = async () => {
    setLoading(true);
    const params: HomePortfolioDetail["req"] = {
      address: "",
      sort: sort.sort,
      desc: sort.desc,
    };
    const res = await homePortfolioDetail(params).catch(() => null);
    setLoading(false);
    if (!res) return;
    const { items = [] } = res.data;
    setList(items);
  };
  useEffect(() => {
    getTableList();
  }, []);
  return {
    userList,
    tabs,
    typeList,
    list,
    loading,
    sort,
  };
}

export default function NoLoginDemo() {
  const { userList, tabs, typeList, sort, list, loading } = useDemoData();
  const typography = createTypography();
  const boxAttr = {
    width: `calc(50% - 18.5px)`,
    height: 416,
    border: 1,
    borderColor: "#2C3036",
    borderRadius: "20px",
  };
  const { column } = useTableColumn(getUnixDateFormatStr(Date.now()));

  return (
    <Stack
      width={1300}
      height={"fit-content"}
      borderRadius={"40px"}
      border={4}
      borderColor={"#383838"}
      p={"32px 28px 63px 28px"}
      sx={{
        background: "linear-gradient( 180deg, #1C1C1C 0%, #0E1014 100%)",
      }}
    >
      <Stack>
        <Stack
          direction={"row"}
          spacing={"32px"}
          alignItems={"center"}
          mb={"37px"}
        >
          {["#F45952", "#DFB94E", "#5AB748"].map((item, index) => (
            <Stack
              key={index}
              height={20}
              width={20}
              borderRadius={"50%"}
              bgcolor={item}
            ></Stack>
          ))}
          <Stack
            height={32}
            width={580}
            bgcolor={"#26292C"}
            border={"8px"}
            borderRadius={"8px"}
          ></Stack>
        </Stack>
      </Stack>
      <Stack
        flex={1}
        overflow={"hidden"}
        alignItems={"center"}
        position={"relative"}
      >
        <Stack
          sx={{
            zoom: 0.8,
            // transformOrigin: "top center",
            // overflow: "hidden",
          }}
          width={1400}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            mb={"22px"}
            sx={{
              cursor: "pointer",
            }}
          >
            <Typography variant="h2">My Wallet ({userList.length})</Typography>
            &nbsp; &nbsp;
            <MyIcon name="icon-down" size={20} />
          </Stack>
          <Stack mb={"28px"}>
            <Button
              variant={"primary"}
              sx={{
                width: 130,
                height: 44,
                ...typography.subtitle3,
              }}
            >
              Link Address
            </Button>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mb={"32px"}
          >
            <Tabs activeIconHeight={6} list={tabs} active={tabs[0].value} />

            <Select list={[]} value={""} placeholder="All addresses" disabled />
          </Stack>

          <Stack mb={"66px"}>
            <Stack
              width={"100%"}
              justifyContent={"space-between"}
              direction={"row"}
              mt={2}
            >
              <Stack {...boxAttr}>
                <AssetTrend address={""} api={homePortfolioTrend} />
              </Stack>
              <Stack {...boxAttr}>
                <AssetDist address={""} api={homePortfolioPie} />
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={"36px"}
            >
              <Typography variant="h3">Asset Details</Typography>
              <Stack direction={"row"} spacing={"20px"}>
                <Select
                  width={180}
                  list={[]}
                  value={""}
                  placeholder="All assets"
                />
                <FaceTabs list={typeList} value={"1"} mode={"padding"} />
              </Stack>
            </Stack>
            <Stack border={1} borderRadius={"20px"} borderColor={"divider"}>
              <CustomTable
                headerPy={1.75}
                loadingPlaceholderLen={10}
                defaultKey={sort.sort}
                defaultSort={sort.desc ? UserSortType.desc : UserSortType.asc}
                column={column}
                list={list}
                loading={loading}
                showEmptyPlaceholder={true}
                tableHeight={"fit-content" as any}
                bodyPy={2}
                bodyOtherStyle={({ isLast }) => ({
                  borderBottom: isLast ? "none" : "1px solid #2C3036",
                })}
                headerOtherStyle={{
                  borderBottom: "1px solid #2C3036",
                }}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          height={"100%"}
          width={"100%"}
          position={"absolute"}
          left={0}
          zIndex={0}
          bgcolor={"transparent"}
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            SnackbarUtils.warning(
              "For the full experience, please connect your wallet first.",
            );
          }}
        ></Stack>
      </Stack>
    </Stack>
  );
}
