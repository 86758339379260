import { Stack } from '@mui/material';
import { Children, isValidElement, cloneElement } from 'react';
import MyIcon from '@/components/public/my-icon';

interface Props {
  children: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
}

export default function ButtonStatusManage(props: Props) {
  const { children, loading, disabled } = props;
  const addProps: any = {};
  const size = 16;
  if (loading) {
    addProps['disabled'] = true;
    addProps['startIcon'] = (
      <Stack width={size} height={size} className="rotate-animation">
        <MyIcon name="icon-loading" size={size} color={'currentColor'} />
      </Stack>
    );
  }
  const modifiedChildren = Children.map(children, (child) => {
    // 检查子组件类型并修改传参
    if (isValidElement(child)) {
      return cloneElement(child, addProps);
    }
    return child;
  });
  return <>{modifiedChildren}</>;
}
