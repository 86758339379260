import { imageBaseUrl } from "@/config/http";
import src from "node_modules/@emotion/cache/dist/declarations/src";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getAssetsUrl } from "@/utils/config";

export interface ImageProps {
  src: string;
  height?: number | string;
  width?: number | string;
  size?: number;
  alt?: string;
  visibleByDefault?: boolean;
  onLoad?: () => void;
  afterLoad?: () => void;
  beforeLoad?: () => void;
  onError?: () => void;
  style?: React.CSSProperties;
}

export function Image(props: ImageProps) {
  const {
    src,
    height = 0,
    width = 0,
    size = 0,
    ...other // 用于参数穿透
  } = props;
  const handleUrl = getAssetsUrl(src);
  const getPx = (value: number | string) =>
    value ? (typeof value === "number" ? `${value}px` : value) : undefined;
  const handleHeight = getPx(height) || getPx(size) || "fit-content";
  const handleWidth = getPx(width) || getPx(size) || "fit-content";
  return (
    <LazyLoadImage
      {...other}
      style={{
        ...other.style,
        height: handleHeight,
        width: handleWidth,
      }}
      src={handleUrl}
    />
  );
}

export default Image;
