import ButtonStatusManage from "@/components/expansion/ButtonStatusManage";
import { Button, Stack } from "@mui/material";
import MyIcon from "../my-icon";

interface LoadingButtonProps extends React.ComponentProps<typeof Button> {
  loading: boolean;
  children: React.ReactNode;
  childrenProps?: React.ComponentProps<typeof Stack>; //如果表格有设置列宽，则需要给children设置宽度才能把按钮撑开
}
const LoadingButton = ({
  loading,
  children,
  childrenProps,
  ...props
}: LoadingButtonProps) => {
  return (
    <>
      <ButtonStatusManage loading={loading}>
        <Button {...props}>
          {childrenProps ? (
            <Stack {...childrenProps}>{children}</Stack>
          ) : (
            <>{children}</>
          )}
        </Button>
      </ButtonStatusManage>
    </>
  );
};
export default LoadingButton;
