import { Stack, Typography } from "@mui/material";
import {
  useState,
  forwardRef,
  useEffect,
  useCallback,
  useImperativeHandle,
} from "react";
import { styled } from "@mui/material/styles";

function TypeBox(props: {
  type: 1 | 2 | 3;
  children: React.ReactNode;
  placeholderWidth?: string | number;
  endDom?: React.ReactNode;
  prefix?: React.ReactNode;
}) {
  const {
    type,
    children = null,
    placeholderWidth = "9%",
    endDom = null,
    prefix = null,
  } = props;
  if (!endDom && !prefix) return <>{children}</>;
  return (
    <>
      {type === 1 ? (
        <Stack direction={"row"} width={"100%"} alignItems={"center"}>
          {prefix ? (
            <Stack minWidth={placeholderWidth} flex={"none"}>
              <Stack
                sx={{
                  visibility: prefix ? "inline-flex" : "hidden",
                }}
              >
                {prefix}
              </Stack>
            </Stack>
          ) : null}
          <Stack flex={1}>{children}</Stack>
          <Stack minWidth={placeholderWidth} flex={"none"}>
            {endDom}
          </Stack>
        </Stack>
      ) : null}
      {type === 2 ? (
        <Stack direction={"row"} width={"100%"} alignItems={"center"}>
          <Stack flex={1}>{children}</Stack>
          <Stack minWidth={placeholderWidth} flex={"none"}>
            {endDom}
          </Stack>
        </Stack>
      ) : null}
      {type === 3 ? (
        <Stack
          direction={"row"}
          width={"100%"}
          alignItems={"center"}
          spacing={1}
        >
          <Stack minWidth={placeholderWidth} flex={"none"}>
            {prefix}
          </Stack>
          <Stack flex={1}>{children}</Stack>
        </Stack>
      ) : null}
    </>
  );
}

const inputCommonStyle: any = {
  color: "#fff",
  fontSize: 16,
  textAlign: "center",
  "&::before,&::after": {
    border: "none!important",
  },
  "&::placeholder": {
    fontSize: "16px",
  },
  "& .MuiInput-input": {
    textAlign: "center",
  },
  "&:focus-visible": {
    outline: "none",
  },
  border: "none",
};
const Input = styled("input")(({ theme }) => inputCommonStyle);
const Textarea = styled("textarea")(({ theme }) => ({
  ...inputCommonStyle,
  resize: "none",
}));

interface Props {
  placeholder?: string;
  boxHeight?: number;
  value: string;
  change: (value: any) => void;
  type?: 1 | 2 | 3; // 1: 分三段 2: 分两段
  endDom?: React.ReactNode;
  maxLength?: number;
  onBlur?: () => void;
  autoFocus?: boolean;
  fontWeight?: number;
  fontSize?: number;
  api?: (api: { insertAtCursor: (str: string) => void }) => void;
  openMultipleRow?: boolean;
  minHeight?: number;
  textAlign?: "center" | "left" | "right";
  prefix?: React.ReactNode;
  bgcolor?: string;
  p?: string;
  placeholderWidth?: string | number;
  placeholderColor?: string;
  isError?: boolean;
  hasEmptyError?: boolean;
  onFocus?: (e?: any) => void;
  borderRadius?: number | string;
  width?: number | string;
  color?: React.CSSProperties | string;
  onPressEnter?: (value: string) => void; // 回车按钮回调，仅在单行input的时候有效
}
const FormInput = forwardRef((props: Props, ref) => {
  const {
    placeholder = "",
    boxHeight = 48,
    change = null,
    value = "",
    type = 1,
    endDom = null,
    prefix = null,
    maxLength = 9999999,
    onBlur = null,
    autoFocus = false,
    fontSize = 12,
    fontWeight = 400,
    openMultipleRow = false,
    minHeight = 28,
    textAlign = "left",
    bgcolor = "transparent",
    p = "0",
    placeholderWidth = "9%",
    isError = false,
    placeholderColor = "rgba(94, 95, 98, 1)",
    hasEmptyError = false,
    onFocus = null,
    borderRadius = "10px",
    width = "100%",
    color = "#fff",
    onPressEnter = null,
  } = props;
  const [cursorPosition, setCursorPosition] = useState("");
  const onChange = (e: any) => {
    setCursorPosition(inputRef.selectionEnd);
    if (change && e.target.value.length <= maxLength) {
      return change(e.target.value);
    }
  };
  const keyDown = (e: any) => {
    if (!openMultipleRow && (e.key === "Enter" || e.keyCode === 13))
      onPressEnter?.(value);
  };
  const [inputRef, setInputRef] = useState<any>(null);
  useEffect(() => {
    if (inputRef && value) {
      inputRef.setSelectionRange(cursorPosition, cursorPosition);
      calculateHeight();
    }
  }, [value, inputRef, minHeight]);

  useEffect(() => {
    if (inputRef && autoFocus) {
      inputRef.focus();
    }
  }, [inputRef]);

  const insertAtCursor = (str: string) => {
    const input = inputRef;
    const startPos = input.selectionStart;
    const endPos = input.selectionEnd;
    input.value =
      input.value.substring(0, startPos) +
      str +
      input.value.substring(endPos, input.value.length);
    // 设置新的光标位置为原来的位置加上插入字符的长度
    const newPosition = startPos + 1;
    input.setSelectionRange(newPosition, newPosition);
    // 聚焦到 input 元素
    if (change) change(input.value);
    setCursorPosition(newPosition);
    input.focus();
  };

  const imperativeHandleObj = {
    insertAtCursor,
  };
  useImperativeHandle(ref, () => imperativeHandleObj);

  // Textarea
  const [height, setHeight] = useState(minHeight);
  const maxHeight = 300;
  const calculateHeight = () => {
    const handleHeight = inputRef.scrollHeight; // 设置高度为内容的实际高度
    inputRef.style.height =
      handleHeight > maxHeight ? `${maxHeight}px` : `${handleHeight}px`; // 这里不做转换，转换会存在高度误差，导致高度不变的情况下一直在不停的变化高度
    // setHeight(handleHeight > 300 ? 300 : handleHeight);
  };

  return (
    <Stack width={width}>
      <Stack
        width={"100%"}
        minHeight={boxHeight}
        display={"inline-flex"}
        bgcolor={bgcolor}
        alignItems={"center"}
        direction={"row"}
        p={p}
        border={1}
        borderColor={"divider"}
        borderRadius={borderRadius}
      >
        <TypeBox
          type={type}
          endDom={endDom}
          prefix={prefix}
          placeholderWidth={placeholderWidth}
        >
          {openMultipleRow ? (
            <Textarea
              ref={(ref) => setInputRef(ref)}
              placeholder={placeholder}
              onBlur={() => {
                if (onBlur) onBlur();
              }}
              onFocus={(e) => {
                if (onFocus) onFocus(e);
              }}
              sx={{
                color: isError && value ? "error.main" : color,
                width: "100%",
                height: height,
                background: "transparent",
                fontSize: fontSize,
                fontWeight: fontWeight,
                lineHeight: `${fontSize + 4}px`,
                textAlign: textAlign,
              }}
              onInput={(e: any) => {
                onChange(e);
                calculateHeight();
              }}
              value={value}
            />
          ) : (
            <Input
              ref={(ref) => setInputRef(ref)}
              placeholder={placeholder}
              onBlur={() => {
                if (onBlur) onBlur();
              }}
              onFocus={() => {
                if (onFocus) onFocus();
              }}
              sx={{
                color: isError && value ? "error.main" : color,
                width: "100%",
                height: fontSize + 4,
                background: "transparent",
                fontSize: fontSize,
                fontWeight: fontWeight,
                lineHeight: fontSize,
                textAlign: textAlign,
                "&::placeholder": {
                  fontSize: fontSize,
                  color:
                    isError && hasEmptyError ? "#C75858" : placeholderColor,
                },
              }}
              onInput={onChange}
              onKeyDown={keyDown}
              value={value}
            />
          )}
        </TypeBox>
      </Stack>
    </Stack>
  );
});
FormInput.displayName = "FormInput";

export default FormInput;
