import { Stack, Typography, Box, Popover, SxProps } from "@mui/material";
import { useEffect, useState, Fragment } from "react";
import { ListItem } from "@/types/components";
import { alpha } from "@mui/material/styles";
import { TypographyPropsVariantOverridesProps } from "@/theme/overrides/Typography";

export interface MenusBaseProps {
  borderRadius?: number | string;
  p?: string | number;
  boxSx?: SxProps;
  maxHeight?: number;
  childrenRender?: (open: boolean) => React.ReactNode;
}

interface MenusBoxProps extends MenusBaseProps {
  children?: React.ReactNode;
  render?: (open: boolean, close?: () => void) => React.ReactNode;
  onClose?: () => void;
  disabled?: boolean;
}
export function MenusBox(props: MenusBoxProps) {
  const {
    children = null,
    borderRadius = 2.5,
    p = "8px",
    boxSx = {},
    maxHeight = 500,
    render = null,
    childrenRender = null,
    onClose = undefined,
    disabled = false,
  } = props;
  const [domRef, setDomRef] = useState<HTMLElement | null>(null);
  const [minWidth, setMinWidth] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    console.log(3333);
    setOpen(false);
    onClose?.();
  };
  useEffect(() => {
    if (domRef) {
      domRef.getClientRects();
      const rect = domRef.getBoundingClientRect();
      setMinWidth(rect.width);
    }
  }, [domRef]);
  return (
    <>
      <Box
        ref={(ref: any) => setDomRef(ref)}
        component={"span"}
        width={"fit-content"}
        onClick={() => {
          if (!disabled) setOpen(true);
        }}
        sx={{
          cursor: disabled ? "not-allowed" : "pointer",
        }}
      >
        {childrenRender ? childrenRender(open) : children}
      </Box>
      <Popover
        open={open}
        anchorEl={domRef}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: "left",
        }}
        anchorPosition={{
          top: 500,
          left: 0,
        }}
        sx={{
          "& > .MuiPopover-paper": {
            bgcolor: "transparent",
          },
        }}
      >
        <Stack
          bgcolor={"background.textPaper"}
          borderRadius={borderRadius}
          p={p}
          maxHeight={maxHeight}
          minWidth={minWidth}
          className="scrollbar"
          sx={{
            boxShadow: "0px 12px 20px 0px rgba(1,3,10,0.7)",
            overflow: "auto",
            ...boxSx,
          }}
        >
          {render ? render(open, handleClose) : null}
        </Stack>
      </Popover>
    </>
  );
}

interface MenusProps extends MenusBaseProps {
  children?: React.ReactNode;
  list: ListItem[];
  value?: string | number;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  itemRender?: (item: ListItem, close?: () => void) => React.ReactNode;
  itemHeight?: number;
  change?: (value: string | number, row: ListItem) => void;
  hoverBgColor?: string;
  activeColor?: string;
  labelVariant?: TypographyPropsVariantOverridesProps;
  disabled?: boolean;
}

export function Menus(props: MenusProps) {
  const {
    children,
    itemRender = null,
    list,
    prefix = null,
    suffix = null,
    itemHeight = 59,
    value = undefined,
    change = null,
    hoverBgColor = "rgba(74, 78, 91, 0.5)",
    activeColor = "secondary.main",
    labelVariant = "h5",
    ...other
  } = props;
  const valueChange = (value: string | number, row: ListItem) => {
    change?.(value, row);
  };
  return (
    <MenusBox
      {...other}
      render={(_, close) => (
        <>
          {prefix ? prefix : null}
          {list.map((item, key) => (
            <Fragment key={key}>
              {itemRender ? (
                itemRender(item, close)
              ) : (
                <Stack
                  height={itemHeight}
                  justifyContent={"center"}
                  spacing={0.5}
                  px={2}
                  borderRadius={1}
                  flex={"none"}
                  sx={{
                    "&:hover": {
                      bgcolor: item.value === value ? "" : hoverBgColor,
                    },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    valueChange(item.value, item);
                    close?.();
                  }}
                >
                  <Typography
                    variant={labelVariant as any}
                    color={item.value === value ? activeColor : ""}
                  >
                    {item.label}
                  </Typography>
                  {item.remark ? (
                    <Typography
                      variant="caption"
                      color={item.value === value ? activeColor : ""}
                    >
                      {item.remark}
                    </Typography>
                  ) : null}
                </Stack>
              )}
            </Fragment>
          ))}
          {suffix ? suffix : null}
        </>
      )}
    ></MenusBox>
  );
}
