const { AES, enc } = require("crypto-js");
import { NOT_LOGIN_CODE } from "@/config/http";
import dayjs from "dayjs";

// 加密
const encrypt = (data: any, key: string) => {
  return AES.encrypt(data, key).toString();
};
// 解密
const decrypt = (str: string, key: string) => {
  const res = AES.decrypt(str, key);
  return res.toString(enc.Utf8);
};

// token相关操作
const TOKEN_STR = "TOKEN_STR";
const INVALID_KEY = "NO_LOGIN";
export function getToken() {
  const token = localStorage.getItem(TOKEN_STR) || "";
  return decrypt(token, INVALID_KEY);
}
export function setToken(str: string) {
  const res = encrypt(str, INVALID_KEY);
  localStorage.setItem(TOKEN_STR, res);
}
export function clearToken() {
  localStorage.removeItem(TOKEN_STR);
}
export function createInvalid() {
  return {
    code: NOT_LOGIN_CODE,
    data: null,
    msg: "Not Login",
  };
}

// 地址相关操作
const WALLET_KEY = "WALLET_KEY";
const WALLET_ENCRYPT = "WALLET_ENCRYPT";
export function getAddress(): {
  address: string;
  btcAddress: string;
} {
  const token = localStorage.getItem(WALLET_KEY) || "";
  const objStr = decrypt(token, WALLET_ENCRYPT);
  const defaultRes = {
    address: "",
    btcAddress: "",
  };
  try {
    const { address = "", time = 0, btcAddress = "" } = JSON.parse(objStr);
    if (dayjs.unix(time).add(1, "day").unix() < dayjs().unix()) {
      return { ...defaultRes };
    }
    return {
      address,
      btcAddress,
    };
  } catch (error) {
    return { ...defaultRes };
  }
}
export function setAddress(address: string, btcAddress: string) {
  const obj = { address, time: dayjs().unix(), btcAddress };
  const objStr = JSON.stringify(obj);
  const res = encrypt(objStr, WALLET_ENCRYPT);
  localStorage.setItem(WALLET_KEY, res);
}
export function clearAddress() {
  localStorage.removeItem(WALLET_KEY);
}
