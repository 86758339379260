import {
  Box,
  Stack,
  Typography,
  Backdrop,
  Button,
  SxProps,
} from "@mui/material";
import MyIcon from "@/components/public/my-icon";
import Image from "@/components/public/image";
import { useBaseAlert, Close } from "./BaseDialog";
import { isValidElement } from "react";
import { createTypography } from "@/theme/typography";
/*
 * 通用提示弹窗 组件封装
 * @param
 * @return
 */
interface ResultAlertProps {
  // isSuccess: boolean;
  type: "succeed" | "fail" | "warn";
  title?: string;
  msg?: string | React.ReactNode;
  msgRender?: (close: Close) => React.ReactNode;
  render?: (close: Close) => React.ReactNode;
  footerRender?: (close: Close) => React.ReactNode;
  closeText?: string;
  imgRender?: (close: Close) => React.ReactNode;
  showClose?: boolean;
  boxSx?: SxProps;
  width?: number;
  minHeight?: number;
  isShowTitle?: boolean;
}
const ResultAlert = (
  props: ResultAlertProps & {
    close: Close;
  },
) => {
  const {
    type,
    render = null,
    close,
    showClose = true,
    boxSx = { px: 2, pb: 3, pt: 8 },
    width = 482,
    minHeight = 419,
    title = "",
    msg = "",
    msgRender = null,
    footerRender = null,
    closeText = "Close",
    imgRender = null,
    isShowTitle = true,
  } = props;
  const defaultTypeTitle = {
    succeed: "Submission successful!",
    fail: "Submission failed!",
    warn: "Submission failed!",
  };
  const handleTitle = title || defaultTypeTitle[type];

  const handleClose = (value: boolean = true) => {
    close(value);
  };
  const imgDist = {
    succeed: "/assets/success.png",
    fail: "/assets/error.png",
    warn: "/assets/error.png",
  };
  const showImg = imgDist[type];
  const typography = createTypography();

  return (
    <Backdrop
      open={true}
      aria-hidden={false}
      onClick={() => {
        // 不要点击任意地方都关闭，否则用户会误点，看不清提示内容
        // if (onClick) {
        //   onClick();
        //   closeCb();
        // }
      }}
    >
      <Box
        sx={{
          width,
          borderRadius: "20px",
          position: "relative",
          minHeight,
          display: "flex",
          flexDirection: "column",
        }}
        bgcolor="background.paper"
      >
        {showClose ? (
          <Stack
            onClick={() => handleClose(false)}
            position={"absolute"}
            right={"24px"}
            top={"24px"}
            zIndex={2}
            sx={{
              cursor: "pointer",
            }}
          >
            <MyIcon name="icon-close" color="white" size={16} />
          </Stack>
        ) : null}
        {render ? (
          render(handleClose)
        ) : (
          <>
            <Stack
              flex={1}
              sx={{
                height: "100%",
                width: "100%",
                ...boxSx,
              }}
            >
              <Stack
                alignItems="center"
                justifyContent={"space-between"}
                flex={1}
              >
                <Stack alignItems={"center"} width={"100%"}>
                  {imgRender ? (
                    imgRender(handleClose)
                  ) : (
                    <Image src={showImg} size={130} />
                  )}

                  {isShowTitle ? (
                    <Typography variant="h3" mt={1}>
                      {handleTitle}
                    </Typography>
                  ) : null}
                  {msgRender ? (
                    msgRender(handleClose)
                  ) : !isValidElement(msg) ? (
                    <Typography
                      variant="subtitle3"
                      mt={2}
                      textAlign={"center"}
                      width={"calc(100% - 80px)"}
                      sx={{
                        wordWrap: "break-word",
                      }}
                    >
                      {msg}
                    </Typography>
                  ) : (
                    msg
                  )}
                </Stack>
                <Stack width={"100%"}>
                  {footerRender ? (
                    footerRender(handleClose)
                  ) : (
                    <Button
                      variant={"glow"}
                      fullWidth
                      sx={{
                        mt: 2,
                        height: "56px",
                        borderRadius: "22px",
                        ...typography.subtitle2,
                      }}
                      onClick={() => handleClose()}
                    >
                      {closeText}
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </>
        )}
      </Box>
    </Backdrop>
  );
};
export default ResultAlert;

/**
 * @func useResultAlert 使用打开Alert的前提是警告弹窗
 * @returns openResultAlert: 打开弹窗；closeResultAlert: 用一个hook生成的值打开多个时存在问题，仅能关闭最后一个，多个时慎用，而是使用openResultAlert生成的值
 * @example
 * const { openResultAlert } = useResultAlert();
 * const res = openResultAlert({
 *  text: 'Wallet connected. Start trading now!',
 *  isSuccess: true,
 *  footerRender: (close) => {
 *    <Button onClick={close}>关闭</Button>
 *  },
 * });
 * res => boolean
 */
export const useResultAlert = () => {
  /**
   * @func openResultAlert
   * @description 打开弹层
   * @param {options} 弹层插件配置信息
   * @returns remove: 移除的弹窗的方法
   * @example
   * const res = await openResultAlert()
   */
  const { openBaseAlert } = useBaseAlert();
  const openResultAlert = (options: ResultAlertProps) => {
    return openBaseAlert({
      render: (close: Close) => <ResultAlert {...options} close={close} />,
    });
  };

  return {
    openResultAlert,
  };
};
