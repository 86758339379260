import { Stack, Typography, Tooltip } from "@mui/material";
import { createTypography } from "@/theme/typography";
import { TabsItem } from "@/types/components";

export interface TabsProps {
  list: TabsItem[];
  active: string;
  onChange?: (value: string) => void;
  variant?: any;
  activeVariant?: any;
  color?: string;
  activeColor?: string;
  weight?: number;
  activeWeight?: number;
  activeSpacing?: string;
  activeIconHeight?: number;
  activeIconWidth?: number;
  showDisabledTooltip?: boolean;
  showDisabledTooltipMsg?: string;
  boxSpacing?: number;
}

export default function Tabs(props: TabsProps) {
  const {
    list = [],
    active = "",
    onChange = null,
    variant = "value2",
    activeVariant = "h4",
    color = "text.secondary",
    activeColor = "text.primary",
    weight = 600,
    activeWeight = 600,
    activeSpacing = "6px",
    activeIconHeight = 8,
    activeIconWidth = 24,
    showDisabledTooltip = false,
    showDisabledTooltipMsg = "Coming soon",
    boxSpacing = 5,
  } = props;
  const t: any = createTypography();
  const lineHeight = t[activeVariant];
  return (
    <Stack direction={"row"} spacing={boxSpacing} alignItems={"flex-start"}>
      {list.map((item, index) => (
        <Stack
          key={index}
          onClick={() => {
            if (!item.disabled) onChange?.(item.value);
          }}
          position={"relative"}
          alignItems={"center"}
          justifyContent={"center"}
          spacing={activeSpacing}
          sx={{
            cursor: !item.disabled ? "pointer" : "not-allowed",
          }}
        >
          {showDisabledTooltip && item.disabled ? (
            <Tooltip title={showDisabledTooltipMsg}>
              <Typography
                component={"span"}
                variant={item.value === active ? variant : activeVariant}
                color={item.value === active ? activeColor : color}
                fontWeight={item.value === active ? activeWeight : weight}
                sx={{
                  lineHeight,
                }}
              >
                {item.label}
              </Typography>
            </Tooltip>
          ) : (
            <Typography
              component={"span"}
              variant={item.value === active ? variant : activeVariant}
              color={item.value === active ? activeColor : color}
              fontWeight={item.value === active ? activeWeight : weight}
              sx={{
                lineHeight,
              }}
            >
              {item.label}
            </Typography>
          )}
          {item.value === active ? (
            <Stack
              height={activeIconHeight}
              width={activeIconWidth}
              borderRadius={"4px"}
              bgcolor={"secondary.main"}
            ></Stack>
          ) : null}
        </Stack>
      ))}
    </Stack>
  );
}
