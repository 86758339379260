import { mountComponentToBody } from "@/utils/dom";
import ThemeProvider from "@/theme/index";

export type Close = (value?: any) => any;
interface OpenAlertProps<p = any> {
  render: (close: Close) => React.ReactNode;
}
export const useBaseAlert = () => {
  /**
   * @func openResultAlert
   * @description 打开弹层
   * @param {options} 弹层插件配置信息
   * @returns remove: 移除的弹窗的方法
   * @example
   * const remove = openResultAlert()
   */
  function openBaseAlert<p = any>(
    options: OpenAlertProps<p> & p,
  ): Promise<any> {
    const { render } = options;
    return new Promise((resolve) => {
      let removeDom: any;
      const close = (value: any = true) => {
        removeDom();
        console.log("close---value", value);
        resolve(value);
      };

      removeDom = mountComponentToBody(
        <ThemeProvider>{render(close)}</ThemeProvider>,
      );
    });
  }

  return {
    openBaseAlert,
  };
};
