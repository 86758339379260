import { tiertopBaseUrl } from "@/config/http";
import { AddressPoints } from "@/types/cpfp";
import { api } from "@/utils/request";

/**
 *
 * @param
 * @return
 */
export const addressPoints = (data: AddressPoints["req"]) =>
  api<AddressPoints["req"], AddressPoints["res"]>({
    url: `/btc/api/address/points`,
    method: "post",
    data,
    baseUrl: tiertopBaseUrl,
  });
