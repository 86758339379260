import { useAppSelector } from "@/redux";
import { Stack, Typography } from "@mui/material";
import {
  WalletAccountGetAccountItem,
  WalletAccountAddressPortfolioDetailItem,
  WalletAccountAddressPortfolioDetail,
  WalletWalletAccountDetail,
} from "@/types/wallet_manager";
import { walletAccountAddressPortfolioDetail } from "@/services/wallet_manager";
import { useIntersectionObserver } from "usehooks-ts";
import { useEffect, useState, Fragment } from "react";
import { CustomTable, ColumnItem } from "@/components/public/table/CustomTable";
import {
  SortBox,
  getSortAttr,
  SortVal,
} from "@/components/public/table/SortComp";
import { UserSortType } from "@/types/service";
interface SortInfo {
  sort: string;
  desc: boolean;
}
export interface GetListProp {
  sortObj?: SortInfo;
  type?: string | number;
  address?: string;
}

type Type = "runes" | "brc20" | "others";
export function MultipleAddressItem(props: {
  type?: Type;
  data: WalletAccountGetAccountItem;
  column: ColumnItem[];
}) {
  const { column, data, type } = props;
  const { address, remark } = data;
  const [list, setList] = useState<WalletAccountAddressPortfolioDetailItem[]>(
    [],
  );
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 0,
  });
  const [sortInfo, setSortInfo] = useState<{ sort: string; desc: boolean }>({
    sort: "",
    desc: false,
  });
  const [loading, setLoading] = useState(false);
  const [isGetList, setIsGetList] = useState(false);
  const [typeInit, setTypeInit] = useState(false);
  const getList = async (props?: GetListProp) => {
    setLoading(true);
    setIsGetList(true);
    const handleProps = props || ({} as any);
    const { sortObj = sortInfo } = handleProps;
    const params: WalletAccountAddressPortfolioDetail["req"] = { address };
    if (type) params["type"] = type;
    const { sort, desc } = sortObj;
    if (sort) {
      params.sort = sort;
      params.desc = desc;
    }
    const res = await walletAccountAddressPortfolioDetail(params).catch((e) => {
      setLoading(false);
      throw e;
    });
    setLoading(false);
    const { items = [] } = res.data;
    setList(items);
  };
  useEffect(() => {
    if (isIntersecting && !isGetList) {
      getList();
    }
  }, [isIntersecting]);
  useEffect(() => {
    if (typeInit) {
      if (isIntersecting) getList();
      else setIsGetList(false);
      console.log("type变化", isIntersecting);
    }

    setTypeInit(true);
  }, [type]);
  const sortChange = (sort: SortVal) => {
    const { key, sort: sortType } = sort;
    const sortInfo = {
      sort: key,
      desc: sortType === UserSortType.desc ? true : false,
    };
    setSortInfo(sortInfo);
    getList({
      sortObj: sortInfo,
    });
  };

  return (
    <Stack ref={ref}>
      <Typography variant="subtitle2" mb={4}>
        {address}
        {remark ? ` (${remark})` : null}
      </Typography>

      <Stack
        border={1}
        borderRadius={"20px"}
        borderColor={"divider"}
        mb={"50px"}
      >
        <CustomTable
          headerPy={1.75}
          column={column}
          sortChange={sortChange}
          list={list}
          loading={loading}
          showEmptyPlaceholder={true}
          tableHeight={"fit-content" as any}
          bodyPy={2}
          bodyOtherStyle={({ isLast }) => ({
            borderBottom: isLast ? "none" : "1px solid #2C3036",
          })}
          headerOtherStyle={{
            borderBottom: "1px solid #2C3036",
          }}
        />
      </Stack>
    </Stack>
  );
}

export default function MultipleAddress(props: {
  type?: Type;
  column: ColumnItem[];
  address: string;
}) {
  const { type, column, address = "" } = props;
  const { address_list } = useAppSelector((state) => state.account);
  return (
    <Stack spacing={3}>
      {address_list.map((item, key) => (
        <Fragment key={key}>
          {address ? (
            item.address === address ? (
              <MultipleAddressItem data={item} column={column} type={type} />
            ) : null
          ) : (
            <MultipleAddressItem data={item} column={column} type={type} />
          )}
        </Fragment>
      ))}
    </Stack>
  );
}
