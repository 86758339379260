import { pxToVw } from "@/utils/style";

const createStyle = (
  size = 14,
  lineHeight = 20,
  fontWeight = 400,
  isToVw = false,
  fontFamily = "PingFangSC-Regular,sans-serif",
): {
  fontSize: React.CSSProperties["fontSize"];
  lineHeight: React.CSSProperties["lineHeight"];
  fontWeight: React.CSSProperties["fontWeight"];
  textTransform: React.CSSProperties["textTransform"];
  fontFamily: React.CSSProperties["fontFamily"];
} => ({
  fontSize: isToVw ? pxToVw(size) : `${size}px`,
  lineHeight: isToVw ? pxToVw(lineHeight) : `${lineHeight}px`,
  fontWeight,
  textTransform: "none",
  fontFamily: fontFamily,
});

export const createTypography = (isToVw = false) => {
  return {
    h1: createStyle(64, 90, 600, isToVw),
    h2: createStyle(36, 50, 600, isToVw),
    h3: createStyle(28, 40, 600, isToVw),
    h24: createStyle(24, 40, 600, isToVw),
    h4: createStyle(20, 28, 600, isToVw),
    h5: createStyle(16, 22, 600, isToVw),
    h6: createStyle(14, 20, 600, isToVw),
    h7: createStyle(44, 62, 600, isToVw),
    h8: createStyle(88, 123, 600, isToVw),
    h9: createStyle(72, 100, 600, isToVw),
    h10: createStyle(40, 56, 600, isToVw),
    h11: createStyle(12, 17, 600, isToVw),
    h12: createStyle(10, 14, 600, isToVw),
    subtitle1: createStyle(24, 33, 400, isToVw),
    subtitle2: createStyle(16, 22, 400, isToVw),
    subtitle3: createStyle(14, 20, 400, isToVw),
    subtitle4: createStyle(20, 28, 400, isToVw),
    body1: createStyle(16, 22, 400, isToVw),
    body2: createStyle(14, 20, 400, isToVw),
    caption: createStyle(12, 17, 400, isToVw),
    button: createStyle(16, 22, 600, isToVw),
    button2: createStyle(14, 20, 600, isToVw),
    value1: createStyle(36, 50, 600, isToVw),
    value2: createStyle(24, 33, 600, isToVw),
    value3: createStyle(20, 28, 600, isToVw),
    value4: createStyle(22, 30, 600, isToVw),
  } as const;
};

const defaultTypography = createTypography();

export default defaultTypography;
