import { Stack } from "@mui/material";
import LoadingButton from "../button/LoadingButton";
import { useState } from "react";
import { handleFollowRemarkLimit } from "@/utils/service";

// 输入备注
interface UpdateRemarkAlertProps {
  requestFun: (param: any) => any; // api请求名,用于保存
  requestParams?: any; //接口需要的额外参数
  requestKey?: string; // 用来保存当前text内容的请求key
  initText?: string; //初始化内容，编辑时使用
  width?: string;
  onSaveSuccess?: (props: { text: string }) => void; // 成功回调
  onSaveFail?: () => void; // 失败回调
  onSaveFinish?: () => void; // 完成回调,不论是否成功
}
export const UpdateRemarkAlert = (props: UpdateRemarkAlertProps) => {
  const {
    initText = "",
    requestFun,
    requestKey = "remark",
    requestParams = {},
    width,
    onSaveSuccess,
    onSaveFail,
    onSaveFinish,
  } = props || {};
  const [text, setText] = useState(initText);
  const [loading, setLoading] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const result = e.target.value;
    // const limitRes = handleFollowRemarkLimit({ remark: result });
    // if (limitRes.code === -1) {
    //   return;
    // }
    setText(result);
  };
  const fetchUpdateRemark = async () => {
    const params = { [requestKey]: text, ...requestParams };
    try {
      setLoading(true);
      const { code } = await requestFun({ items: [params] }); // 调用api
      if (code === 0) {
        // 如果保存成功
        onSaveSuccess && onSaveSuccess({ text });
      }
    } catch (error) {
      console.log("error: ", error);
      onSaveFail && onSaveFail();
    } finally {
      setLoading(false);
      onSaveFinish && onSaveFinish();
    }
  };
  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ width: "100%", px: 2 }}
      spacing={2}
    >
      <textarea
        className="bq-textarea-input "
        value={text}
        style={{
          minWidth: width,
          maxWidth: width,
          height: 137,
          resize: "none",
        }}
        placeholder="Up to 50 characters."
        onChange={handleChange}
        maxLength={50}
      />
      <LoadingButton
        loading={loading}
        onClick={() => {
          fetchUpdateRemark();
        }}
        className="bq-btn3"
        variant={"glow"}
        sx={{ width: "100%" }}
      >
        Submit
      </LoadingButton>
    </Stack>
  );
};
