import { UserSortType } from "@/types/service";
import MyIcon from "@/components/public/my-icon";
import { Stack } from "@mui/material";
import React, { useEffect } from "react";
export type SortBoxPropType =
  | UserSortType.asc
  | UserSortType.default
  | UserSortType.desc;
export interface SortBoxProp {
  children?: React.ReactNode;
  sortChange?: (type: SortBoxPropType) => void;
  type?: SortBoxPropType;
  size?: number;
}

export interface SortVal {
  key: string;
  sort: SortBoxPropType;
}

export function SortComp(props: SortBoxProp) {
  const { type = UserSortType.default, size = 8 } = props;
  return (
    <Stack alignItems={"center"} ml={0.5}>
      <MyIcon
        size={size}
        name="icon-a-lujing2"
        color={type === UserSortType.asc ? "secondary.main" : "#4A4E5B"}
        sx={{
          cursor: "pointer",
        }}
      />
      <MyIcon
        size={size}
        name="icon-lujing"
        color={type === UserSortType.desc ? "secondary.main" : "#4A4E5B"}
        sx={{
          cursor: "pointer",
        }}
      />
    </Stack>
  );
}

export const SortBox = (
  props: SortBoxProp & {
    render?: (SortComp: React.ReactNode, sort: () => void) => React.ReactNode;
  },
) => {
  const { children, render, sortChange, type = UserSortType.default } = props;
  const dict: any = {
    [UserSortType.default]: UserSortType.desc,
    [UserSortType.desc]: UserSortType.asc,
    [UserSortType.asc]: UserSortType.desc,
  };
  const sortChangeFn = () => {
    sortChange?.(dict[type]);
  };
  return (
    <>
      {render ? (
        render(<SortComp {...props} />, sortChangeFn)
      ) : (
        <Stack justifyContent={"flex-start"} width={"fit-content"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            onClick={sortChangeFn}
            display={"inline-flex"}
            sx={{
              cursor: "pointer",
            }}
          >
            {children}
            <SortComp {...props} />
          </Stack>
        </Stack>
      )}
    </>
  );
};

interface GetSortAttrProps {
  key: string;
  cb?: (value: SortVal) => void;
  sort?: SortBoxPropType;
  sortKey?: string;
}
export const getSortAttr = (props: GetSortAttrProps) => {
  const { cb, key, sort, sortKey } = props;
  return {
    sortChange: (value: SortBoxPropType) => {
      cb?.({
        key,
        sort: value,
      });
    },
    type: sortKey === key ? sort : UserSortType.default,
  };
};

export interface SortChangeProps {
  key: string;
  sort: SortBoxPropType;
}
interface SortHookProps {
  sortChange?: (value: SortChangeProps) => void;
  defaultKey?: string;
  defaultSort?: SortBoxPropType;
}
export const useSortHook = ({
  sortChange = undefined,
  defaultKey = "",
  defaultSort = UserSortType.default,
}: SortHookProps) => {
  // 设置变动
  const [sortInfo, setSortInfo] = React.useState<SortChangeProps>({
    key: defaultKey,
    sort: defaultSort,
  });
  // 内容变动
  const dict: any = {
    [UserSortType.default]: UserSortType.desc,
    [UserSortType.desc]: UserSortType.asc,
    [UserSortType.asc]: UserSortType.desc,
  };
  const sortChangeFn = (data: SortChangeProps) => {
    const { key, sort } = data;
    // const handleData = {
    //   sort: dict[sort],
    //   key,
    // };
    console.log("handleData", data);
    sortChange?.(data);
    setSortInfo(data);
  };
  // 获取信息
  const getSortProps = (key: string) => {
    return getSortAttr({
      key,
      cb: sortChangeFn,
      sort: sortInfo.sort,
      sortKey: sortInfo.key,
    });
  };

  return {
    getSortProps,
    sortInfo,
  };
};
