export class Bus {
  events: any;
  constructor() {
    // 用于存储事件及其对应的回调函数
    this.events = {};
  }

  // 订阅事件
  on(event: any, callback: Function) {
    if (!this.events[event]) this.events[event] = [];
    this.events[event].push(callback);
  }

  // 发布事件
  emit(event: any, ...args: any[]) {
    const callbacks = this.events[event];
    if (callbacks) {
      callbacks.forEach((callback: any) => callback.apply(null, args));
    }
  }

  // 取消订阅
  off(event: any, callback: Function) {
    const callbacks = this.events[event];
    if (callbacks)
      this.events[event] = callbacks.filter((cb: any) => cb !== callback);
  }
}

export const bus = new Bus();
