import GlobalStyles from "@mui/material/GlobalStyles";

export default function globalStyles() {
  return (
    <GlobalStyles
      styles={{
        "#__next": {
          background: "0E1014",
        },
        ".ellipsis": {
          whiteSpace: "nowrap" /* 文本不换行 */,
          overflow: "hidden" /* 隐藏超出容器的文本 */,
          textOverflow: "ellipsis" /* 显示省略号 */,
        },
      }}
    />
  );
}
