// @mui
import { useTheme } from "@mui/material/styles";
import { GlobalStyles } from "@mui/material";
import { alpha, borderColor } from "@mui/system";

export default function StyledNotistack() {
  const theme = useTheme();

  const isLight = theme.palette.mode === "light";

  const inputGlobalStyles = (
    <GlobalStyles
      styles={{
        "#__next": {
          backgroundColor: "rgba(49, 49, 49,0.1)",
          ".notistack-MuiContent": {
            color: "#1F2227",
            borderWidth: "2px",
            borderStyle: "solid",
            borderRadius: "16px",
            "&.notistack-MuiContent-default": {
              color: "#fff",
              borderColor: "#1F2227",
              backgroundColor: "#1F2227",
            },
            "&.notistack-MuiContent-success": {
              backgroundColor: "#CFF0D8",
              borderColor: "#11B33C",
            },
            "&.notistack-MuiContent-warning": {
              backgroundColor: "#FFE8CC",
              borderColor: "#FF8D00",
            },
            "&.notistack-MuiContent-info": {
              backgroundColor: "#D4E7FF",
              borderColor: "#2A85FF",
            },
            "&.notistack-MuiContent-error": {
              backgroundColor: "#FDD7D7",
              borderColor: "#F33838",
            },
          },

          ".SnackbarContent-root": {
            width: "100%",
            padding: theme.spacing(1),
            margin: theme.spacing(0.25, 0),
            // boxShadow: theme.customShadows.z8,
            borderRadius: theme.shape.borderRadius,
            color: isLight
              ? theme.palette.common.white
              : theme.palette.grey[800],
            backgroundColor: "red", //isLight ? theme.palette.grey[900] : theme.palette.common.white,
            "&.SnackbarItem-variantSuccess, &.SnackbarItem-variantError, &.SnackbarItem-variantWarning, &.SnackbarItem-variantInfo":
              {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
              },
            [theme.breakpoints.up("md")]: {
              minWidth: 240,
            },
          },
          ".SnackbarItem-message": {
            padding: "0 !important",
            fontWeight: theme.typography.fontWeightMedium,
          },
          ".SnackbarItem-action": {
            marginRight: 0,
            color: theme.palette.action.active,

            "& svg": {
              width: 20,
              height: 20,
            },
          },
        },
      }}
    />
  );

  return inputGlobalStyles;
}
