import { useAppSelector } from "@/redux";
import { useEffect, useState } from "react";
import LoadingButton from "./LoadingButton";
import { Box, Button, Link, Stack, Typography } from "@mui/material";
import ConnectWalletButton from "./ConnectWalletButton";
import { CreateFollowItem, FollowItem } from "@/types";
import {
  createFollowApi,
  getFollowStatusApi,
  removeFollowApi,
  updateFollowRemarkApi,
} from "@/services/follow";
import { handleApiResponse } from "@/utils/tools";
import { useResultAlert } from "../dialog/ResultAlertRevision";
import { UpdateRemarkAlert } from "../UpdateRemark";
import { SnackbarUtils } from "../snackbar/SnackbarProvider";
import CommonModal from "../modal/common-modal";
import router from "next/router";
import { useAlertToast } from "../dialog/AlertToast";
import { formatAddress } from "@/utils/formatAddress";

/**
 * 未登录-点击登录，登录后-关注/取消关注
 * @param
 * @return
 */
interface FollowButtonProps {
  otherFollowMsg?: CreateFollowItem; //当前查看的地址(如果外面要传备注进来)
  otherAddress?: string; //当前查看的地址（只给地址）
  isQuickFocus?: boolean; //快速关注，不弹出备注编辑框
  onlyShowCancel?: boolean; //只显示取消关注（在已关注列表用，明确已知是关注状态，不需要进行查询）
  onlyHasFollow?: boolean; //只显示已关注，点击无反应
  noAutoFetch?: boolean; //不自动查询关注状态,(比如表格不需要单独查询当前的状态，表格会返回)
  sxConnectBtn?: any;
  sxFollowBtn?: any;
  sxUnFollowBtn?: any;
  onSuccessCancel?: () => void; //取消关注之后执行（如：刷新表格数据）
  onSuccess?: () => void; //关注成功之后执行（如：刷新表格数据）
  onRemarkSuccess?: () => void; //关注成功之后执行（如：刷新表格数据）
}
const FollowButton = (props: FollowButtonProps) => {
  const { openResultAlert } = useResultAlert();
  const { openAlertToast } = useAlertToast();
  const { isConnect } = useAppSelector((state) => state.wallet);
  const {
    otherFollowMsg = { address: "", remark: "" },
    otherAddress,
    onlyShowCancel = false,
    isQuickFocus = false,
    noAutoFetch = false,
    onlyHasFollow = false,
    sxConnectBtn,
    sxFollowBtn,
    sxUnFollowBtn,
    onSuccessCancel,
    onSuccess,
    onRemarkSuccess,
  } = props;
  const [isFollow, setIsFollow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openRemark, setOpenRemark] = useState(false);
  const [refresh, setRefresh] = useState(-1); // 手动更新一下当前按钮的状态
  const Address = otherFollowMsg.address || otherAddress;
  const Remark = otherFollowMsg.remark ? `(${otherFollowMsg.remark})` : "";

  useEffect(() => {
    if (Address && ((!onlyShowCancel && !noAutoFetch) || refresh !== -1)) {
      setRefresh(-1);
      fetchIsFollow({
        address: Address,
      });
    }
  }, [Address, onlyShowCancel, noAutoFetch, refresh]);
  const fetchIsFollow = async ({ address }: { address: string }) => {
    const res = await getFollowStatusApi(address);
    handleApiResponse({
      res,
      onSuccess: (data) => {
        setIsFollow(data.is_followed);
      },
    });
  };

  // 打开备注编辑框
  const handleEditRemarkAndCreateFollow = () => {
    setOpenRemark(true);
  };
  // 取消关注
  const handleUnFollow = async () => {
    setLoading(true);
    const res = await openAlertToast({
      msg: `Are you sure you want to remove ${formatAddress({ str: Address as string })} ${Remark}? After removing, you will no longer see data for this address.`, //"确定取消关注bc1q…njhk (x)吗？取消后将不可见该地址数据",
    });
    if (res) {
      await handleCancelFollow({
        address: Address as string,
        successCancelCallback: () => {
          onSuccessCancel && onSuccessCancel();
        },
      });
      setRefresh(Math.random());
    }

    setLoading(false);
  };
  const handleEditRemarkSuccess = () => {
    onRemarkSuccess && onRemarkSuccess();
    setRefresh(Math.random());
    openResultAlert({
      type: "succeed",
      title: "Added successfully! ",
      msgRender: (close) => {
        return (
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            mt={2}
            spacing={0.5}
          >
            {/* 关注成功，你可在【关注地址】中查看 */}
            <Typography variant="body1" color={"text.secondary"}>
              Added successfully! You can view it in the
            </Typography>
            <Typography
              color={"secondary.main"}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                close();
                router.push("/follow");
              }}
            >
              Watchlist
            </Typography>
          </Stack>
        );
      },
    });
  };
  const handleFollowSuccess = () => {
    SnackbarUtils.success(
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        mt={2}
        spacing={0.5}
      >
        <Typography variant="body1" color={"text.secondary"}>
          Added successfully! You can view it in the
        </Typography>
        <Typography
          color={"secondary.main"}
          sx={{ cursor: "pointer" }}
          onClick={() => {
            router.push("/follow");
          }}
        >
          Watchlist
        </Typography>
      </Stack>,
      {
        autoHideDuration: 5000,
      },
    );
  };

  const followBtn = () => {
    let html = <></>;
    if (!isConnect) {
      // 未登录
      html = (
        <ConnectWalletButton
          beforeConnectDom={
            <Button
              variant={"glow"}
              className="bq-btn2"
              sx={{ width: 180, ...sxConnectBtn }}
            >
              + Add to watchlist
            </Button>
          }
        >
          <></>
        </ConnectWalletButton>
      );
    } else if (onlyHasFollow) {
      //仅显示已关注
      html = <OlnyHasFollowBtn />;
    } else if (isFollow || onlyShowCancel) {
      // 已关注, 支持直接取消
      html = (
        <LoadingButton
          loading={loading}
          variant={"grey"}
          className="bq-btn2"
          sx={{ width: 116, sxFollowBtn }}
          onClick={handleUnFollow}
        >
          {/* 取消关注 btn*/}
          Remove
        </LoadingButton>
      );
    } else {
      // 未关注
      html = (
        <LoadingButton
          loading={loading}
          variant={"glow"}
          className="bq-btn2"
          sx={{ ...sxUnFollowBtn }}
          childrenProps={{ sx: { width: 140 } }}
          onClick={async () => {
            if (isQuickFocus) {
              setLoading(true);
              // 仅关注
              await handleAddFollow({
                createFollowItem: [otherFollowMsg],
                successFollowCallback: () => {
                  handleFollowSuccess();
                  onSuccess && onSuccess();
                },
              });
              setRefresh(Math.random());
              setLoading(false);
            } else {
              // 编辑备注且关注
              handleEditRemarkAndCreateFollow();
            }
          }}
        >
          {/* +关注 */}+ Add to watchlist
        </LoadingButton>
      );
    }
    return html;
  };

  return (
    <Stack>
      {followBtn()}
      <CommonModal
        open={openRemark}
        headerTitle="Address label" //"地址备注"
        onClose={() => setOpenRemark(false)}
      >
        <UpdateRemarkAlert
          requestFun={createFollowApi}
          requestParams={{ address: Address }}
          onSaveSuccess={handleEditRemarkSuccess}
          onSaveFinish={() => setOpenRemark(false)}
        />
      </CommonModal>
    </Stack>
  );
};
export default FollowButton;

// 单纯新增关注（不编辑备注）
export const handleAddFollow = async (props: {
  createFollowItem: CreateFollowItem[];
  successFollowCallback?: () => void;
}) => {
  const { createFollowItem, successFollowCallback } = props;
  try {
    const res = await createFollowApi({ items: createFollowItem });
    handleApiResponse({
      res,
      onSuccess: () => {
        successFollowCallback && successFollowCallback();
      },
    });
  } catch (error: any) {
    console.log("error: ", error);
    // 接口有统一抛出错误，如果不需要特殊处理错误，这边可不加
    // SnackbarUtils.error(error.msg || "Focus on failure");
  }
};

// 取消关注
export const handleCancelFollow = async (props: {
  address: string;
  successCancelCallback?: () => void; //取消关注之后执行（如：刷新表格数据）
}) => {
  const { address, successCancelCallback } = props;
  try {
    const params = {
      address: address,
    };
    const res = await removeFollowApi(params);
    handleApiResponse({
      res,
      onSuccess: () => {
        successCancelCallback && successCancelCallback();
      },
    });
  } catch (error: any) {
    console.log("error: ", error);
    // 接口有统一抛出错误，如果不需要特殊处理错误，这边可不加
    // SnackbarUtils.error(error.msg || "Focus on failure");
  }
};

export const OlnyHasFollowBtn = (props?: { sxFollowBtn?: any }) => {
  const { sxFollowBtn } = props || {};
  return (
    <Button
      variant={"grey"}
      className="bq-btn2"
      sx={{ width: 116, ...sxFollowBtn, cursor: "default" }}
    >
      Added
    </Button>
  );
};
