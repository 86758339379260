import { Stack, SxProps, Typography, Skeleton } from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useEffect, useState, Fragment } from "react";
import { UserSortType } from "@/types/service";
import Empty from "@/components/public/empty";
import {
  SortBoxPropType,
  SortBox,
  useSortHook,
  SortBoxProp,
} from "@/components/public/table/SortComp";

interface VirtualScrollProps {
  viewHeight: number;
  viewItemHeight: number;
  ref?: HTMLElement;
  preloadNumber: number;
  removeHeight?: number;
  total?: number;
}
interface VirtualScrollRes {
  endHeight: number;
  position: {
    start: number;
    end: number;
  };
  showStart: boolean;
  startHeight: number;
}
export const useVirtualScroll = (
  props: VirtualScrollProps,
): VirtualScrollRes => {
  const {
    viewHeight,
    viewItemHeight,
    ref,
    preloadNumber,
    removeHeight = 0,
    total = Infinity,
  } = props;
  const [topHeight, setTopHeight] = useState(0);
  const [position, setPosition] = useState({
    start: 0,
    end: Infinity,
  });
  const [endHeight, setEndHeight] = useState(0);

  const scrollChange = () => {
    if (!ref) return;
    const computeHeight =
      ref.scrollTop - removeHeight < 0 ? 0 : ref.scrollTop - removeHeight;
    const startNum = Math.floor(computeHeight / viewItemHeight);
    const endNum = Math.ceil((computeHeight + viewHeight) / viewItemHeight);
    setTopHeight(startNum * viewItemHeight);
    setPosition({
      start: startNum,
      end: endNum,
    });
    const max = total - endNum > preloadNumber ? preloadNumber : total - endNum;
    const handleMax = Math.max(max, 0);
    setEndHeight(handleMax * viewItemHeight);
  };

  useEffect(() => {
    if (ref) {
      ref.addEventListener("scroll", scrollChange);
      return () => {
        ref.removeEventListener("scroll", scrollChange);
      };
    }
  }, [ref, total]);
  return {
    endHeight,
    position,
    showStart: !!ref,
    startHeight: topHeight,
  };
};

export interface ColumnItem<R = any> {
  label: string;
  key?: string;
  render?: (item: R, rowIndex: number, column: ColumnItem) => React.ReactNode;
  align?: "left" | "right";
  width?: number;
  change?: (data?: any) => void;
  pl?: number | string;
  pr?: number | string;
  renderHeader?: (
    config: ColumnItem,
    label: string,
    sortConfig: SortBoxProp,
  ) => React.ReactNode;
  sortChange?: (data?: any) => void;
  headerVariant?: any;
  bodyVariant?: any;
  headerColor?: string;
  bodyColor?: string;
  sort?: boolean;
  py?: number | string;
}

type bodyOtherStyleTypes = (option: {
  isLast: boolean;
  isFirst: boolean;
  index: number;
}) => React.CSSProperties;
interface Props<R = any> {
  list: R[]; // 数据列表
  column: ColumnItem[]; // column配置
  bodyPy?: number; // body的上下边距
  headerPy?: number; // header的上下边距
  boxSx?: SxProps; // 盒子的sx
  bodyOtherStyle?: React.CSSProperties | bodyOtherStyleTypes; // body=>tr的其他style
  headerOtherStyle?: React.CSSProperties; // header=>tr的其他style
  showEmptyPlaceholder?: boolean; // 是否显示空占位
  showEmptyPlaceholderHeight?: number; // 是否显示空占位高度
  showEmptyPlaceholderMsg?: string; // 是否显示空占位msg
  tableHeight?: number; // 用于开启虚拟滚动时使用
  bodyTdHeight?: number; // 用于开启虚拟滚动时使用
  endDom?: React.ReactNode; // 表格尾部内容
  openVirtual?: boolean; // 是否开启虚拟滚动
  loadingType?: "all" | "pullUp"; // 加载类型
  loading?: boolean; // 是否处于加载中
  loadingPlaceholderLen?: number; // 加载样式的行数
  secondaryRowHeight?: number; // 次要行高
  secondaryRowRender?: (
    item: R,
    rowIndex: number,
    column: ColumnItem[],
  ) => React.ReactNode; // 次要行的render
  columnItemHeight?: number; // 主要内容行高度
  totalListNum?: number;
  preloadNumber?: number;
  sortChange?: (data: { key: string; sort: SortBoxPropType }) => void;
  defaultKey?: string;
  defaultSort?: SortBoxPropType;
  sortIconSize?: number;
}

export function CustomTable<R = any>(props: Props<R>) {
  const {
    list = [],
    column = [],
    headerPy = 0,
    bodyPy = 0,
    boxSx = {},
    bodyOtherStyle = {},
    headerOtherStyle = {},
    showEmptyPlaceholder = false,
    showEmptyPlaceholderHeight = 500,
    showEmptyPlaceholderMsg = "No data",
    tableHeight = "100%",
    bodyTdHeight = "fit-content",
    endDom = null,
    openVirtual = false,
    loadingType = "all",
    loading = false,
    loadingPlaceholderLen = 5,
    secondaryRowHeight = 0,
    secondaryRowRender = null,
    columnItemHeight = "fit-content",
    totalListNum = Infinity,
    preloadNumber = 10,
    sortChange,
    defaultKey,
    defaultSort,
    sortIconSize = 8,
  } = props;

  const totalWidth = column.reduce((pre, cur) => {
    return pre + (cur.width || 0);
  }, 0);

  const theme = useTheme();
  const getPx = (value: string | number) => {
    if (typeof value === "string") return value;
    return theme.spacing(value);
  };

  // 虚拟滚动部分处理
  const tableStyle: any = {};
  if (typeof tableHeight === "number") {
    tableStyle.height = tableHeight;
    tableStyle.overflowY = "auto";
  }
  const [boxRef, setBoxRef] = useState<any>(null);
  const [headerRef, setHeaderRef] = useState<any>(null);
  const { endHeight, position, showStart, startHeight } = useVirtualScroll({
    ref: boxRef,
    viewHeight: tableHeight as any,
    viewItemHeight: Number(columnItemHeight) + Number(secondaryRowHeight),
    preloadNumber: preloadNumber,
    removeHeight: headerRef ? headerRef.clientHeight : 11,
    total: totalListNum,
  });
  const handleList = openVirtual
    ? list.slice(position.start, position.end)
    : list;

  const { getSortProps } = useSortHook({
    sortChange,
    defaultKey,
    defaultSort,
  });
  return (
    <Stack
      className="scrollbar"
      ref={(ref) => {
        if (openVirtual) setBoxRef(ref);
      }}
      sx={{
        ...boxSx,
        ...tableStyle,
        width: totalWidth,
      }}
    >
      <table
        style={{
          borderCollapse: "collapse",
        }}
      >
        <colgroup>
          {column.map((item, index) => {
            const { width, align = "left" } = item;
            const style: any = {
              textAlign: align,
            };
            if (width) style.width = width;
            return <col style={style} key={index} />;
          })}
        </colgroup>
        <thead>
          <tr ref={(ref) => setHeaderRef(ref)}>
            {column.map((item, columnIndex) => {
              const {
                headerVariant = "caption",
                headerColor = "text.secondary",
                align = "left",
                pr = 0,
                pl = 0,
                sort,
                label,
                key,
              } = item;
              const style: any = {};
              if (pr || pl || headerPy) {
                style.padding = `${getPx(headerPy)} ${getPx(pr)} ${getPx(headerPy)} ${getPx(pl)}`;
              }
              const getTextDom = () => (
                <Typography
                  component={"div"}
                  // width={"100%"}
                  variant={headerVariant}
                  color={headerColor}
                  textAlign={align}
                >
                  {label}
                </Typography>
              );
              return (
                <th
                  style={{
                    ...style,
                    ...headerOtherStyle,
                  }}
                  key={columnIndex}
                >
                  {item.renderHeader ? (
                    item.renderHeader(item, item.label, getSortProps(key || ""))
                  ) : (
                    <>
                      {sort ? (
                        <SortBox
                          {...getSortProps(key || "")}
                          size={sortIconSize}
                        >
                          {getTextDom()}
                        </SortBox>
                      ) : (
                        getTextDom()
                      )}
                    </>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {loadingType === "all" && loading ? (
            <>
              {new Array(loadingPlaceholderLen).fill("").map((_, index) => {
                return (
                  <tr key={index}>
                    {column.map((columnItem, columnIndex) => {
                      const { pr = 0, pl = 0 } = columnItem;
                      const style: any = {};
                      if (pr || pl || bodyPy)
                        style.padding = `${getPx(bodyPy)} ${getPx(pr)} ${getPx(bodyPy)} ${getPx(
                          pl,
                        )}`;
                      const align: any = {
                        left: "flex-start",
                        right: "flex-end",
                      };
                      const bodyOtherStyleRes =
                        typeof bodyOtherStyle === "function"
                          ? bodyOtherStyle({
                              isFirst: index === 0,
                              isLast: index === loadingPlaceholderLen - 1,
                              index,
                            })
                          : bodyOtherStyle;
                      return (
                        <td
                          key={columnIndex}
                          style={{
                            ...style,
                            ...bodyOtherStyleRes,
                          }}
                        >
                          <Stack
                            width={"100%"}
                            alignItems={align[columnItem.align || ""]}
                          >
                            <Skeleton width={"80%"} height={20} />
                          </Stack>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </>
          ) : (
            <>
              {showStart ? (
                <tr>
                  <td style={{ height: startHeight }}></td>
                </tr>
              ) : null}
              {handleList.map((listItem, index) => {
                const startItemPl = column[0].pl || 0;
                const endItemPr = column[column.length - 1].pr || 0;
                let secondaryRowPadding = "0";
                if (startItemPl || endItemPr)
                  secondaryRowPadding = `0 ${getPx(startItemPl)} 0 ${getPx(endItemPr)}`;
                const handleIndex = showStart ? index + position.start : index;
                return (
                  <Fragment key={index}>
                    <tr
                      style={{
                        height: columnItemHeight,
                      }}
                    >
                      {column.map((columnItem, columnIndex) => {
                        const {
                          render,
                          bodyVariant = "body2",
                          bodyColor = "text.primary",
                          align = "left",
                          key = "",
                          pr = 0,
                          pl = 0,
                          py = 0,
                        } = columnItem;
                        const style: any = {};
                        if (pr || pl || bodyPy)
                          style.padding = `${getPx(py || bodyPy)} ${getPx(pr)} ${getPx(py || bodyPy)} ${getPx(
                            pl,
                          )}`;
                        const bodyOtherStyleRes =
                          typeof bodyOtherStyle === "function"
                            ? bodyOtherStyle({
                                isFirst: index === 0,
                                isLast: index === handleList.length - 1,
                                index,
                              })
                            : bodyOtherStyle;
                        return (
                          <td
                            style={{
                              ...style,
                              ...bodyOtherStyleRes,
                            }}
                            key={columnIndex}
                          >
                            {columnItem.render ? (
                              columnItem.render(
                                listItem,
                                handleIndex,
                                columnItem,
                              )
                            ) : (
                              <Typography
                                component={"div"}
                                width={"100%"}
                                variant={bodyVariant}
                                color={bodyColor}
                                textAlign={align}
                                sx={{
                                  wordBreak: "break-all",
                                }}
                              >
                                {(listItem as any)[key]}
                              </Typography>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                    {secondaryRowRender ? (
                      <tr>
                        <td
                          colSpan={column.length}
                          style={{
                            height: secondaryRowHeight,
                            padding: secondaryRowPadding,
                          }}
                        >
                          {secondaryRowRender(listItem, handleIndex, column)}
                        </td>
                      </tr>
                    ) : null}
                  </Fragment>
                );
              })}

              {openVirtual && endHeight ? (
                <tr>
                  <td style={{ height: endHeight }}></td>
                </tr>
              ) : null}
            </>
          )}
        </tbody>
      </table>
      {showEmptyPlaceholder && list.length === 0 && !loading ? (
        <Stack height={showEmptyPlaceholderHeight}>
          <Empty width={90} height={73} msg={showEmptyPlaceholderMsg} />
        </Stack>
      ) : null}
      {list.length > 0 && endDom && !loading ? endDom : null}
    </Stack>
  );
}
