import Image from "@/components/public/image";
import { Stack, Typography, Button } from "@mui/material";
import PageIndication from "@/components/public/page-indication/pageIndication";
import Tabs from "@/components/public/tabs";
import { useEffect, useState, useRef } from "react";
import { useResultAlert } from "@/components/public/dialog/ResultAlertRevision";
import { useAlertToast } from "@/components/public/dialog/AlertToast";
import Select from "@/components/public/select";
import { ListItem, TabsItem } from "@/types/components";
import FaceTabs from "@/components/public/tabs/FaceTabs";
import WalletBox from "@/components/pc/v1/wallet/WalletBox";
import { CustomTable, ColumnItem } from "@/components/public/table/CustomTable";
import { marketList, runesOwnedList } from "@/services/runes";
import { MarketList, MarketListItem } from "@/types/runes";
import { useRouter } from "next/router";
import { useUpdateTime } from "./hook";
import { UserSortType } from "@/types/service";
import {
  SortBox,
  getSortAttr,
  SortVal,
} from "@/components/public/table/SortComp";
import { useTheme } from "@mui/material/styles";
import TickImage from "@/components/public/image/TickImage";
import { sliceNumber, multipliedBy } from "@/utils/number";
import PlatformItem from "./PlatformItem";
import { createTypography } from "@/theme/typography";
import AssetTrend from "./AssetTrend";
import AssetDist from "./AssetDist";
import { useAppSelector } from "@/redux";
// import NoLogin from "./NoLogin";
import { useSearchAddress } from "@/hooks/router";
import { PropertyTypes } from "@/config/components";
import {
  WalletAccountGetAccountItem,
  WalletAccountAddressPortfolioDetailItem,
  WalletAccountAddressPortfolioDetail,
  WalletWalletAccountDetail,
} from "@/types/wallet_manager";
import {
  walletAccountAddressPortfolioDetail,
  walletWalletAccountDetail,
} from "@/services/wallet_manager";
import { formatNumberToM } from "@/utils/formatNumber";
import MultipleAddress from "./MultipleAddress";
import { useAddressGroupInfo } from "@/components/pc/v1/wallet/hook";
import { dotswapUrl, toolsUrl } from "@/config/http";
import { satoshisToAmount, formatUsd } from "@/utils/bitcoin";
import { useUpdateCb } from "@/components/pc/v1/wallet/hook";

interface SortInfo {
  sort: string;
  desc: boolean;
}
export interface GetListProp {
  sortObj?: SortInfo;
  type?: string | number;
  address?: string;
}
// Market Cap，Volume (24h)
export const volumeData = (props: { num: number | string }) => {
  const { num } = props || {};
  try {
    const num1 = formatUsd({
      value: num,
    });
    return num1 ? `$${num1}` : "-";
  } catch (e) {
    console.log("volumeData", e);
    return "--";
  }
};
const goSwapUrl = (
  row: WalletAccountAddressPortfolioDetailItem,
  isOpen: boolean = true,
) => {
  const dict: any = {
    runes: "R",
    brc20: "B",
  };
  let str = dict[row.type.toLowerCase()];
  let handleUrl = "";
  if (str) {
    str += `_BTC_${row.tick}`;
    handleUrl = `${dotswapUrl}/swap#${str}`;
    if (isOpen) {
      window.open(handleUrl);
      return handleUrl;
    }
  }
  return handleUrl;
};
const goMint = (row: WalletAccountAddressPortfolioDetailItem) => {
  window.open(`${toolsUrl}/bq/home#${row.tick}`);
};

export function useTableColumn(updateTime = "") {
  const getIsBtc = (row: WalletAccountAddressPortfolioDetailItem) =>
    !(row.tick.toLowerCase() === "btc" && row.tick.toLowerCase() === "btc");

  const getSliceNumber = (number: string | number) => {
    if (number) {
      return sliceNumber(Number(number), 8);
    }
    return number;
  };
  const getPlatformList = (tick: WalletAccountAddressPortfolioDetailItem) => {
    const list = [];
    const {
      okx = "",
      magic_eden = "",
      unisat = "",
      dotswap = "",
    } = tick?.floor_price || ({} as any);
    const okxItem = {
      img: "/assets/logo-okx.png",
      num: getSliceNumber(okx || "0") || "--",
      icon: tick?.symbol || "",
      href: `https://www.okx.com/zh-hans/web3/marketplace/runes/token/${tick?.tick}/${tick?.tick_id}`,
    };
    const magicEdenItem = {
      img: "/assets/magic_eden_icon.png",
      num: getSliceNumber(magic_eden || "0") || "--",
      icon: tick?.symbol || "",
      href: `https://magiceden.io/runes/${tick?.tick}`,
    };
    const unisatItem = {
      img: "/assets/unisat.png",
      num: getSliceNumber(unisat || "0"),
      icon: tick?.symbol || "",
      href: `https://unisat.io/runes/market?tick=${tick?.tick}`,
    };
    const dotswapItem = {
      img: "/assets/dotswap_icon.png",
      num: getSliceNumber(dotswap || "0"),
      icon: tick?.symbol || "",
      href: goSwapUrl(tick, false),
    };
    if (dotswap) list.push(dotswapItem);
    if (okx) list.push(okxItem);
    if (magic_eden) list.push(magicEdenItem);
    if (unisat) list.push(unisatItem);
    // if (!okx && !magic_eden && !unisat && !dotswap)
    //   list.push(okxItem, magicEdenItem, unisatItem, dotswapItem);
    return list;
  };
  const { searchAddress } = useSearchAddress({});
  const typography = createTypography();
  const btnSx = {
    width: 88,
    height: 38,
    borderRadius: "20px",
    ...typography.subtitle3,
  };
  const column: ColumnItem[] = [
    {
      label: "Type",
      render: (row: WalletAccountAddressPortfolioDetailItem) => (
        <Typography variant={"subtitle3"}>{row.type}</Typography>
      ),
      width: 73 + 24,
      pl: 3,
    },
    {
      label: "Token",
      render: (row: WalletAccountAddressPortfolioDetailItem) => (
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <TickImage
            url={row.icon_url}
            name={row.tick}
            symbol={row.symbol}
            size={26}
            hasRadius={true}
          />
          <Typography
            variant="subtitle3"
            sx={{
              wordBreak: "break-all",
            }}
          >
            {row.tick}
          </Typography>
        </Stack>
      ),
      width: 174 + 8,
      pl: 1,
      pr: 1.5,
    },
    {
      label: "Balance",
      key: "amount",
      render: (row: WalletAccountAddressPortfolioDetailItem) => (
        <Typography
          variant={"subtitle3"}
          sx={{
            wordBreak: "break-word",
          }}
        >
          {row.type === "BTC"
            ? satoshisToAmount(row.amount)
            : sliceNumber(row.amount, 2)}
        </Typography>
      ),
      width: 102 + 8,
      pl: 1,
      pr: 1,
    },
    {
      label: "Value",
      sort: true,
      key: "usd_assets",
      render: (row: WalletAccountAddressPortfolioDetailItem) => (
        <Stack
          width={"100%"}
          sx={{
            wordBreak: "break-word",
          }}
        >
          <Typography variant="subtitle3">
            {satoshisToAmount(row.btc_assets)} ₿
          </Typography>
          <Typography variant="caption" color="text.secondary">
            (${sliceNumber(row.usd_assets, 2)})
          </Typography>
        </Stack>
      ),
      width: 115,
      pl: 0.5,
      pr: 0.5,
    },
    {
      label: "Market Cap",
      key: "market_cap_usd",
      sort: true,
      render: (row: WalletAccountAddressPortfolioDetailItem) => (
        <Typography variant="subtitle3">
          {!getIsBtc(row) ? "-" : volumeData({ num: row.market_cap_usd })}
        </Typography>
      ),
      width: 90,
      pl: 1,
      pr: 1,
    },
    {
      label: "Holders",
      key: "holders",
      render: (row: WalletAccountAddressPortfolioDetailItem) => (
        <Typography variant="subtitle3">
          {!getIsBtc(row) ? "-" : row.holders}
        </Typography>
      ),
      sort: true,
      width: 80,
      pl: 1,
      pr: 1,
    },
    {
      label: "Change",
      key: "price_change_24h",
      sort: true,
      render: (row: WalletAccountAddressPortfolioDetailItem) => (
        <Typography
          variant="subtitle3"
          sx={{
            color: `${
              +row.price_change_24h > 0 ? "success.main" : "error.main"
            }`,
          }}
        >
          {row.price_change_24h
            ? sliceNumber(multipliedBy(row.price_change_24h, 100), 3) + "%"
            : "-"}
        </Typography>
      ),
      width: 80,
      pl: 1,
      pr: 1,
    },
    {
      label: "Volume (24h)",
      key: "volume_usd_24h",
      renderHeader: (c, r, config) => (
        <SortBox
          {...config}
          render={(dom, sort) => (
            <Stack justifyContent={"flex-start"} width={"fit-content"}>
              <Stack
                onClick={sort}
                sx={{
                  cursor: "pointer",
                  display: "inline-flex",
                }}
              >
                <Typography
                  variant="caption"
                  color={"text.secondary"}
                  textAlign={"left"}
                >
                  Volume
                </Typography>
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography
                    variant="caption"
                    color={"text.secondary"}
                    textAlign={"left"}
                  >
                    (24h)
                  </Typography>
                  {dom}
                </Stack>
              </Stack>
            </Stack>
          )}
        ></SortBox>
      ),
      render: (row: WalletAccountAddressPortfolioDetailItem) => (
        <Typography variant="subtitle3">
          {row.tick.toLowerCase() === "btc" && row.tick.toLowerCase() === "btc"
            ? "-"
            : volumeData({ num: row.volume_usd_24h })}
        </Typography>
      ),
      width: 80,
      pl: 1,
      pr: 1,
    },
    {
      label: "Floor Price",
      renderHeader: () => (
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography
            variant="subtitle3"
            sx={{
              fontWeight: 600,
              fontSize: `14px`,
              color: `#FFFFFF`,
              background: "linear-gradient(90deg, #BF60F2 0%, #01CDFF 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Floor Price
          </Typography>
          <Typography variant="subtitle3" color={"#B4B4B6"}>
            Updated: {updateTime}
          </Typography>
        </Stack>
      ),
      render: (
        row: WalletAccountAddressPortfolioDetailItem,
        rowIndex: number,
      ) => {
        const list = getPlatformList(row);
        return (
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              width={"100%"}
              pl={"2px"}
              sx={{
                overflow: "hidden",
              }}
            >
              {/* <EntrancePart data={dealFloorPrice({ row })} /> */}
              {list.length > 0
                ? list.map((item, index) => (
                    <Stack
                      key={index}
                      sx={{
                        // width: "90px",
                        pr: "8px",
                        mr: index === list.length - 1 ? 0 : "14px",
                        position: "relative",
                        "&::after": {
                          content: "''",
                          display:
                            index === getPlatformList(row).length - 1
                              ? "none"
                              : "block",
                          height: "100%",
                          width: "1px",
                          position: "absolute",
                          right: 0,
                          top: 0,
                          background: "#2C3036",
                          boxSizing: "border-box",
                        },
                      }}
                    >
                      <PlatformItem
                        img={item.img}
                        num={
                          Number(item.num) < 1
                            ? sliceNumber(Number(item.num), 5)
                            : sliceNumber(Number(item.num), 2)
                        }
                        icon={item.icon}
                        href={item.href}
                      />
                    </Stack>
                  ))
                : "-"}
            </Stack>
            <Stack spacing={1} pr={"12px"} py={2}>
              {!searchAddress && row.type.toLowerCase() !== "btc" ? (
                <Button
                  variant={"purple"}
                  onClick={() => {
                    const type = row.type.toLowerCase();
                    if (type === "runes") {
                      window.open(
                        `${dotswapUrl}/market/operation?tick=${row?.tick}&tick_id=${row?.tick_id}`,
                      );
                    }
                    if (type === "brc20") {
                      window.open(
                        `https://www.okx.com/zh-hans/web3/marketplace/inscription/ordinals/token/${row.tick}`,
                      );
                    }
                  }}
                  sx={btnSx}
                >
                  Trade
                </Button>
              ) : null}

              {row?.floor_price?.dotswap &&
              row.type.toLowerCase() !== "btc" &&
              !searchAddress ? (
                <Button
                  variant={"glow"}
                  onClick={() => goSwapUrl(row)}
                  sx={btnSx}
                >
                  Swap
                </Button>
              ) : null}
              {row.mintable ? (
                <Button variant={"cyan"} onClick={() => goMint(row)} sx={btnSx}>
                  Mint
                </Button>
              ) : null}
            </Stack>
          </Stack>
        );
      },
      width: 558 + 8,
      pr: 3,
      py: "0px",
    },
  ];
  return {
    column,
  };
}

const useList = (props: { setIsInvalid?: (value: boolean) => void }) => {
  const { setIsInvalid = undefined } = props;
  // 类型选择
  const { address } = useAppSelector((state) => state.wallet);
  const { addressList } = useAddressGroupInfo();
  const selectList: ListItem[] = addressList;
  const [selectAddress, setSelectAddress] = useState("");
  const selectAddressChange = (address: string | number) => {
    const handleAddress = address + "";
    setSelectAddress(handleAddress);
    getTotalList({
      address: handleAddress,
    });
  };
  // 精准地址选择
  const [type, setType] = useState<any>();
  const propertyList: ListItem[] = PropertyTypes;
  const typeChange = (value: string | number) => {
    setType(value);
    console.log("typeChange", value);
    getTotalList({ type: value });
  };

  const [list, setList] = useState<WalletAccountAddressPortfolioDetailItem[]>(
    [],
  );

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortInfo, setSortInfo] = useState<{ sort: string; desc: boolean }>({
    sort: "usd_assets",
    desc: true,
  });
  const handleGetListParams = (params: any, dataProps?: GetListProp) => {
    const data = dataProps || ({} as any);
    const { sortObj = sortInfo, address = undefined } = data;
    const handleType = typeof data.type === "undefined" ? type : data.type;
    const handleAddress =
      typeof address === "undefined" ? selectAddress : address;
    const { sort, desc } = sortObj;
    if (sort) {
      params.sort = sort;
      params.desc = desc;
    }
    if (handleType) params["type"] = handleType;
    if (handleAddress) params["address"] = handleAddress;
  };

  const getAccountList = async (props?: GetListProp) => {
    setLoading(true);
    const params: WalletWalletAccountDetail["req"] = {};
    handleGetListParams(params, props);
    const res = await walletWalletAccountDetail(params).catch(() => null);
    setLoading(false);
    if (!res) return;
    const { items = [] } = res.data;
    setList(items);
  };

  const { searchAddress } = useSearchAddress({});
  const walletBoxRef = useRef<any>(null);
  const getAddressList = async (props?: GetListProp) => {
    setLoading(true);
    const params: WalletAccountAddressPortfolioDetail["req"] = {
      address: searchAddress,
    };
    handleGetListParams(params, props);
    const res = await walletAccountAddressPortfolioDetail(params).catch((e) => {
      throw e;
    });
    setLoading(false);
    if (!res) return;
    if (res.code === 10170006) {
      walletBoxRef.current?.setIsInvalid?.(true);
      return;
    }
    walletBoxRef.current?.setIsInvalid?.(false);
    const { items = [] } = res.data;
    setList(items);
  };

  const getTotalList = (props?: GetListProp) => {
    if (searchAddress) {
      getAddressList(props);
      return;
    }
    if (props?.type === "1" || tabActive === "1") {
      getAccountList(props);
    }
  };
  useUpdateCb({
    updateCb: getTotalList,
  });
  const pageChange = () => {
    getTotalList();
  };
  const sortChange = (sort: SortVal) => {
    const { key, sort: sortType } = sort;
    const sortInfo = {
      sort: key,
      desc: sortType === UserSortType.desc ? true : false,
    };
    setSortInfo(sortInfo);
    getTotalList({
      sortObj: sortInfo,
    });
  };

  // 表格配置部分
  const { updateTime } = useUpdateTime();
  const router = useRouter();
  const getP = (len: number) => {
    if (len === 4)
      return {
        pr: "3px",
        mr: "8px",
      };
    if (len === 3)
      return {
        pr: "30px",
        mr: "27px",
      };
  };
  const typography = createTypography();
  const btnSx = {
    width: 88,
    height: 38,
    borderRadius: "20px",
    ...typography.subtitle3,
  };
  const { column } = useTableColumn(updateTime);
  // tabs
  const tabList: TabsItem[] = [
    { label: "View by token", value: "1" },
    { label: "View by address", value: "2" },
    // { label: "View by address", value: "3" },
  ];
  const [tabActive, setTabActive] = useState<TabsItem["value"]>(
    tabList[0].value,
  );
  const tabActiveChange = (value: string) => {
    setTabActive(value);
    console.log("value", value, value === "1");
    if (value === "1") {
      console.log(999);
      getAccountList();
    }
  };

  return {
    list,
    total,
    loading,
    sortChange,
    pageChange,
    sort: sortInfo,
    column,
    type,
    setType: typeChange,
    propertyList,
    selectList,
    selectAddress,
    selectAddressChange,
    tabList,
    tabActive,
    setTabActive: tabActiveChange,
    getAddressList,
    getAccountList,
    walletBoxRef,
  };
};

interface Props {
  setIsInvalid?: (value: boolean) => void;
}

export default function Home(props: Props) {
  const { setIsInvalid } = props;
  const { openResultAlert } = useResultAlert();
  const { openAlertToast } = useAlertToast();
  const boxAttr = {
    width: `calc(50% - 18.5px)`,
    height: 416,
    border: 1,
    borderColor: "#2C3036",
    borderRadius: "20px",
  };

  const { address } = useAppSelector((state) => state.wallet);

  const {
    column,
    list,
    loading,
    pageChange,
    sortChange,
    total,
    type,
    setType,
    propertyList,
    selectList,
    selectAddress,
    selectAddressChange,
    tabList,
    tabActive,
    setTabActive,
    getAddressList,
    getAccountList,
    sort,
    walletBoxRef,
  } = useList({
    setIsInvalid,
  });
  const { searchAddress } = useSearchAddress({
    cb: () => {
      getAccountList();
    },
    searchCb: () => {
      getAddressList();
    },
  });
  // useEffect(() => {
  //   if (searchAddress) getAddressList();
  // }, [searchAddress]);
  // useEffect(() => {
  //   if (!searchAddress && address) getAccountList();
  // }, [address]);

  return (
    <>
      <WalletBox ref={walletBoxRef}>
        <Stack alignItems={"flex-end"} height={44}>
          {!searchAddress ? (
            <Select
              list={selectList}
              value={selectAddress}
              placeholder=""
              change={(value) => selectAddressChange(value)}
            />
          ) : null}
        </Stack>
        <Stack mb={"66px"}>
          <Stack width={"100%"} spacing={"20px"} direction={"row"} mt={2}>
            <Stack {...boxAttr}>
              <AssetTrend address={selectAddress} />
            </Stack>
            <Stack {...boxAttr}>
              <AssetDist address={selectAddress} />
            </Stack>
          </Stack>
        </Stack>
        <Stack mb={"36px"}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h3">Asset Details</Typography>
            <Stack direction={"row"} spacing={"20px"}>
              <Select
                width={180}
                list={propertyList}
                value={type}
                placeholder="请选择资产"
                change={(value) => setType(value)}
              />
              {!searchAddress ? (
                <>
                  <FaceTabs
                    list={tabList}
                    value={tabActive}
                    mode={"padding"}
                    change={(value) => setTabActive(value)}
                  />
                </>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
        {tabActive === "1" ? (
          <Stack
            border={1}
            borderRadius={"20px"}
            borderColor={"divider"}
            mb={"50px"}
          >
            <CustomTable
              headerPy={1.75}
              loadingPlaceholderLen={20}
              defaultKey={sort.sort}
              defaultSort={sort.desc ? UserSortType.desc : UserSortType.asc}
              column={column}
              sortChange={sortChange}
              list={list}
              loading={loading}
              showEmptyPlaceholder={true}
              tableHeight={"fit-content" as any}
              bodyPy={2}
              bodyOtherStyle={({ isLast }) => ({
                borderBottom: isLast ? "none" : "1px solid #2C3036",
              })}
              headerOtherStyle={{
                borderBottom: "1px solid #2C3036",
              }}
            />
          </Stack>
        ) : (
          <MultipleAddress
            type={type}
            column={column}
            address={selectAddress}
          />
        )}
      </WalletBox>
    </>
  );
}
