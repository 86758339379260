import { walletApi, walletDict } from "dotswap-wallet";
import { useResultAlert } from "@/components/public/dialog/ResultAlertRevision";
import { useAppDispatch, useAppSelector } from "@/redux";
import { checkIsBTCAddress } from "@/utils/bitcoin";
import {
  setPublicKey,
  setBTCPublicKey,
  clearBalance,
  setAccounts,
  setAddress,
  setBTCAddress,
  setIsConnect,
  setNetwork,
  setPrivateKey,
} from "@/redux/slices/wallet";
import { WALLET_NAME, walletSdkNetwork } from "@/config/wallet";
import { isRejectSign, signMessage } from "@/utils/wallet";
import {
  walletAccountGetAddress,
  walletAccountGetAccount,
  walletAccountGetAddressBatch,
} from "@/services/wallet_manager";
import { SigInfo, WalletAccountGetAccount } from "@/types/wallet_manager";
import {
  setToken,
  setAddress as setLocalStorageAddress,
  getAddress as getLocalStorageAddress,
  getToken as getTokenCache,
} from "@/utils/auth";
import { createTypography } from "@/theme/typography";
import { setAccountInfo } from "@/redux/slices/account";
import {
  useSingleAddressBindWallet,
  useMultipleAddressBindWallet,
} from "@/hooks/bindWallet";

interface GetInfoProps {
  address: string;
  publicKey: string;
}

export const useUpdateAccount = () => {
  const dispatch = useAppDispatch();
  const updateAccount = async () => {
    const accountInfo = await walletAccountGetAccount();
    const { account_id = "", items = [] } = accountInfo.data;
    dispatch(
      setAccountInfo({
        account_id,
        address_list: items,
      }),
    );
  };
  return {
    updateAccount,
  };
};

export const useGetToken = () => {
  const {
    checkOrCreateAccount,
    checkOrRebinding,
    directBinding,
    bindOrCreateAccount,
    directLogin,
  } = useSingleAddressBindWallet();
  const typography = createTypography();
  const handleBindAddress = async (
    address: string,
    publicKey: string,
  ): Promise<{
    sig_info: SigInfo;
    token: string;
    back?: boolean;
  } | void> => {
    const tokenCase = getTokenCache();
    const res = await walletAccountGetAddress({ address });
    const { union_address = [], is_bind, is_signed, account_id } = res.data;
    // 情况3
    if (is_bind && is_signed) {
      const res = await directLogin({
        address,
        publicKey,
      });
      return res;
    }
    // 情况6
    if (is_bind && !tokenCase) {
      const handleRes = await checkOrCreateAccount({
        address,
        publicKey,
      });
      return handleRes;
    }
    let signedAddress = { address: "" };
    // 有地址组，但新地址无绑定与无签名 => 2
    if (tokenCase) {
      const accountInfo = await walletAccountGetAccount();
      const { items = [] } = accountInfo.data;
      signedAddress = items.find((item) => item.is_signed) || {
        address: "",
      };
      if (tokenCase && !is_bind && !is_signed && signedAddress.address) {
        // 绑定新组
        const res = await bindOrCreateAccount({
          address,
          publicKey,
          oldAddress: signedAddress.address,
        });
        return res;
      }
      if (accountInfo.data.account_id !== account_id && !is_signed) {
        // 情况5
        const res = await checkOrRebinding({
          address,
          publicKey,
        });
        return res;
      }
    }

    // 情况1 + 4
    let bindRes = await directBinding({
      address,
      publicKey,
    });
    return bindRes;
  };
  const getToken = async (info: any) => {
    const { btcAddress, address, publicKey, btcPublicKey } = info;
    const res = await handleBindAddress(btcAddress, btcPublicKey);
    if (!res)
      throw {
        code: "4001",
        nsg: "User Refuse",
      };
    return res ? res.token : "";
  };
  const { updateAccount } = useUpdateAccount();

  return {
    getToken,
    updateAccount,
  };
};

const useGetMultipleAddressToken = () => {
  const {
    getMultipleSignInfo,
    multipleDirectBinding,
    multipleBindOrCreateAccount,
    multipleDirectLogin,
    multipleCreateOrMvOldAddress,
  } = useMultipleAddressBindWallet();

  const getMultipleAddressToken = async (info: any): Promise<string | void> => {
    const { btcAddress, address, publicKey, btcPublicKey } = info;
    const bindObj = {
      btcAddress,
      address,
      publicKey,
      btcPublicKey,
    };
    const addressListRes = await walletAccountGetAddressBatch({
      address: [btcAddress, address],
    });
    let accountInfo: any = {} as WalletAccountGetAccount["res"];
    if (getTokenCache()) {
      accountInfo = await walletAccountGetAccount();
    }
    const { items = [] } = addressListRes.data;
    const allSigned = items.every((item) => item.is_signed);
    const hasSigned = items.some((item) => item.is_signed);
    const allBind = items.every((item) => item.is_bind);
    const allNotBind = items.every((item) => !item.is_bind);
    const hasBind = items.some((item) => item.is_bind);
    const cacheToken = getTokenCache();
    const isSameGroup =
      items[0] && items[1]
        ? items[0].account_id === items[1].account_id
        : false;
    // 情况1 + 4 + 5
    if (
      (allNotBind && !cacheToken) ||
      (!isSameGroup && allSigned) ||
      (hasSigned && !allSigned)
    ) {
      const res = await multipleDirectBinding(bindObj);
      return res ? res.token : "";
    }
    // 情况2
    if ((allNotBind && cacheToken) || (hasBind && !hasSigned && cacheToken)) {
      const { items = [] } = accountInfo.data;
      const signedAddress = items.find((item: any) => item.is_signed) || {
        address: "",
      };
      const res = await multipleBindOrCreateAccount({
        ...bindObj,
        oldAddress: signedAddress.address,
      });
      return res ? res.token : "";
    }
    // 情况3
    if (isSameGroup && allSigned) {
      const res = await multipleDirectLogin(bindObj);
      return res ? res.token : "";
    }
    // 情况6
    if (allBind && !hasSigned && !isSameGroup && !cacheToken) {
      const res = await multipleCreateOrMvOldAddress(bindObj);
      return res ? res.token : "";
    }
  };
  return {
    getMultipleAddressToken,
  };
};

// 链信息
export const chainInfo = {
  btc: [
    WALLET_NAME.Atom,
    WALLET_NAME.OK,
    WALLET_NAME.Uni,
    WALLET_NAME.XV,
    WALLET_NAME.ME,
  ],
  doge: [WALLET_NAME.DL],
};

/**
 * @name walletNameDict
 * @description 钱包名称映射
 */
export const walletNameDict = {
  [WALLET_NAME.Atom]: walletDict.atom,
  [WALLET_NAME.Uni]: walletDict.unisat,
  [WALLET_NAME.XV]: walletDict.xverse,
  [WALLET_NAME.OK]: walletDict.okx,
  [WALLET_NAME.DL]: walletDict.dogeLabs,
  [WALLET_NAME.BG]: (walletDict as any).bitget,
  [WALLET_NAME.GT]: walletDict.unisat,
  [WALLET_NAME.TP]: walletDict.unisat,
  [WALLET_NAME.ME]: walletDict.magicEden,
};
/**
 * @name walletNameToKey
 * @description 使用名称获取对应的key之
 */
export const walletNameToKey = {
  [walletDict.atom]: WALLET_NAME.Atom,
  [walletDict.unisat]: WALLET_NAME.Uni,
  [walletDict.okx]: WALLET_NAME.OK,
};
/**
 * @func connectFn
 * @description
 * @param {string}
 * @returns content
 * @example
 * console.log('example');
 */
export const useWalletContent = () => {
  const { openResultAlert } = useResultAlert();
  const dispatch = useAppDispatch();
  const { getToken, updateAccount } = useGetToken();
  const { getMultipleAddressToken } = useGetMultipleAddressToken();
  const connect = async ({
    errorCb,
    key,
  }: {
    errorCb?: () => void;
    key: string;
  }) => {
    const name: any = key || "";
    const info = await walletApi
      .connect({
        name: walletNameDict[name] as any,
        network: walletSdkNetwork as any,
        handleMismatchNetwork: true,
      })
      .catch((e) => {
        if (errorCb) errorCb();
        if (!isRejectSign(e)) {
          openResultAlert({
            // containerId: 'RequestCanceled',
            msg: e.msg || "Request canceled",
            type: "fail",
          });
        }
        disconnect();
        return null;
      });
    if (!info) return false;
    if (!checkIsBTCAddress(info.address)) {
      openResultAlert({
        msg: `This wallet type doesn't support ordinals. Use an address starting with "bc1p" or "bc1q" before connecting.`,
        type: "fail",
      });
      return false;
    }
    if (!info) return;
    const {
      address = "",
      publicKey = "",
      btcAddress,
      btcPublicKey,
      network,
      accounts,
    } = info;
    // if (getAddress() !== address || getAddress() !== btcAddress) {
    // }
    try {
      const cacheAddress = getLocalStorageAddress();
      if (
        cacheAddress.address !== address ||
        cacheAddress.btcAddress !== btcAddress
      ) {
        let token = "";
        if (address !== btcAddress) {
          token = (await getMultipleAddressToken(info)) || "";
          if (!token)
            throw {
              code: "4001",
              nsg: "User Refuse",
            };
        } else {
          token = await getToken(info);
        }
        setToken(token);
        if (token) {
          updateAccount();
        }
        setLocalStorageAddress(address, btcAddress);
      } else {
        if (getTokenCache()) updateAccount();
      }
      dispatch(
        setIsConnect({
          isConnect: true,
          walletName: name,
        }),
      );
      dispatch(setAccounts(accounts));
      dispatch(setAddress(address));
      dispatch(setBTCAddress(btcAddress));
      dispatch(setPublicKey(publicKey));
      dispatch(setBTCPublicKey(btcPublicKey));
      dispatch(setNetwork(network));
      return true;
    } catch (error) {
      errorCb?.();
    }
  };
  const disconnect = async () => {
    dispatch(
      setIsConnect({
        isConnect: false,
        walletName: "",
      }),
    );
    dispatch(setAccounts([]));
    dispatch(setAddress(""));
    dispatch(setBTCAddress(""));
    dispatch(setPublicKey(""));
    dispatch(setBTCPublicKey(""));
    dispatch(clearBalance());
    dispatch(setNetwork(""));
    dispatch(setPrivateKey(""));
    await walletApi.disconnect();
  };
  return {
    connect,
    disconnect,
  };
};
