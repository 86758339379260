export const pxToVw = (number = 0, totalWidth = 375, decimals = 5) => {
  let compWidth = totalWidth;
  const baseNumber = Math.pow(10, decimals);
  const numberVw =
    Math.floor((100 / compWidth) * number * baseNumber) / baseNumber;
  return numberVw + "vw";
};

export const getTextLinkGradation = (color: React.CSSProperties["color"]) => {
  return {
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundImage: color,
  };
};
