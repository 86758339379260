import { Box, SxProps } from "@mui/material";

export interface MyIconProps {
  name: string;
  color?: string;
  width?: number | string;
  height?: number | string;
  number?: number;
  size?: number | string;
  id?: string;
  transform?: string;
  sx?: SxProps;
  onClick?: () => void;
  className?: string;
  fill?: string;
  children?: React.ReactNode;
}

const MyIcon: React.FC<MyIconProps> = (props: MyIconProps) => {
  const {
    onClick = null,
    className = "",
    fill = "currentcolor",
    children = null,
  } = props;
  return (
    <Box
      component="svg"
      className={className}
      sx={{
        color: props.color,
        width: props.width || props.size || 24,
        height: props.height || props.size || 24,
        fill,
        verticalAlign: "-0.15em",
        overflow: "hidden",
        transform: props.transform,
        cursor: onClick ? "pointer" : "default",
        ...props.sx,
      }}
      id={props.id}
      onClick={() => onClick?.()}
    >
      <use xlinkHref={`#${props.name}`}></use>
      {children}
    </Box>
  );
};

export default MyIcon;
