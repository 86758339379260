/**
 * @file PageArchitecture
 * @description 项目公共基础建设架构文件，尽量不涉及修改
 */
import ThemeProvider from "@/theme/index";

import SwitchTerminal from "@/components/public/switch-terminal";
import PcMain from "@/layouts/pc/main";
import H5Main from "@/layouts/h5/main";
import SnackbarProvider, {
  SnackbarUtilsConfigurator,
} from "../snackbar/SnackbarProvider";
import InitApp from "@/components/public/init/app-init";
import H5Home from "@/components/h5/v1/home";

interface Props {
  children?: React.ReactNode;
  versions?: "v1" | "v2";
  isHeader?: boolean;
  isFooter?: boolean;
  h5IsHeader?: boolean;
  h5IsFooter?: boolean;
  activity?: React.ReactNode;
  hasPlaceholder?: boolean;
  hasH5?: boolean;
  h5IsOpenService?: boolean;
  h5Activity?: React.ReactNode;
  isCloseH5?: boolean;
}

export default function PageArchitecture(props: Props) {
  const {
    hasH5 = false,
    isFooter = false,
    children = null,
    h5IsHeader = true,
    h5IsFooter = true,
    isCloseH5 = true,
  } = props;
  return (
    <SnackbarProvider>
      <ThemeProvider hasH5={hasH5}>
        <SnackbarUtilsConfigurator />
        <InitApp />
        <SwitchTerminal
          pc={<PcMain>{children}</PcMain>}
          h5={<H5Main>{isCloseH5 ? <H5Home /> : children}</H5Main>}
          hasMultipleTerminal={hasH5}
        />
      </ThemeProvider>
    </SnackbarProvider>
  );
}
