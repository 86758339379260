export const walletSdkNetwork = process.env.NEXT_PUBLIC_NETWORK_WALLET;
export const isMainnet = process.env.NEXT_PUBLIC_NETWORK_WALLET === "livenet";
export const baseMempoolUrl = isMainnet
  ? "https://mempool.space"
  : "https://mempool.space/testnet";
export const imageBaseUrl = process.env.NEXT_PUBLIC_IMAGE_BASE_URL || "";

export const apiHost = process.env.NEXT_PUBLIC_API_HOST; // "/api"; //

export const marketBaseUrl = process.env.NEXT_PUBLIC_MARKET_BASH_URL;
export const shareUrl = process.env.NEXT_PUBLIC_MULTIPLE_DOMAIN;
export const dotswapUrl = process.env.NEXT_PUBLIC_DOTSWAP_URl;
export const toolsUrl = process.env.NEXT_PUBLIC_TOOLS_URl;

// code 部分管理
export const NOT_LOGIN_CODE = 10170001;

export const tiertopBaseUrl = !isMainnet
  ? process.env.NEXT_PUBLIC_API_HOST
  : "https://api-btc.tiertop.io";

export const tokenInvalidBusName = "tokenInvalid";

export const customerService =
  "https://dotswap.gitbook.io/dotswap/about-dotswap/links";
