/**
 * @饼图组件
 */
import React from "react";
import { echarts } from "./config";
import ReactECharts from "echarts-for-react/lib/core";
import { renderToString } from "react-dom/server";
import { TipParams } from "@/types/echarts";
import { baseRenderTip } from "./tools";
import palette from "@/theme/palette";

interface Data {
  name: string;
  value: number;
  color?: string;
}

interface Position {
  top?: number;
  bottom?: number;
  right?: number;
  left?: number;
}
type NumberStr = string;
interface Props {
  data: Data[];
  name?: "";
  radius?: NumberStr[];
  position?: Position;
  center?: NumberStr[];
  colorList?: string[];
  showLabel?: boolean;
  renderTip?: (params: TipParams) => React.ReactNode;
}

const PieEcharts: React.FC<Props> = (props: Props) => {
  const {
    data,
    radius = [0, "50%"],
    position = {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    center = ["50%", "50%"],
    colorList = [
      "#04598C",
      "#078BD2",
      "#0DADEA",
      "#0BBBFD",
      "#38DAFD",
      "#74DAFF",
      "#97EAFF",
      "#B3F2FF",
    ],
    showLabel = true,
    renderTip = null,
  } = props;
  const options = {
    tooltip: {
      trigger: "item",
      show: true,
      formatter: renderTip
        ? function (params: any) {
            return renderTip ? baseRenderTip(renderTip(params)) : "";
          }
        : undefined,
      className: "echarts-tip",
    },
    // legend: {
    //   top: "5%",
    //   left: "center",
    // },
    color: colorList,
    series: [
      {
        name: "Access From",
        type: "pie",
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        data,
        radius,
        center,
      },
    ],
  };
  return (
    <ReactECharts
      echarts={echarts}
      option={options}
      style={{ height: "100%", width: "100%" }}
    />
  );
};

export default PieEcharts;
