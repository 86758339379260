import { Stack, Typography } from "@mui/material";
import Image from "./index";
import { useState } from "react";
import { TypographyPropsVariantOverridesProps } from "@/theme/overrides/Typography";
import { createTypography } from "@/theme/typography";
import {
  PLATFORM_ICON,
  ATOM_ICON,
  PAI_ICON,
  BTC_ICON,
  DOGE_ICON,
  DOHI_ICON,
  QUARk_ICON,
  DOTS_ICON,
  INF_ICON,
  Electron_ICON,
  RUNES_ICON,
  DeAI_ICON,
  RUNES_ICO1N2,
  BRC20_ICON,
  ARC20_ICON,
} from "@/config/localTickIcon";

interface Props {
  size?: number;
  width?: number;
  height?: number;
  url?: string;
  name?: string;
  symbol?: string;
  hasRadius?: boolean;
  bgColor?: string;
  borderColor?: string;
  textVariant?: TypographyPropsVariantOverridesProps;
}

export default function TickImage(props: Props) {
  const {
    size,
    width,
    height,
    url,
    name = "",
    hasRadius,
    bgColor = "background.textPaper",
    borderColor = "divider",
    textVariant = "subtitle3",
    symbol = "",
  } = props;

  // const isEmojiString = (s: string) => {
  //   const emojiPattern =
  //     /^[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}]+$/u;
  //   return emojiPattern.test(s);
  // };
  const handleStr = symbol ? symbol : name?.slice(0, 1).toUpperCase() || "";
  const [error, setError] = useState(false);
  const t = createTypography();
  const defaultImageDict: any = {
    BTC: BTC_ICON,
    DSWP: PLATFORM_ICON,
    DOGE: DOGE_ICON,
    quark: QUARk_ICON,
    DeAI: DeAI_ICON,
  };
  const handleUrl = defaultImageDict[name] || url;
  return (
    <>
      {handleUrl && !error ? (
        <Image
          src={handleUrl}
          {...{ size, width, height }}
          style={{
            borderRadius: hasRadius ? "50%" : "0px",
            flex: "none",
          }}
          onError={() => setError(true)}
        />
      ) : (
        <div
          style={{
            width: width || size,
            height: height || size,
            borderRadius: hasRadius ? "50%" : "0px",
            backgroundColor: bgColor,
            border: "1px solid",
            borderColor: "borderColor",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: "none",
          }}
        >
          <span style={{ ...(t[textVariant] as any), color: "#fff" }}>
            {handleStr}
          </span>
        </div>
        // <Stack
        //   width={width || size}
        //   height={height || size}
        //   borderRadius={hasRadius ? "50%" : "0px"}
        //   bgcolor={bgColor}
        //   border={1}
        //   borderColor={borderColor}
        //   justifyContent={"center"}
        //   alignItems={"center"}
        //   sx={{
        //     flex: "none",
        //   }}
        // >
        //   <Typography variant={textVariant as any}>{handleStr}</Typography>
        // </Stack>
      )}
    </>
  );
}
