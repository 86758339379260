import { Stack, Typography, Button } from "@mui/material";
import BaseButton from "@/components/public/button/BaseButton";
import Image from "@/components/public/image";
import ConnectWalletButton from "@/components/public/button/ConnectWalletButton";
import NoLoginDemo from "./NoLoginDemo";

export const WalletList = [
  {
    label: "Xverse",
    image: "/assets/logo-xverse.png",
  },
  {
    label: "OKX",
    image: "/assets/logo-okx.png",
  },
  {
    label: "Unisat",
    image: "/assets/unisat.png",
  },
  {
    label: "ME",
    image: "/assets/magic_eden_icon.png",
  },
  {
    label: "Wizz",
    image: "/assets/atom.png",
  },
  // {
  //   label: "Bitget",
  //   image: "/assets/bitget.png",
  // },
];

export default function NoLogin() {
  const list = WalletList;
  return (
    <Stack alignItems={"center"} pb={"45px"}>
      <Stack position={"absolute"} zIndex={-1} width={"100%"} left={0}>
        <Image
          src={"/assets/no_login_bg.png"}
          height={"597px"}
          width={"100%"}
          style={{
            minWidth: "1400px",
          }}
        />
      </Stack>
      <Typography
        variant="h1"
        sx={{
          fontSize: "52px",
          lineHeight: "73px",
        }}
        mb={1.25}
      >
        Power Up Your Trading
      </Typography>
      <Typography variant="h5" mb={"43px"}>
        Easily track and analyse your BTC, Runes & BRC20 assets with Mempool
        Portfolio.
      </Typography>

      <ConnectWalletButton
        beforeConnectDom={
          <BaseButton
            variant={"glow"}
            borderRadius={"20px"}
            textVariant="h5"
            height={56}
            width={300}
            sx={{
              mb: "44px",
            }}
          >
            Connect Wallet
          </BaseButton>
        }
      >
        <></>
      </ConnectWalletButton>

      <Typography variant="h5" mb={"43px"}>
        - Supported Wallets -
      </Typography>
      <Stack spacing={"30px"} direction={"row"} alignItems={"center"} mb={5}>
        {list.map((item, index) => (
          <Stack spacing={"13px"} key={index} alignItems={"center"}>
            <Image
              src={item.image}
              size={32}
              alt={item.label}
              style={{
                borderRadius: "4px",
                boxShadow: "0px 0px 4px 0px rgba(255,255,255,0.5)",
              }}
            />
            <Typography variant="subtitle3">{item.label}</Typography>
          </Stack>
        ))}
      </Stack>
      <Typography variant="h5" mb={"57px"}>
        - Demonstrations -
      </Typography>
      {/* <Image src={"/assets/no_login_home_bg.png"} width={1400} height={1470} /> */}
      {/* <Stack height={}></Stack> */}
      <NoLoginDemo />
    </Stack>
  );
}
