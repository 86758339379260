import { Stack, Typography } from "@mui/material";
import Image from "@/components/public/image";
import MyIcon from "@/components/public/my-icon";
import NextLink from "next/link";

interface Props {
  img: string;
  num: number | string;
  icon: string;
  href: string;
}

export default function PlatformItem(props: Props) {
  const { img, num, icon, href } = props;
  return (
    <NextLink
      href={href}
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
      target={"_blank"}
    >
      <Stack
        width={"90px"}
        boxSizing={"border-box"}
        spacing={1}
        position={"relative"}
        borderRadius={"8px"}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Stack
          width={23}
          height={23}
          flex={"none"}
          borderRadius={"4px"}
          sx={{
            boxShadow: "0px 0px 4px 0px rgba(255,255,255,0.5)",
          }}
        >
          <Image
            src={img}
            size={23}
            style={{
              borderRadius: 4,
            }}
          />
        </Stack>
        <Stack flex={1}>
          <Typography variant="body2">{num ? num : "--"}</Typography>
          <Typography variant="body2">sats/{icon}</Typography>
        </Stack>
      </Stack>
    </NextLink>
  );
}
