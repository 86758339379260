import { SnackbarUtils } from "@/components/public/snackbar/SnackbarProvider";
import { apiHost } from "@/config/http";
import axios, {
  AxiosResponse,
  InternalAxiosRequestConfig,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosError,
} from "axios";
import { getToken, createInvalid, getAddress } from "./auth";
import { NOT_LOGIN_CODE } from "@/config/http";
import { bus } from "@/utils/bus";
import { tokenInvalidBusName } from "@/config/http";

// 继续请求的处理
const getErrorConsole = (options: {
  url: string;
  code: string | number;
  msg: string;
}) => {
  const { url, code, msg } = options;
  console.error(`url: ${url}\ncode: ${code}\nmsg: ${msg}`);
};
const handleContinueReq = (error: any, cb?: Function) => {
  const {
    method,
    url,
    data,
    timeout,
    noHandleError,
    filterCodes,
    isContinueReqError,
    maxContinueReqNumber,
    currentContinueIndex,
  } = error.config;
  if (error.error.code === NOT_LOGIN_CODE) {
    const { code, msg } = error.error;
    getErrorConsole({ url, code, msg });
    return cb ? cb() : Promise.reject(error);
  }
  if (
    error.config.isContinueReqError &&
    error.config.maxContinueReqNumber > error.config.currentContinueIndex
  ) {
    return http({
      method,
      url,
      data,
      timeout,
      noHandleError,
      filterCodes,
      isContinueReqError,
      maxContinueReqNumber,
      currentContinueIndex: currentContinueIndex + 1,
    });
  }
  return cb ? cb() : Promise.reject(error);
};

export const handleRequestError = (error: any) => {
  const msg = error?.data?.msg;
  if (error.code === "ERR_NETWORK" || error.name === "AxiosError") {
    SnackbarUtils.error(error.message || "Network Error");
  }
  // 统一提示
  if (msg) {
    SnackbarUtils.error(msg);
  }
};

// 请求前拦截
interface InternalHttpRequestConfig<D = any>
  extends InternalAxiosRequestConfig<D> {
  noHandleError?: boolean;
  filterCodes?: number[];
  isContinueReqError?: boolean;
  maxContinueReqNumber?: number;
  currentContinueIndex?: number;
  cleanVerifyToken?: boolean;
  openVerifyTokenMsg?: boolean;
}
export const requestInterceptor = (config: InternalHttpRequestConfig) => {
  // 只有在客户端请求时带 access_token 和 language
  // 停服公告使用，统一禁用接口请求
  // throw new Error('DotSwap is temporarily in maintenance.');
  const token = getToken();
  const address = getAddress().address;
  if (!config.cleanVerifyToken) {
    if (!token) {
      const error = createInvalid();
      if (config.openVerifyTokenMsg)
        SnackbarUtils.error(error.msg || "Network Error");
      throw {
        error,
        config,
      };
    }
  }
  config.headers["Authorization"] = token;
  return config;
};

// 响应后拦截
/**
 * 响应后拦截
 * @params type 1全返回 2只返回data数据
 * @return
 */
interface HttpResponse extends AxiosResponse {
  config: InternalHttpRequestConfig;
}
const responseInterceptors = async (res: HttpResponse) => {
  const { noHandleError, filterCodes = [] } = res.config;
  const resContent = res.data;
  if (!(res && res.status === 200)) return Promise.reject(resContent);
  if (res.data.code === 10170001) {
    handleRequestError(res);
    bus.emit(tokenInvalidBusName);
    return Promise.reject(resContent);
  }

  if ([0, ...filterCodes].includes(res.data.code))
    return Promise.resolve(resContent);
  else {
    // 错误从这统一抛出
    if (!noHandleError) {
      handleRequestError(res);
      return Promise.reject("Request Error");
    } else {
      return Promise.reject(resContent);
    }
  }
};

interface Options<p = any> {
  baseUrl?: string;
  data?: p;
  url: string;
  method: string;
  timeout?: number;
  noHandleOther?: boolean;
  noHandleError?: boolean;
  filterCodes?: number[]; // 将本应该报错的内容视为正确
  isContinueReqError?: boolean;
  maxContinueReqNumber?: number;
  cleanVerifyToken?: boolean;
  openVerifyTokenMsg?: boolean;
  // currentContinueIndex?: number;
}
interface HttpRequestConfig<D> extends AxiosRequestConfig<D> {
  noHandleError?: boolean;
  filterCodes?: number[];
  isContinueReqError?: boolean;
  maxContinueReqNumber?: number;
  currentContinueIndex?: number;
  cleanVerifyToken?: boolean;
  openVerifyTokenMsg?: boolean;
}
interface HttpInstance2 extends AxiosInstance {
  <T = any, R = AxiosResponse<T>, D = any>(
    config: HttpRequestConfig<D>,
  ): Promise<R>;
}
const http: HttpInstance2 = axios.create({
  timeout: 20 * 1000,
});
http.interceptors.response.use(responseInterceptors, handleContinueReq);
http.interceptors.request.use(requestInterceptor, (error) =>
  Promise.reject(error),
);

export const api = <p = any, s = any>(options: Options<p>): Promise<s> => {
  // noHandleError: false 默认同意抛出错误提示，如果需要单独捕获的增加这个配置
  const {
    baseUrl = apiHost,
    method = "post",
    url,
    data,
    timeout,
    noHandleError = false,
    filterCodes = [],
    isContinueReqError = true,
    maxContinueReqNumber = 3,
    cleanVerifyToken = false,
    openVerifyTokenMsg = true,
  } = options;
  return http({
    method,
    url: `${baseUrl}${url}`,
    data,
    timeout,
    noHandleError,
    filterCodes,
    isContinueReqError,
    maxContinueReqNumber,
    currentContinueIndex: 1,
    cleanVerifyToken,
    openVerifyTokenMsg,
  });
};
