import { ListItem, TabsItem } from "@/types/components";
export const PropertyTypes: ListItem[] = [
  {
    label: "All assets",
    value: "",
  },
  {
    label: "Runes",
    value: "runes",
  },
  {
    label: "BRC20",
    value: "brc20",
  },
  {
    label: "Others",
    value: "others",
  },
];

export const methodList: ListItem[] = [
  { label: "All", value: "" },
  { label: "Mint", value: "mint" },
  { label: "Buy", value: "buy" },
  { label: "Sell", value: "sell" },
  { label: "Send", value: "send" },
  { label: "Receive", value: "receive" },
  { label: "Burn", value: "burn" },
  // { label: "Depoly", value: "depoly" },
  { label: "Transfer", value: "transfer" },
];
